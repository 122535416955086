//React

//UI

//Services

//Logics

//Components

//Classes
import { COMPONENT_TYPE } from 'classes/enums/component-types';

type Props = {
    selectedType: string;
    handleDoubleClickCreateComponent: Function;
}

const ComponentOverviewPanel = (props: Props) => {
    const { selectedType, handleDoubleClickCreateComponent } = props;
    let componentTypeText = ["Title", "Heading", "Text", "Image", "List", "Code", "MCQ", "Page Challenge"];
    let componentTypes = [COMPONENT_TYPE.title, COMPONENT_TYPE.heading, COMPONENT_TYPE.text, COMPONENT_TYPE.image, COMPONENT_TYPE.list, COMPONENT_TYPE.codeblock, COMPONENT_TYPE.mcq, COMPONENT_TYPE.pageChallenge];
    
    // display: inline-block;
    // justify-content: space-between;
    // padding: 10px;
    // border: 2px solid lightgray;
    // margin: 10px;
    // width: 80px;
    // height: 60px;

    //const componentTypeStyle = "inline-block justify-between p-2 border-2 border-slate-50 m-2 w-32 h-16 bg-white";

    const parseStyle = (type: string) => {
        if (type === selectedType) {
            return "inline-block justify-between p-2 border-2 border-slate-50 m-2 w-32 h-16 bg-slate-200";
        } else {
            return "inline-block justify-between p-2 border-2 border-slate-50 m-2 w-32 h-16 bg-white";
        }
    }

    return (
        <div className='rounded border-2 bg-slate-300 m-2'>
            {componentTypeText.map((type, index) => {
                return (
                        <div
                            key={index}
                            className={parseStyle(componentTypes[index])}
                            draggable={true}
                            onDoubleClick={() => handleDoubleClickCreateComponent(componentTypes[index])}
                            onDragStart={(e) => e.dataTransfer.setData('type', componentTypes[index])}
                            onDragOver={(e) => e.stopPropagation()}
                        >{type}</div>
                   
                )
            })}
        </div>
    )
}

export default ComponentOverviewPanel;