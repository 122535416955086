//React

//UI

//Services

//Logics

//Components

//Store

//Classes
import {StyleTheme} from "classes/gridslate/style/StyleTheme";
import { Component } from "classes/gridslate/components/Component";
import {STATUS } from "classes/enums/status";
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";

//Assuming only data property needs to be stringified
const stringifyDataProperty = (object: any) => {
    let model = { ...object };
    if (model.data) {
        model.data = JSON.stringify(object.data);
    }
    return model;
}

const stringifyDataPropertyOnList = (objects: any[]) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        parsedObjects.push(stringifyDataProperty(object));
    });
    return parsedObjects;
}

const unstringifyDataProperty = (object: any) => {
    let model = { ...object };
    if (model.data) {
        model.data = JSON.parse(object.data);
    }
    return model;
}

const unstringifyDataPropertyOnList = (objects: any[]) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        parsedObjects.push(unstringifyDataProperty(object));
    });
    return parsedObjects;
}

const setStatusPropertyOnList = (objects: any[], status: string) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        let model = { ...object };
        model.status = status;
        parsedObjects.push(model);
    });
    return parsedObjects;
}

const parseGenericFetchedPayload = (objects: any[]) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        parsedObjects.push(unstringifyDataProperty(object));
    });
    return parsedObjects;
}

const parseGenericList = (objects: any[]) => {
    let parsedObjects = [] as any[];
    objects.forEach((object) => {
        parsedObjects.push(unstringifyDataProperty(object));
    });
    return parsedObjects;
}

const getObjectName = (objectType: string, object: any) => {
    let name = "";
    if (object.name) {
        name = object.name;
    } else if (object.title) {
        name = object.title;
    } else if (object.type) {
        name = object.type;
    } else {
        name = object.id;
    }
    // switch (objectType) {
    //     case "styletheme":
    //         object = object as StyleTheme;
    //         name = object.name;
    //         break;
    //     case "component":
    //         name = object.type;
    //         break;
    //     case "chapter":
    //     case "page":
    //         name = object.title;
    //         break;
    //     case 
    //     default:
    //         name = object.id;
    // }
    return name;
}

const parseComponentsFromLoad = (components: Component[]) => {
    let parsedComponents = [] as Component[];
    components.forEach((component: Component) => {
        parsedComponents.push(unstringifyDataProperty(component));
    });
    parsedComponents = setStatusPropertyOnList(parsedComponents, STATUS.unchanged);
    return parsedComponents;
}

const parseComponentsFromDownload = (components: Component[], newComponents: Component[], updatedComponents: Component[], deletedComponentIds: string[]) => {
    let allComponents = [...components];
    //For each component in responseModel.NewComponents, find component by componentRef and set id and status
    newComponents.forEach((component: Component) => {
        let thisComponent = allComponents.find((c: Component) => c.componentRef === component.componentRef);
        if (thisComponent) {
            component = unstringifyDataProperty(component);
            component.id = component.id;
            component.status = STATUS.unchanged;
        }
    });
    //For each component in responseModel.UpdateComponents, find component by componentRef and set status
    updatedComponents.forEach((component: Component) => {
        let thisComponent = allComponents.find((c: Component) => c.componentRef === component.componentRef);
        if (thisComponent) {
            component = unstringifyDataProperty(component);
            component.status = STATUS.unchanged;
        }
    });
    //For each component in responseModel.DeleteComponents, find component by componentRef and remove it
    deletedComponentIds.forEach((id: string) => {
        let index = allComponents.findIndex((c: Component) => c.id === id);
        if (index > -1) {
            newComponents.splice(index, 1);
        }
    });

    allComponents = setStatusPropertyOnList(allComponents, STATUS.unchanged);

    return allComponents;
}

const parseComponentsForUpload = (components: Component[]) => {
    let newComponents = [] as Component[];
    let updatedComponents = [] as Component[];
    let deletedComponentIds = [] as string[];

    components.forEach((component: Component) => {
        if (component.status === STATUS.new) {
            newComponents.push(stringifyDataProperty(component));
        } else if (component.status === STATUS.updated) {
            updatedComponents.push(stringifyDataProperty(component));
        } else if (component.status === STATUS.deleted) {
            deletedComponentIds.push(component.id);
        }
    });
    //return new CreateUpdatePageRequest(page, newComponents, updatedComponents, deletedComponentIds);
    return { newComponents, updatedComponents, deletedComponentIds };
}

const parseObjectUpdate = (thisObject: any, propertyList: EditablePropertyMetadata[]) => {

    function updateObject(obj: any, updates: { propertyName: string, value: any }[]) {
        for (let update of updates) {
            obj[update.propertyName] = update.value;
        }
        return obj;
    }

    thisObject = updateObject(thisObject, propertyList);
    return thisObject;

}

export default {
    parseGenericFetchedPayload,
    getObjectName,
    stringifyDataProperty,
    unstringifyDataProperty,
    stringifyDataPropertyOnList,
    unstringifyDataPropertyOnList,
    setStatusPropertyOnList,
    parseComponentsFromDownload,
    parseComponentsForUpload,
    parseComponentsFromLoad,
    parseObjectUpdate
};