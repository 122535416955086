import {NavbarElement} from './NavbarElement';

class NavbarClass {

    id: string = "";
    websiteId: string;
    elements: NavbarElement[];

    constructor(websiteId: string, elements: NavbarElement[]) {
        this.websiteId = websiteId;
        this.elements = elements;
    }
    
}

export { NavbarClass};