
class PageChallengeInstance {
    id: string = "";
    pageChallengeId: string = "";
    name: string = "";
    ownerId: string = "";
    courseId: string = "";
    dueDate: string = "";
    status: number = 0;
    completedUserIds: string[] = [];

    constructor(pageChallengeId: string, courseId: string, dueDate: string) {
        this.pageChallengeId = pageChallengeId;
        this.courseId = courseId;
        this.dueDate = dueDate;
    }
}

export {PageChallengeInstance};