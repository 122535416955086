
enum COMPONENT_TYPE {
    text = "text",
    image = "image",
    codeblock = "codeblock",
    title = "title",
    heading = "heading",
    empty = "empty",
    list = "list",
    dynamic = "dynamic",
    table = "table",
    mcq = "mcqQuestion",
    pageChallenge = "pageChallenge"
    //mcqAnswer = "mcqAnswer"
}

export { COMPONENT_TYPE};