//React
import { useEffect, useState } from 'react';

//UI
import { Button } from "flowbite-react";
import Row from "components/ui_components/helper/Row";

//Services
import crudService from 'services/crudService';
import errorService from 'services/errorService';
import { toast } from 'react-toastify';

//Logics

//Components
import CategorySelector from "components/synapp/CategorySelector";

//Classes
import {Category} from 'classes/synapp/Category';
import {FilterModel} from "classes/models/request/FilterModel";
import {CategoryStatus} from 'classes/synapp/CategoryStatus';
import {CLASS} from 'classes/enums/classes';
import {IsBusy} from 'classes/general/IsBusy';

type Props = {
    categoryStatus: CategoryStatus;
    setCategoryStatus: Function;
    content: string;
}

const CategoryConstructor = (props: Props) => {

    const { categoryStatus, setCategoryStatus, content } = props;
    const [newCategory, setNewCategory] = useState<Category>(new Category());

    useEffect(() => {
        if (content !== "") {
            setNewCategory({ ...newCategory, name: content });
        }
    }, [content]);

    const deleteCategory = async () => {
        //find id highest up in the hierarchy
        let id: string = "";
        if (categoryStatus.level2CategoryId !== "None") {
            id = categoryStatus.level2CategoryId;
        } else if (categoryStatus.level1CategoryId !== "None") {
            id = categoryStatus.level1CategoryId;
        } else if (categoryStatus.level0CategoryId !== "None") {
            id = categoryStatus.level0CategoryId;
        } else {
            toast.error("No category selected to delete");
            return;
        }

        let filterModel = new FilterModel([["Id", id]]);
        let response = await crudService.del(CLASS.category, filterModel);
        if (response.success) {
            let tempCategories = [...categoryStatus.categories];
            let index = tempCategories.findIndex(x => x.id === id);
            tempCategories.splice(index, 1);
            setCategoryStatus({...categoryStatus, categories: tempCategories});
            //TODO: remove selectedCategory from state
            toast.success("Category deleted");
        } else {
            errorService.handleError(response);
        }
    }

    const createUpdateCategory = async () => {

        if (categoryStatus.level0CategoryId === "None" && categoryStatus.level1CategoryId === "None" && categoryStatus.level2CategoryId === "None") {
            newCategory.parentId = "Root";
            newCategory.level = 0;
        } else if (categoryStatus.level1CategoryId === "None" && categoryStatus.level2CategoryId === "None") {
            newCategory.parentId = categoryStatus.level0CategoryId;
            newCategory.level = 1;
        } else if (categoryStatus.level2CategoryId === "None") {
            newCategory.parentId = categoryStatus.level1CategoryId;
            newCategory.level = 2;
        } else {
            console.log(categoryStatus)
            toast.error("Cannot create a category with more than 2 levels of nesting");
            return;
        }

        let newCategories = [];
        let categoryNames = newCategory.name.split(";");
        //remove empty strings
        categoryNames = categoryNames.filter((name) => name.trim() !== "");
        for (let name of categoryNames) {
            let thisCategory = new Category();
            thisCategory.name = name.trim();
            thisCategory.parentId = newCategory.parentId;
            thisCategory.level = newCategory.level;
            newCategories.push(thisCategory);
        }

        let requestModel = newCategories;

        let response = await crudService.create(CLASS.category, requestModel);
        if (response.success) {
            if (newCategory.id === "") {
                let responseModel = response.payload as Category[];
                let tempCategories = [...categoryStatus.categories];
                //TODO: Only creating new categories, not updating existing ones
                for (let category of responseModel) {
                    tempCategories.push(category);
                }
                //set index to the last category created
                let lastCategoryCreated = responseModel[responseModel.length - 1];
                let newCategoryStatus = { ...categoryStatus };
                if (lastCategoryCreated.level === 0) {
                    newCategoryStatus.level0CategoryId = lastCategoryCreated.id;
                } else if (lastCategoryCreated.level === 1) {
                    newCategoryStatus.level1CategoryId = lastCategoryCreated.id;
                } else if (lastCategoryCreated.level === 2) {
                    newCategoryStatus.level2CategoryId = lastCategoryCreated.id;
                }
                newCategoryStatus.categories = tempCategories;
                setCategoryStatus(newCategoryStatus);
                //setCategoryStatus({...categoryStatus, categories: tempCategories});
                toast.success("Category created");
            } else {
                let tempCategories = [...categoryStatus.categories];
                let index = tempCategories.findIndex(x => x.id === newCategory.id);
                tempCategories[index] = response.payload;
                setCategoryStatus({...categoryStatus, categories: tempCategories});
                toast.success("Category updated");
            }
        } else {
            errorService.handleError(response);
        }
    }

    return (
        <div>
            {/* <div>{JSON.stringify(categoryStatus.categories)}</div> */}
            <CategorySelector
                categories={categoryStatus.categories}
                categoryObject={categoryStatus}
                setCategoryObject={setCategoryStatus}
                //categoryStatus={categoryStatus}
                //setCategoryStatus={setCategoryStatus}
                isBusy={new IsBusy()}
            />
            <Row></Row>
            Seperate name with semicolon for multiple categories:
            <input type="text" placeholder='Category names here...' value={newCategory.name} onChange={(e) => { setNewCategory({ ...newCategory, name: e.target.value }) }} />
            <Button onClick={() => createUpdateCategory()}>Save new category</Button>
            <Button onClick={() => deleteCategory()}>Delete category</Button>
        </div>
    );
}

export default CategoryConstructor;