//React
import { useEffect } from "react";

//UI
import Row from "components/ui_components/helper/Row";

//Services

//Logics

//Components

//Classes
//import {CategoryStatus} from "classes/general/CategoryStatus";
import {Category} from "classes/synapp/Category";
import {IsBusy} from "classes/general/IsBusy";
    
    type Props = {
        categories: Category[];
        categoryObject: any;
        setCategoryObject: Function;
        isBusy: IsBusy;
    }
   
const CategorySelector = (props: Props) => {

    const { categories, categoryObject, setCategoryObject, isBusy } = props;
    
    // useEffect (() => {
        
    // }, [categoryStatus]);

    return (
        <Row>
        <div className="flex gap-4">

            <div>Subject:</div>
            {/* <select disabled={isBusy.isBusy} value={categoryStatus.level0CategoryId} onChange={(e) => { setCategoryStatus("subjectId", e.target.value); }}> */}
            <select disabled={isBusy.isBusy} value={categoryObject.level0CategoryId} onChange={(e) => setCategoryObject({...categoryObject, level0CategoryId: e.target.value})}>
                <option value="None">None</option>
                {categories.filter((category) => category.level === 0).map((subject, index) => {
                    return (
                        <option key={"subject" + index} value={subject.id}>{subject.name}</option>
                    )
                })}
            </select>


            <div>Category:</div>
            {/* <select disabled={isBusy.isBusy} value={categoryStatus.level1CategoryId} onChange={(e) => {  setCategoryStatus("categoryId", e.target.value); }}> */}
            <select disabled={isBusy.isBusy} value={categoryObject.level1CategoryId} onChange={(e) =>  setCategoryObject({...categoryObject, level1CategoryId: e.target.value})}>
                <option value="None">None</option>
                {categories.filter((category) => category.level === 1 && category.parentId === categoryObject.level0CategoryId).map((category, index) => {
                    return (
                        <option key={"category" + index} value={category.id}>{category.name}</option>
                    )
                })}
            </select>

            <div>Subcategory:</div>
            <select disabled={isBusy.isBusy} value={categoryObject.level2CategoryId} onChange={(e) =>   setCategoryObject({...categoryObject, level2CategoryId: e.target.value})}>
                <option value="None">None</option>
                {categories.filter((category) => category.level === 2 && category.parentId === categoryObject.level1CategoryId).map((subCategory, index) => {
                    return (
                        <option key={"subCategory" + index} value={subCategory.id}>{subCategory.name}</option>
                    )
                })}
            </select>

        </div>
        </Row>
    );
}

export default CategorySelector;

// import {CategoryStatus} from "classes/general/CategoryStatus";
// import {Category} from "classes/general/Category";
// import {IsBusy} from "classes/general/IsBusy";

// type Props = {
//     categories: Category[];
//     categoryStatus: CategoryStatus;
//     setCategoryStatus: Function;
//     isBusy: IsBusy;
// }

// const CategorySelector = (props: Props) => {

//     const { categories, categoryObject, setCategoryObject, setCategoryStatus, isBusy } = props;
    
//     // useEffect (() => {
        
//     // }, [categoryStatus]);

//     return (
//         <Row>
//         <div className="flex gap-4">

//             <div>Subject:</div>
//             {/* <select disabled={isBusy.isBusy} value={categoryStatus.level0CategoryId} onChange={(e) => { setCategoryStatus("subjectId", e.target.value); }}> */}
//             <select disabled={isBusy.isBusy} value={categoryStatus.level0CategoryId} onChange={(e) => setCategoryStatus({...categoryStatus, level0CategoryId: e.target.value})}>
//                 <option value="None">None</option>
//                 {categories.filter((category) => category.level === 0).map((subject, index) => {
//                     return (
//                         <option key={"subject" + index} value={subject.id}>{subject.name}</option>
//                     )
//                 })}
//             </select>


//             <div>Category:</div>
//             {/* <select disabled={isBusy.isBusy} value={categoryStatus.level1CategoryId} onChange={(e) => {  setCategoryStatus("categoryId", e.target.value); }}> */}
//             <select disabled={isBusy.isBusy} value={categoryStatus.level1CategoryId} onChange={(e) =>  setCategoryStatus({...categoryStatus, level1CategoryId: e.target.value})}>
//                 <option value="None">None</option>
//                 {categories.filter((category) => category.level === 1 && category.parentId === categoryStatus.level0CategoryId).map((category, index) => {
//                     return (
//                         <option key={"category" + index} value={category.id}>{category.name}</option>
//                     )
//                 })}
//             </select>

//             <div>Subcategory:</div>
//             <select disabled={isBusy.isBusy} value={categoryStatus.level2CategoryId} onChange={(e) =>   setCategoryStatus({...categoryStatus, level2CategoryId: e.target.value})}>
//                 <option value="None">None</option>
//                 {categories.filter((category) => category.level === 2 && category.parentId === categoryStatus.level1CategoryId).map((subCategory, index) => {
//                     return (
//                         <option key={"subCategory" + index} value={subCategory.id}>{subCategory.name}</option>
//                     )
//                 })}
//             </select>

//         </div>
//         </Row>
//     );
// }

// export default CategorySelector;