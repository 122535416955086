
import { McqStoppingPoint } from "classes/synapp/page_challenge/McqStoppingPoint";
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";
import { CodeExerciseStoppingPoint } from "../code_exercise/CodeExerciseStoppingPoint";
import { StoppingPoint } from "classes/synapp/page_challenge/StoppingPoint";
import createGUID from "logic/utility/helper-functions/createGUID";

// class CodeblockExerciseStoppingPoint {
//     id: string = "";
//     guidRef: string = "";
//     componentRefLocation: string = "";
//     codeblockExerciseId: string = "";
// }

class PageChallenge {
    id: string = "";
    guidRef: string = createGUID(8);
    pageId: string = "";
    ownerId: string = "";
    name: string = "";
    //categoryId: string = ""; // top-level category id, under which all the Mcqs must be
    level0CategoryId: string = "None";
    level1CategoryId: string = "None";
    level2CategoryId: string = "None";
    mcqStoppingPoints: McqStoppingPoint[] = [];
    codeExerciseStoppingPoints: CodeExerciseStoppingPoint[] = [];
    stoppingPoints: StoppingPoint[] = [];
    dueDate: string = "";
    isLoaded: boolean = false; //for client side // Could redact
}

const getPageChallengeProperties = (thisObject: PageChallenge) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'name', propertyType: 'string', displayName: "Text challenge name", value: thisObject ? thisObject.name : "new" });
    return editableProperties;
}

export { PageChallenge, getPageChallengeProperties };