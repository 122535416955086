import createGUID from 'logic/utility/helper-functions/createGUID';
import { COMPONENT_TYPE } from 'classes/enums/component-types';
import {STATUS } from 'classes/enums/status';
import styleLogic from 'logic/gridslate/style/styleLogic';
import { COMPONENT_SUBTYPE } from 'classes/enums/component-subtypes';
import {ComponentData} from './ComponentData';
import { ImageData } from './ImageData';

// interface styleSetting {
//     [key: string]: string;
// }

const getDefaultSubtype = (type: COMPONENT_TYPE) => {
    switch (type) {
        case COMPONENT_TYPE.text:
            return COMPONENT_SUBTYPE.rich;
        case COMPONENT_TYPE.heading:
            return COMPONENT_SUBTYPE.type1;
        case COMPONENT_TYPE.title:
            return COMPONENT_SUBTYPE.none;
        case COMPONENT_TYPE.list:
            return COMPONENT_SUBTYPE.unordered;
        case COMPONENT_TYPE.codeblock:
            return COMPONENT_SUBTYPE.none;
        default:
            return COMPONENT_SUBTYPE.none;
    }
}

class Component {

    id : string = "";
    parentId : string = "";
    parentRef: string = "";
    type: COMPONENT_TYPE;
    subtype: COMPONENT_SUBTYPE;
    text: string;
    status: STATUS;
    componentRef: string;
    mediaLogId = "";
    data: ComponentData = new ComponentData();

    render: string = "yes"; //Only for client side rendering // yes, no, stoppingpoint

    constructor(type: COMPONENT_TYPE, subtype: COMPONENT_SUBTYPE = COMPONENT_SUBTYPE.none) {
        this.type = type;
        this.subtype = subtype===COMPONENT_SUBTYPE.none ? getDefaultSubtype(type) : subtype;
        this.text = (type === COMPONENT_TYPE.text) ? '<p>text</p>' : type;
        this.data.style = styleLogic.setDefaultStyle(type);
        this.status = STATUS.new;
        this.componentRef = createGUID(8);
        
        if (type === COMPONENT_TYPE.image) {
            this.data.specialisedData = new ImageData();
            this.subtype = COMPONENT_SUBTYPE.incomplete;
        }
       
    }

}

export {Component};