import TestTurtle from "views/synapp/tools/Turtle";
import Test from 'views/test/Test'
import CourseTemplateBrowser from "views/synapp/course/CourseTemplateBrowser";
import CourseInstanceBrowser from "views/synapp/course/CourseInstanceBrowser";
import CourseInstanceConstructor from "views/synapp/course/CourseInstanceConstructor";
//import GridslateNavbar from "views/navbar/GridslateNavbar";
import PageConstructor from "views/gridslate/constructor/PageConstructor";
import NavbarConstructor from 'components/gridslate/NavbarConstructor';
import Home from "views/gridslate/pages/Home";
//import NotFound from 'views/routes/NotFound'
import PageChallengeConstructor from 'views/synapp/constructor/page_challenge/PageChallengeConstructor';
import PageChallengeInstanceViewer from 'views/synapp/constructor/page_challenge/PageChallengeInstanceViewer';
import ChallengeConstructor from "views/synapp/constructor/ChallengeConstructor";
import AccountManager from 'views/admin/AccountManager';
//import StageFlashGame from "app_gridslate/slides/StageFlashGame";
//import RunFlashGame from "app_gridslate/slides/RunFlashGame";
import HelpPage  from 'views/gridslate/pages/Help';
import pageGroups from "classes/enums/page-groups";

//Store
//import { preconstructedPagesAtom } from 'atom';
//import { useAtom } from 'jotai';

type Props = {
    name: string;
}

const RenderPreConstructedPage = (props: Props) => {

    //const [allPreConstructedPages, setAllPreConstructedPages] = useAtom(preconstructedPagesAtom);

    let name = props.name;

    switch (name) {
        case pageGroups.Pages.TestTurtle:
            return (<TestTurtle></TestTurtle>);
        case pageGroups.Pages.Test:
            return <Test></Test>;
        case pageGroups.Pages.CourseTemplateBrowser:
            return <CourseTemplateBrowser></CourseTemplateBrowser>;
        case pageGroups.Pages.CourseInstanceBrowser:
            return <CourseInstanceBrowser></CourseInstanceBrowser>;
        case pageGroups.Pages.CourseInstanceConstructor:
            return <CourseInstanceConstructor></CourseInstanceConstructor>;
        case pageGroups.Pages.PageConstructor:
            return <PageConstructor></PageConstructor>;
        case pageGroups.Pages.NavbarConstructor:
            return <NavbarConstructor></NavbarConstructor>;
        case pageGroups.Pages.Home:
            return <Home></Home>;
        // case pageGroups.Pages.NotFound:
        //     return <NotFound></NotFound>;
        case pageGroups.Pages.PageChallengeConstructor:
            return <PageChallengeConstructor></PageChallengeConstructor>;
        case pageGroups.Pages.PageChallengeInstanceViewer:
            return <PageChallengeInstanceViewer></PageChallengeInstanceViewer>;
        case pageGroups.Pages.ChallengeConstructor:
            return <ChallengeConstructor></ChallengeConstructor>;
        case pageGroups.Pages.AccountManager:
            return <AccountManager></AccountManager>;
        // case pageGroups.Pages.StageFlashGame:
        //     return <StageFlashGame></StageFlashGame>;
        // case pageGroups.Pages.RunFlashGame:
        //     return <RunFlashGame></RunFlashGame>;
        case pageGroups.Pages.HelpPage:
            return <HelpPage></HelpPage>;

        default:
            return (<></>);
    }
}

export default RenderPreConstructedPage