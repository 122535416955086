// public class NavbarChild {
//     public string Id { get; set; }
//     public string PageRef { get; set; }
//     public string Title { get; set; }
// }

class NavbarChild {   
    route : string;
    pageRef: string;
    pageId: string;
    title: string;
    requiredClaims: string[] = [];
    type: number = 0;

    constructor(pageRef: string, pageId: string, title: string, route: string) {
        this.pageRef = pageRef;
        this.pageId = pageId;
        this.title = title;
        this.route = route;
    }
}

export { NavbarChild};