//React
import { useState, useEffect, useRef } from 'react';

//UI
import { Tabs, TabsRef, Button } from "flowbite-react";

//Services
import apiService from 'services/apiService';
import parseGradeCodeExerciseAILogic from 'logic/ai/parseGradeCodeExerciseAILogic';
import config from 'config';

//Logics
import codeEditorLogic from "logic/gridslate/codeEditor/codeEditorLogic";
import parseCodeEditor from "logic/parse/parseCodeEditor";

//Components
import Editor from 'react-simple-code-editor';

//Classes
import { CodeError } from 'classes/synapp/code_exercise/CodeError';
import { CodeExercise } from 'classes/synapp/code_exercise/CodeExercise';
import { CodeExerciseSubmission } from 'classes/synapp/code_exercise/CodeExerciseSubmission';
import { CodeExerciseSubmissionGrade } from 'classes/synapp/code_exercise/CodeExerciseSubmissionGrade';
// class CodeExerciseData {
//     title: string = "";
//     instructionText: string = "";
//     codeExerciseId: string = "";
//     sourceCode: string = "";
//     idealAnswer: string = "";
// }

type Props = {
    codeExercise: CodeExercise;
    codeExerciseSubmission: CodeExerciseSubmission;
    updateCodeExerciseSubmissionWithGrade: Function;
    editCodeExercise: Function;
    dueDatePassed: boolean;
}

const SingleCodeExercise = (props: Props) => {

    const { codeExercise, codeExerciseSubmission, updateCodeExerciseSubmissionWithGrade, editCodeExercise, dueDatePassed } = props;

    const [codeError, setCodeError] = useState<CodeError>(new CodeError());

    const tabsRef = useRef<TabsRef>(null);
    const [activeTab, setActiveTab] = useState(0);

    //const [data, setData] = useState<CodeExerciseData>(new CodeExerciseData());

    useEffect(() => {

        // let newCodeExerciseData = new CodeExerciseData();
        // newCodeExerciseData.title = "Test 01";
        // newCodeExerciseData.instructionText = "This is a test instruction";
        // newCodeExerciseData.codeExerciseId = "codeExerciseId";
        // newCodeExerciseData.sourceCode = "console.log('Hello World');";
        // newCodeExerciseData.idealAnswer = "console.log('Hello World');";

        // setData(newCodeExerciseData);
        //setData(codeExerciseData);
    }, []);


    // const editCodeExerciseData = (target: string, value: string) => {
    //     if (target === 'sourceCode') {
    //         setData({ ...data, sourceCode: value });
    //     }
    // }
    //useEffect(() => { }, [errorLine]);

    useEffect(() => {

        console.log("Load reached");
        window.onmessage = function (e) {
            console.log("Message:", e);
            //if (typeof(e.data) == "string" && e.data.substring(0,5) == 'error') {console.log(e.data);}
            if (typeof (e.data) == "string") {
                try {
                    let parsedError = JSON.parse(e.data);
                    //window.top.postMessage("error: "+evt.message +" at linenumber: "+evt.lineno+" of file: "+evt.filename, '*');
                    if (parsedError != null) {
                        setCodeError(parsedError);
                        console.log(parsedError);
                    }
                }
                catch (err) {

                }
                console.log(e.data);
            }

        };

        //TODO: Check if this is the right way to remove the event listener
        return () => {
            window.removeEventListener("message", () => { });
        }

    });

    // window.onload = function () {
    //     console.log("Load reached");
    //     window.onmessage = function (e) {
    //         console.log("Message:", e);
    //         //if (typeof(e.data) == "string" && e.data.substring(0,5) == 'error') {console.log(e.data);}
    //         if (typeof (e.data) == "string") {
    //             try {
    //                 let parsedError = JSON.parse(e.data);
    //                 //window.top.postMessage("error: "+evt.message +" at linenumber: "+evt.lineno+" of file: "+evt.filename, '*');
    //                 if (parsedError != null) {
    //                     setCodeError(parsedError);
    //                     console.log(parsedError);
    //                 }
    //             }
    //             catch (err) {

    //             }
    //             console.log(e.data);
    //         }

    //     };

    //     //TODO: Check if this is the right way to remove the event listener
    //     return () => {
    //         window.removeEventListener("message", () => { });
    //     }

    // }

    const handleRunCodeClick = () => {
        if (codeError.lineNumber !== "") { setCodeError(new CodeError()); }

        //let content = sourceCode;
        var iframe = document.getElementById('targetCode') as HTMLIFrameElement;
        if (!iframe) { return; }

        if (iframe.contentDocument) {
            iframe.contentDocument.open();
            iframe.contentDocument.write(codeEditorLogic.getHead(codeExerciseSubmission.codeExerciseId) + codeExerciseSubmission.sourceCode + codeEditorLogic.getTail());
            iframe.contentDocument.close();
        }

        return false;
    }

    // const hightlightWithLineNumbers = (input, language) =>
    //   highlight(input, language)
    //     .split("\n")
    //     .map((line, i) => {
    //       if (errorLine === null || (errorLine !== null && i + 1 !== parseInt(errorLine))) {
    //         return `<span class='editorLineNumber'>${i + 1}</span>${line}`
    //       }
    //       if (errorLine !== null && i + 1 === parseInt(errorLine)) {
    //         return `<span class='editorLineNumber red-line'>${i + 1}</span>${line}`
    //       }
    //       return '';
    //     })
    //     .join("\n");

    const gradeByAI = async () => {

        //setIsBusy(isBusy.generating());
        let request = { "Prompt": parseGradeCodeExerciseAILogic.returnPrompt(codeExercise, codeExerciseSubmission, "Korean") };
        let response = await apiService.post(config.apiUrl + '/cms/testGPT', request);
        if (response.success) {
            console.log(response.payload);
            let grade = parseGradeCodeExerciseAILogic.parseAIResponse(response.payload);
            let newGrades = [...codeExerciseSubmission.grades];
            newGrades.push(grade);
            //editCodeExercise({ ...codeExerciseSubmission, grades: newGrades });
            updateCodeExerciseSubmissionWithGrade({ ...codeExerciseSubmission, grades: newGrades });

            //set tab to create
            //mainTabsRef.current?.setActiveTab(0);
            //setIsBusy(isBusy.reset());
            //setShowAIGenerationModal(false);
        } else {
            //errorService.handleError(response);
            //setIsBusy(isBusy.reset());
        }


    }

    const setOutputTab = () => {
        let activeTabIndex = dueDatePassed ? 3 : 2;
        tabsRef.current?.setActiveTab(activeTabIndex);
    }

    return (
        <div className="w-full md:w-[800px]">
            {/* {JSON.stringify(codeError)} */}
            <Tabs
                //onSelect={(e) => setCurrentTab(e)
                ref={tabsRef}
                onActiveTabChange={(tab) => setActiveTab(tab)}
                className="mb-3"
            >
                <Tabs.Item active title="Instructions">
                    <p className="font-bold mb-4">Instructions</p>
                    <div className="whitespace-pre">
                        {codeExercise.instructionText}
                    </div>
                    {/* <textarea
                        className="code-exercise-instructions-textarea"
                        //type="textarea"
                        value={data.instructionText}
                    //onChange={(e) => dispatch(editCodeExerciseData({ componentId: componentgridLocationRef, target: 'instructionText', value: e.target.value }))}
                    /> */}
                </Tabs.Item>
                <Tabs.Item title="Code" >
                    <Button className="float-right" onClick={() => { handleRunCodeClick(); setOutputTab(); }} >Run Code</Button>
                    <strong>Code</strong>
                    {codeError.lineNumber !== "" && <div className="text-red-500">Error on line {codeError.lineNumber}: {codeError.message}</div>}
                    <div className="code-editor-div">

                        <Editor
                            value={codeExerciseSubmission.sourceCode}
                            padding={10}
                            className="code-editor"
                            onValueChange={(text: string) => editCodeExercise(text)}
                            highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode, codeExerciseSubmission.codeExerciseId, codeError)}
                            textareaId="codeArea"
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 18,
                                outline: 0
                            }}
                        />
                    </div>
                </Tabs.Item>
                {dueDatePassed && <Tabs.Item title="IdealAnswer">
                    <strong>Ideal Answer</strong>
                    <div className="code-editor-div">
                        <Editor
                            value={codeExercise.possibleAnswer}
                            padding={10}
                            className="code-editor"
                            disabled
                            onValueChange={(sourceCode) => { }}
                            highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode, codeExerciseSubmission.codeExerciseId, codeError)}
                            textareaId="codeArea"
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 18,
                                outline: 0
                            }}
                        />
                    </div>
                </Tabs.Item>}
                <Tabs.Item title="Output">
                    <strong>Output</strong>
                    <iframe style={{
                        // "border": "2px solid #ddd",
                        "height": "500px",
                        "width": "100%"
                    }}
                        name="targetCode"
                        title="Code Output"
                        id="targetCode">
                    </iframe>
                </Tabs.Item>
                <Tabs.Item title="Grading">
                    <strong>Grading</strong>
                    <Button onClick={() => gradeByAI()} />
                    {codeExerciseSubmission.grades.map((grade, index) => {
                        return (
                            <div key={index} className="grid gap-4">
                                <div>Final grade: {grade.grade}/10</div>
                                <div>Points: {grade.grade + " x difficulty (" + codeExercise.difficulty + ") = "} {grade.grade * codeExercise.difficulty} </div>
                                <div className="whitespace-pre-line">{grade.comments}</div>
                                <div>{grade.dateGraded}</div>
                            </div>
                        )
                    })}
                </Tabs.Item>

            </Tabs>
        </div>

    );
}

export default SingleCodeExercise;