
class CodeExerciseSubmissionGrade {
    public id: string = "";
    public codeExerciseSubmissionId: string = "";
    public grade: number = 0; //Always 1-100
    public gradeType: number = 0; //0 - teacher, 1 - auto, 2 - self, 3 - peer
    public graderId: string = "";
    public comments: string = "";
    public dateGraded: string = "";
}

export { CodeExerciseSubmissionGrade };