import {EditablePropertySelectItem} from "./EditablePropertySelectItem";

class EditablePropertyMetadata
{
    propertyName: string = "";
    propertyType: string = "";
    displayName: string = "";
    value: any = "";
    dataSource?: EditablePropertySelectItem[] = [];

    constructor(propertyName: string, propertyType: string, displayName: string, value: any, dataSource?: EditablePropertySelectItem[]) {
        this.propertyName = propertyName;
        this.propertyType = propertyType;
        this.displayName = displayName;
        this.value = value;
        this.dataSource = dataSource;
    }
}

export { EditablePropertyMetadata};