
//require('dotenv').config();

//React
import { useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//UI
import 'react-toastify/dist/ReactToastify.css';

//Services
import { ToastContainer } from 'react-toastify';
import useS3Fetch from 'services/useS3Fetch';

//Logics
//import UserLogic from 'utility/UserLogic';
import routeLogic from 'logic/gridslate/S3RouteLogic';
import useUser from 'logic/utility/hooks/useUser';
//import constructedPagesLogic from 'logic/gridslate/constructedPagesLogic';

//Components
import ViewPage from 'views/gridslate/pages/ViewPage';
import Test from 'views/test/Test';
import TestCamera from 'views/test/TestCamera';
//import TestTurtle from 'views/test/TestTurtle';
import GridslateNavbar from "views/gridslate/navbar/GridslateNavbar";
import Login from 'views/accounts/Login';
import Logout from 'views/accounts/Logout';
//REDACT GridslateConstructor????
import PageConstructor from "views/gridslate/constructor/PageConstructor";
import NavbarConstructor from 'components/gridslate/NavbarConstructor';
import Home from "views/gridslate/pages/Home";
import NotFound from 'views/gridslate/pages/NotFound'
import PageChallengeConstructor from 'views/synapp/constructor/page_challenge/PageChallengeConstructor';
//import PageChallengeViewer2 from 'app_gridslate/constructor/PageChallengeViewer2';
import PageChallengeInstanceViewer from './views/synapp/constructor/page_challenge/PageChallengeInstanceViewer';
import ChallengeConstructor from "views/synapp/constructor/ChallengeConstructor";
import CourseInstanceConstructor from './views/synapp/course/CourseInstanceConstructor';
import AccountManager from './views/admin/AccountManager';
import MyAccount from 'views/accounts/MyAccount';

import CourseInstanceBrowser from "views/synapp/course/CourseInstanceBrowser";

import CourseTemplateBrowser from "views/synapp/course/CourseTemplateBrowser";
import StageFlashGame from "views/synapp/slides/StageFlashGame";
import RunFlashGame from "views/synapp/slides/RunFlashGame";

import HelpPage  from 'views/gridslate/pages/Help';

import RenderPreConstructedPage from "views/render/RenderPreConstructedPage";

//Classes
import { NavbarElement } from 'classes/gridslate/navbar/NavbarElement';
import { NavbarChild } from 'classes/gridslate/navbar/NavbarChild';
import { NavbarClass } from 'classes/gridslate/navbar/Navbar';
import TestContextify from 'views/test/TestContextify';
import TestTipTap from 'views/test/TestTipTap';

function App() {

  const { payload: navbarS3, isPending: isPendingNavbar, error: errorNavbar } = useS3Fetch(routeLogic.GetNavbarRoute());
  const [navbar, setNavbar] = useState<NavbarClass>();

  //console.log(process.env.NODE_ENV + " " +  process.env.PUBLIC_URL);

  useEffect(() => {
    setNavbar(navbarS3 as unknown as NavbarClass);
  }, [navbarS3]);

  useUser();

  return (
    <div>
      <Router>
        {/* <UserLogic /> */}
        <ToastContainer />
        <GridslateNavbar navbar={navbar} />
        <Routes>

          {/* ==================== DYNAMIC routes, based on navbar.json */}

          {/* {navbar && navbar.elements.map((topLevelElement: NavbarElement, index: number) => (
            <React.Fragment key={'navroute_top_' + index}>
              {topLevelElement.children.length === 0 && <Route path={topLevelElement.route} element={<ViewPage pageRef={topLevelElement.pageRef} pageId={topLevelElement.pageId} />} />}
              {topLevelElement.children.length > 0 && topLevelElement.children.map((child: NavbarChild, index2: number) => (
                <Route key={'navroute_child_' + index2} path={child.route} element={<ViewPage pageRef={child.pageRef} pageId={child.pageId} />} />
              ))}
            </React.Fragment>
          ))} */}

          {navbar && navbar.elements.map((topLevelElement: NavbarElement, index: number) => (
            <React.Fragment key={'navroute_top_' + index}>
              {topLevelElement.children.length === 0 && topLevelElement.type === 0 &&
                <Route
                  path={"/" + topLevelElement.route}
                  element={<ViewPage pageRef={topLevelElement.pageRef}
                    pageId={topLevelElement.pageId} />}
                />}
              {topLevelElement.children.length === 0 && topLevelElement.type === 1 &&
                <Route
                  path={topLevelElement.route}
                  element={<RenderPreConstructedPage name={topLevelElement.pageId} />}
                />}
              {topLevelElement.children.length > 0 && topLevelElement.children.map((child: NavbarChild, index2: number) => (
                <React.Fragment key={'navroute_child_' + index2}>
                  {child.type === 0 &&
                    <Route
                      path={"/"+ topLevelElement.route + "/" + child.route}
                      element={<ViewPage pageRef={child.pageRef}
                        pageId={child.pageId} />}
                    />}
                  {child.type === 1 &&
                    <Route
                      path={"/" + topLevelElement.route + "/" + child.route}
                      element={<RenderPreConstructedPage name={child.pageId} />}
                    />}
                </React.Fragment>
                // <Route key={'navroute_child_' + index2} path={child.route} element={<ViewPage pageRef={child.pageRef} pageId={child.pageId} />} />
              ))}
            </React.Fragment>
          ))}

          {/* ==================== CUSTOM routes */}

          <Route path="/" element={<Home />} />
          <Route path="/test" element={<Test />} />
          <Route path="/testcamera" element={<TestCamera />} />
          <Route path="/testcontextify" element={<TestContextify />} />
          <Route path="/testtiptap" element={<TestTipTap />} />
          <Route path={`/help/faqs`} element={<HelpPage />} />
          {/* <Route path="/testturtle" element={<TestTurtle />} /> */}
          <Route path="/gridslate/constructor" element={<PageConstructor />} />
          <Route path="/gridslate/navbarconstructor" element={<NavbarConstructor />} />
          <Route path="/gridslate/challengeconstructor" element={<ChallengeConstructor />} />
          <Route path="/gridslate/PageChallengeConstructor" element={<PageChallengeConstructor />} />

          {/* ==================== Synapp routes */}

          {/* <Route path="/pagechallenge/:pageChallengeId" element={<PageChallengeViewer2 />} /> */}
          <Route path="/pagechallenge/:pageChallengeInstanceId" element={<PageChallengeInstanceViewer />} />
          <Route path="/courseinstanceconstructor" element={<CourseInstanceConstructor />} />

          {/* //Course */}
          <Route path="/courses/instancebrowser" element={<CourseInstanceBrowser />} />
          <Route path="/courses/:courseInstanceId" element={<CourseInstanceBrowser />} />

          {/* //Flashgame */}
          <Route path={`/coursetemplate/browser/:stateEditingOn`} element={<CourseTemplateBrowser />} />
          <Route path={`/coursetemplate/browser`} element={<CourseTemplateBrowser />} />
          <Route path={`/wordflash/stage/:type/:id`} element={<StageFlashGame />} />
          <Route path={`/wordflash/run`} element={<RunFlashGame />} />

          {/* ==================== Admin routes */}
          <Route path="/admin/accountmanager" element={<AccountManager />} />

          {/* ==================== ACCOUNT routes */}

          {/* <Route path="/accounts/myaccount" element={<MyAccount />} /> */}
          <Route path="/accounts/login" element={<Login />} />
          <Route path="/accounts/logout" element={<Logout />} />
          <Route path="/accounts/myaccount" element={<MyAccount />} />
            
            {/* ==================== 404 route */}

          <Route path="*" element={<NotFound />} />
          {/* <Route path="*" element={null} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;