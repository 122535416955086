
//FROM slides, TODO: revise
class CourseTemplate {
  id: string;
  title: string;
  tags: string[] = [];
  description: string;
  isPublic: boolean = false;
  creatorId: string;
  imageSrc: string = '';

  constructor(id: string = "", title: string = "", description: string = "", creatorId: string = "") {
    this.id = id;
    this.title = title;
    this.description = description;
    this.creatorId = creatorId;
  }

}

export { CourseTemplate };