//React
import { useEffect, useState, useRef } from 'react';

//UI
import { Button, Tabs, Modal, Textarea, RangeSlider, Spinner, TabsRef } from 'flowbite-react';
import { Row, CheckboxAndLabel } from 'components/ui_components/helper/HelperComponents';

//Services
import apiService from 'services/apiService';
import crudService from 'services/crudService';
//import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
import config from 'config';

//Logics
//import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';
import parseLogic from 'logic/parse/parseLogic';
import genericSaveLoadLogic from 'logic/saveload/genericSaveLoadLogic';

//Components
import GenericSaveLoadPanel from "components/gridslate/saveload/GenericSaveLoadPanel";

//Classes
import { FilterModel } from "classes/models/request/FilterModel";
import { CLASS } from 'classes/enums/classes';
import { STATUS } from 'classes/enums/status';
//import { Account, getAccountProperties } from 'classes/account/Account';
import { Account, getAccountProperties } from 'classes/accounts/Account';
import { CRUD_ACTION } from 'classes/enums/crud-action';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';

import { IsBusy } from 'classes/general/IsBusy';

type Props = {
}

const AccountManager = (props: Props) => {

    const [accounts, setAccounts] = useState<Account[]>([]);
    const [studentNumbers, setStudentNumbers] = useState<string>("");
    const [loadedAccountIndex, setLoadedAccountIndex] = useState<number>(-1);
    const [activeTab, setActiveTab] = useState(0);
    const [isBusy, setIsBusy] = useState(new IsBusy());

    const mainTabsRef = useRef<TabsRef>(null);

    useEffect(() => {

        const loadAccounts = async () => {
            let response = await apiService.get(config.apiUrl + "/admin/getAllAccounts");
            if (response.success) {
                setAccounts(response.payload);
            } else {
                errorService.handleError(response);
            }
            // setIsBusy(isBusy.loading(["accounts"]));
            // let filterModel = new FilterModel();
            // filterModel.OnlyOwner = true;
            // crudService.get(CLASS.account, filterModel).then(response => {
            //     if (response.success) {
            //         setAccounts(response.payload);
            //     } else {
            //         errorService.handleError(response);
            //     }
            //     setIsBusy(isBusy.loaded("accounts"));
            // });
        }

        if (accounts.length === 0) {
            loadAccounts();
        }

    }, []);

    //================================================================================================= - Edit Account

    // const crudActions = (action: CRUD_ACTION, value: any, target?: string) => {
    //     if (action === CRUD_ACTION.appendNewObject) {
    //         //expect value as new code challenge object
    //         var newAccounts = [...accounts, value];
    //         setLoadedAccountIndex(newAccounts.length - 1);
    //         setAccounts(newAccounts);
    //     }
    //     if (action === CRUD_ACTION.getUpdatedObject) {
    //         return accounts[loadedAccountIndex];
    //     }
    //     else if (action === CRUD_ACTION.returnNewObject) {
    //         let newAccount = new Account();
    //         return newAccount;
    //     }
    //     else if (action === CRUD_ACTION.loadObject) {
    //         //value is actually id of object here
    //         console.log(value);
    //         let index = accounts.findIndex((codeExercise) => codeExercise.id === value);
    //         setLoadedAccountIndex(index);
    //     }
    //     else if (action === CRUD_ACTION.updateObject) {
    //         //value as Account object
    //         let newAccount = value as Account;
    //         var tempAccounts = [...accounts];
    //         tempAccounts[loadedAccountIndex] = newAccount;
    //         setAccounts(tempAccounts);
    //         toast.success("Code Exercise Updated");
    //     }
    //     else if (action === CRUD_ACTION.updateObjectProperties) {
    //         let editableProperties = value as EditablePropertyMetadata[];
    //         let newAccounts = [...accounts];
    //         let newAccount = { ...newAccounts[loadedAccountIndex] };
    //         newAccount = parseLogic.parseObjectUpdate(newAccount, editableProperties);
    //         newAccounts[loadedAccountIndex] = newAccount;
    //         setAccounts(newAccounts);
    //     }

    //     else if (action === CRUD_ACTION.deleteObject) {
    //         //value is actually the object here
    //         //But SHOULD always be currently selected object, especially if disabling selection while pending
    //         var tempAccounts = [...accounts];
    //         //find index of object
    //         let index = tempAccounts.findIndex((codeExercise) => codeExercise.id === value.id);
    //         tempAccounts.splice(index, 1);
    //         setAccounts(tempAccounts);
    //         setLoadedAccountIndex(-1);
    //         toast.success("Code Exercise Deleted");
    //     }

    // }

    const createAccounts = async () => {

        let newAccounts = [] as Account[];
        let studentNumbersArray = studentNumbers.split(",");
        studentNumbersArray.forEach(studentNumber => {
            let account = new Account();
            studentNumber = studentNumber.trim();
            account.studentNumber = studentNumber;
            account.username = studentNumber;
            account.password = studentNumber;
            newAccounts.push(account);
        });

        let response = await apiService.post(config.apiUrl + "/admin/createAccounts", newAccounts);
        if (response.success) {
            toast.success("Accounts created");
            setAccounts(accounts.concat(response.payload));
            setStudentNumbers("");
        } else {
            errorService.handleError(response);
        }
    }


    //================================================================================== - Render Account Manager

    return (
        <div className='container mx-auto'>

            <Tabs aria-label="Default tabs" ref={mainTabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
                <Tabs.Item active title="View">

                    <div className="text-lg font-semibold">Accounts</div>
                    <div className="flex flex-col">
                        <div className="grid grid-cols-5">
                            <div>Username</div>
                            <div>Fullname</div>
                            <div>Email</div>
                            <div>Student Number</div>
                            <div>Actions</div>
                        </div>
                        {accounts.map((account, index) => {
                            return (
                                <div key={index} className="grid grid-cols-5">
                                    <div>{account.username}</div>
                                    <div>{account.fullname}</div>
                                    <div>{account.email}</div>
                                    <div>{account.studentNumber}</div>
                                    <div>
                                        <Button onClick={() => { }} size="small">Edit</Button>
                                        <Button onClick={() => { }} size="small">Delete</Button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </Tabs.Item>

                <Tabs.Item title="Create">
                    <p>Provide a list of student numbers separated by commas</p>

                    <Textarea placeholder="Student numbers" value={studentNumbers} onChange={(e) => setStudentNumbers(e.target.value)} />
                    <Button onClick={() => createAccounts()} size="small">Create</Button>
                </Tabs.Item>

                <Tabs.Item title="Permissions / Roles">
                </Tabs.Item>
            </Tabs>
        </div>
    )

}

export default AccountManager;