//React

//UI

//Services
import config from 'config';
import apiService from 'services/apiService';

//Logics

//Components

//Store

//Classes
import { GridLocation } from '../classes/gridslate/components/GridLocation';
import { Component } from 'classes/gridslate/components/Component';

class CreateMediaLogResponseModel {
    uploadUrl: string;
    mediaLogId: string;
    fullpath: string;

    constructor(uploadUrl: string, path: string, mediaLogId: string) {
        this.uploadUrl = uploadUrl;
        this.fullpath = path;
        this.mediaLogId = mediaLogId;
    }
}

class CreateMediaLogRequestModel {
    Type: string;
    FileExtension: string;
    Filename: string;

    constructor(type: string, fileExtension: string, filename: string) {
        this.Type = type;
        this.FileExtension = fileExtension;
        this.Filename = filename;
    }
}


const requestImageMediaLog = async (component: Component, location: GridLocation, setImageAttribute: Function) => {

    let localBlobRef = component.data.specialisedData.localBlobRef;
    let extension = component.data.specialisedData.extension;
    let componentRef = component.componentRef;

    //Create file
    let newBlob = await fetch(localBlobRef).then(r => r.blob());
    let fileFromBlob = new File([newBlob], "default.jpg");

    // let requestObject = {
    //     "Type": component.type,
    //     "FileExtension": extension,
    //     "Filename": componentRef,
    // }

    let requestObject = new CreateMediaLogRequestModel(component.type, extension, componentRef);
    var response = await apiService.post(config.apiUrl + "/cms/CreateMediaLog", requestObject);

    if (response.success) {
        var createMediaLogResponseModel = response.payload as CreateMediaLogResponseModel;
        var putS3Response = await apiService.put(createMediaLogResponseModel.uploadUrl, fileFromBlob)
        if (putS3Response.success) {
            setImageAttribute(location, 'src', createMediaLogResponseModel.fullpath, createMediaLogResponseModel.mediaLogId);
        } else {
            console.log("Put to S3 error: ", putS3Response);
            //TODO: Check about using errorService here
        }
    } else {
        console.log("MediaLog request error: ", response);
    }

}
export default requestImageMediaLog;

