//React

//UI

//Services
import crudService from "services/crudService"
import errorService from "services/errorService";

//Logics
import parseLogic from "logic/parse/parseLogic";

//Components

//Store

//Classes
import {FilterModel} from "classes/models/request/FilterModel";
import {CRUD_ACTION} from "classes/enums/crud-action";

//======================================================================== Classes



//======================================================================== Input/Output Functions

const save = async (objectType: string, thisObject: any, crudActions: Function, setIsSaving: Function) => {
    setIsSaving(true);
    let requestModel = parseLogic.stringifyDataProperty(thisObject);

    let response;
    if (thisObject.id === "") {
        response = await crudService.create(objectType, requestModel);
    } else {
        response = await crudService.update(objectType, requestModel);
    }
    if (response.success) {
        let newObject = parseLogic.unstringifyDataProperty(response.payload);
        crudActions(CRUD_ACTION.updateObject, newObject);
        setIsSaving(false);
    } else {
        setIsSaving(false);
        errorService.handleError(response);
    }
   
}

const del = async (objectType: string, thisObject: any, crudActions: Function, setIsDeleting: Function) => {
    setIsDeleting(true);
    //Delete by id
    let filterModel = new FilterModel([["Id", thisObject.id]]);
    let response = await crudService.del(objectType, filterModel);
    if (response.success) {
        setIsDeleting(false);
        crudActions(CRUD_ACTION.deleteObject, thisObject);
    } else {
        setIsDeleting(false);
        errorService.handleError(response);
    }
}

const load = async (objectType: string, objectIndex: string, crudActions: Function, setIsLoading: Function) => {
    crudActions(CRUD_ACTION.loadObject, objectIndex);
}

//======================================================================== Helper/parse Functions


export default {
    save,
    //update,
    del,
    load

};