//React

//UI

//Services
import crudService from "services/crudService";
import { toast } from "react-toastify";
import errorService from "services/errorService";

//Logics
import parseLogic from "logic/parse/parseLogic";

//Components

//Store

//Classes
import { FilterModel } from "classes/models/request/FilterModel";
import { Component } from "classes/gridslate/components/Component";
import { Page } from "classes/gridslate/page/Page";
import { CRUD_ACTION } from "classes/enums/crud-action";
import { CLASS } from "classes/enums/classes";
//======================================================================== Classes

class ObjectModel {
   page: any;
   components: any;

   constructor(page: any, components: any) {
      this.page = page;
      this.components = components;
   }
}

class CreateUpdatePageRequest {
   Page: Page;
   NewComponents: Component[];
   UpdatedComponents: Component[];
   DeletedComponentIds: string[];

   constructor(Page: Page, NewComponents: Component[], UpdatedComponents: Component[], DeletedComponentIds: string[]) {
      this.Page = Page;
      this.NewComponents = NewComponents;
      this.UpdatedComponents = UpdatedComponents;
      this.DeletedComponentIds = DeletedComponentIds;
   }
}

//======================================================================== Input/Output Functions

const save = async (objectType: string, thisObject: any, crudActions: Function, setIsSaving: Function) => {
   let data = thisObject as ObjectModel;
   setIsSaving(true);
   let response;
   let parsedComponents = parseLogic.parseComponentsForUpload(data.components);
   let createUpdatePageRequest = new CreateUpdatePageRequest(data.page, parsedComponents.newComponents, parsedComponents.updatedComponents, parsedComponents.deletedComponentIds);

   if (data.page.id === '') {
      //saveNewPage();
      response = await crudService.create(CLASS.page, createUpdatePageRequest);
   } else {
      //updatePage();
      response = await crudService.update(CLASS.page, createUpdatePageRequest);
   }

   if (response.success) {
      let newComponents = parseLogic.parseComponentsFromDownload(data.components, response.payload.newComponents, response.payload.updatedComponents, response.payload.deletedComponentIds);
      let loadModel = new ObjectModel(response.payload.page, newComponents);
      crudActions(CRUD_ACTION.loadObject, loadModel);
      setIsSaving(false);
      toast.success("Save successful");
   } else {
      setIsSaving(false);
      errorService.handleError(response);
   }

}

const del = async (objectType: string, thisObject: any, crudActions: Function, setIsDeleting: Function) => {
   let data = thisObject as ObjectModel;
   setIsDeleting(true);
   let filterModel = new FilterModel([["Id", data.page.id]]);
   let response = await crudService.del(CLASS.page, filterModel);
   if (response.success) {
      setIsDeleting(false);
      crudActions(CRUD_ACTION.deleteObject, data.page.id);
      toast.success("Delete successful");
   } else {
      setIsDeleting(false);
      errorService.handleError(response);
   }

}

const load = async (objectType: string, id: string, crudActions: Function, setIsLoading: Function) => {

   setIsLoading(true);
   let response = await crudService.load(CLASS.page, new FilterModel([["id", id]]));
   if (response.success) {
      setIsLoading(false);
      let components = parseLogic.parseComponentsFromLoad(response.payload.components);
      let loadModel = new ObjectModel(response.payload.page, components);
      crudActions(CRUD_ACTION.loadObject, loadModel);
   } else {
      setIsLoading(false);
      errorService.handleError(response);
   }
}

//======================================================================== Helper/parse Functions


export default {
   save,
   del,
   load
};