enum CRUD_ACTION {
    getUpdatedObject = "getUpdatedObject",
    returnNewObject = "returnNewObject",
    appendNewObject = "appendNewObject",
    loadObject = "loadObject",
    updateObject = "updateObject",
    updateObjectProperties = "updateObjectProperties",
    deleteObject = "deleteObject",
}

export { CRUD_ACTION};