//React
import { useState, useEffect } from 'react';

//UI
import { Button } from "flowbite-react";
import Container from "components/ui_components/helper/Container";

//Services
import crudService from 'services/crudService';
import errorService from 'services/errorService';

//Logics

//Components
import MCQConstructor from "components/synapp/mcq/MCQConstructor";
import CategorySelector from "components/synapp/CategorySelector";
import CodeExerciseConstructor from 'components/synapp/code_exercise/CodeExerciseConstructor2';

//Classes
import { IsBusy } from 'classes/general/IsBusy';
import { FilterModel } from "classes/models/request/FilterModel";
import { CLASS } from 'classes/enums/classes';
import { CategoryStatus } from "classes/synapp/CategoryStatus";

enum ChallengeType {
    none = 'none',
    mcq = 'mcq',
    code = 'code',
    text = 'text'
}

const ChallengeConstructor = () => {

    const [challengeType, setChallengeType] = useState(ChallengeType.none) //mcq, code, text
    const [categoryStatus, setCategoryStatus] = useState(new CategoryStatus());
    const [isBusy, setIsBusy] = useState(new IsBusy());

    useEffect(() => {

        const loadCategories = async () => {
            //Load all mcq categories for the user
            let filterModel = new FilterModel();
            filterModel.OnlyOwner = true;
            let response = await crudService.get(CLASS.category, filterModel);
            if (response.success) {
                let tempStatus = { ...categoryStatus };
                tempStatus.categories = response.payload;
                setCategoryStatus(tempStatus);
            }
            else {
                errorService.handleError(response);
            }
        }

        loadCategories();
    }, []);


    return (
        <Container>
            <h1>Challenge Constructor</h1>
            <div className="flex gap-4">
                <Button onClick={() => setChallengeType(ChallengeType.mcq)}>MCQ</Button>
                <Button onClick={() => setChallengeType(ChallengeType.code)}>Code</Button>
                <Button onClick={() => setChallengeType(ChallengeType.text)}>Text</Button>
            </div>

            {challengeType !== ChallengeType.none &&
                <CategorySelector
                    categories={categoryStatus.categories}
                    categoryObject={categoryStatus}
                    setCategoryObject={setCategoryStatus}
                    isBusy={isBusy}
                />}

            {challengeType === ChallengeType.mcq && <div>
                MCQ
                <MCQConstructor
                    content=""
                    level0CategoryId={categoryStatus.level0CategoryId}
                    level1CategoryId={categoryStatus.level1CategoryId}
                    level2CategoryId={categoryStatus.level2CategoryId}
                    selectingContentPending={false}
                    setSelectingContentPending={() => { }}
                />
            </div>}
            {challengeType === ChallengeType.code && <div>
                Code
                <CodeExerciseConstructor
                    content=""
                    level0CategoryId={categoryStatus.level0CategoryId}
                    level1CategoryId={categoryStatus.level1CategoryId}
                    level2CategoryId={categoryStatus.level2CategoryId}
                />
            </div>
            }

        </Container>
    )
}

export default ChallengeConstructor;