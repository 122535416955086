class FilterModel {
    PropertyFilters: string[][] = [];
    OnlyOwner: boolean = false;
    SpecialFilter: string = "";

    constructor(PropertyFilters: any = [], OnlyOwner: boolean = false) {
        this.PropertyFilters = PropertyFilters;
        this.OnlyOwner = OnlyOwner;
    }
}

export {FilterModel};