//React
import React, { useState } from "react";

//UI
import { Button, Modal, ToggleSwitch, Textarea, TextInput } from "flowbite-react";
import courseIcons from "components/ui_components/icons/courseIcons";

//Services

//Logics

//Components

//Store

//Classes
import { WordFlashSet, WordSet, SingleWord, TranslationKeyValue } from "classes/synapp/wordflash/WordFlashSet";

type WordFlashSetModalProps = {
    showModal: boolean,
    setShowModal: Function,
    wordFlashSet: WordFlashSet | null,
    handlePersistWordFlashSet: Function
}

const CreateWordFlashSetModal = (props: WordFlashSetModalProps) => {

    const { showModal, setShowModal, wordFlashSet, handlePersistWordFlashSet } = props;
    const [selectedWordSubSetIndex, setSelectedWordSubsetIndex] = useState<number>(0);
    const [tempWordFlashSet, setTempWordFlashSet] = useState<WordFlashSet>(wordFlashSet ? wordFlashSet : new WordFlashSet('', '', [new WordSet([], 'Set 1')]));


    const [showTextToWordsModal, setShowTextToWordsModal] = useState<boolean>(false);
    const [tempWords, setTempWords] = useState<string>('');

    const parseTextToWords = () => {
        let wordArray = tempWords.split('\n');
        let singleWords: SingleWord[] = [];
        wordArray.forEach((word: string) => {
            singleWords.push(new SingleWord(word, []));
        });
        let newWordFlashSet = { ...tempWordFlashSet };
        newWordFlashSet.wordSets[selectedWordSubSetIndex].words = newWordFlashSet.wordSets[selectedWordSubSetIndex].words.concat(singleWords);

        //remove whitespace from the end and beginning of each words
        newWordFlashSet.wordSets[selectedWordSubSetIndex].words.forEach((word: SingleWord) => {
            word.word = word.word.trim();
        });

        //remove all quotation marks and commas from the words
        newWordFlashSet.wordSets[selectedWordSubSetIndex].words.forEach((word: SingleWord) => {
            word.word = word.word.replace(/['",]+/g, '');
        });

        //delete any empty words from the list
        newWordFlashSet.wordSets[selectedWordSubSetIndex].words = newWordFlashSet.wordSets[selectedWordSubSetIndex].words.filter((word: SingleWord) => {
            return word.word !== '';
        });
        //delete any duplicate words from the list
        newWordFlashSet.wordSets[selectedWordSubSetIndex].words = newWordFlashSet.wordSets[selectedWordSubSetIndex].words.filter((word: SingleWord, index: number, self: SingleWord[]) => {
            return index === self.findIndex((t) => (
                t.word === word.word
            ));
        });

        setTempWordFlashSet(newWordFlashSet);
    }

    const editWordSet = (action: string, index: number, value: string) => {
        if (action === 'edit') {
            let newWordSet = { ...tempWordFlashSet.wordSets[selectedWordSubSetIndex] };
            newWordSet.words[index].word = value;
            let newWordFlashSet = { ...tempWordFlashSet };
            newWordFlashSet.wordSets[selectedWordSubSetIndex] = newWordSet;
            setTempWordFlashSet(newWordFlashSet);
        }
        if (action === 'delete') {
            let newWordSet = { ...tempWordFlashSet.wordSets[selectedWordSubSetIndex] };
            newWordSet.words.splice(index, 1);
            let newWordFlashSet = { ...tempWordFlashSet };
            newWordFlashSet.wordSets[selectedWordSubSetIndex] = newWordSet;
            setTempWordFlashSet(newWordFlashSet);
        }
    }

    const WordStub = (props: { word: SingleWord, index: number }) => {

        return (
            <div className='text-black rounded border-4 border-col-p1 bg-col-t1 m-3 p-1 w-fit inline-block whitespace-pre text-2xl'>
                {props.word.word + "  "}
                <div className='float-right cursor-pointer border-red-600 border-1 bg-red-200 p-1 text-sm' onClick={() => editWordSet('delete', props.index, '')}>{courseIcons.renderDeleteIcon(16, 16)}</div>
            </div>
        )
    }

    const renderWordSetStub = (wordSet: WordSet, index: number) => {

        const returnSelectedStyle = () => {
            let defaultStyle = 'text-black select-none rounded m-3 p-1 min-w-28 w-fit min-h-10 inline-block text-2xl font-bold text-center cursor-pointer ';
            if (index === selectedWordSubSetIndex) {
                return defaultStyle + 'border-4 border-col-p2 bg-col-s2';
            }
            return defaultStyle + 'border-2 border-col-p2 bg-col-s2 ';
        }

        return (
            <div
                key={"wordSetStub" + index}
                className={returnSelectedStyle()}
                onClick={() => setSelectedWordSubsetIndex(index)}
            >{wordSet.setName}
            </div>
        )
    }

    const handleDeleteWordSet = () => {
        let newWordFlashGame = { ...tempWordFlashSet };
        newWordFlashGame.wordSets.splice(selectedWordSubSetIndex, 1);
        setTempWordFlashSet(newWordFlashGame);
        if (selectedWordSubSetIndex > 0) {
            setSelectedWordSubsetIndex(selectedWordSubSetIndex - 1);
        } else if (newWordFlashGame.wordSets.length > 0) {
            setSelectedWordSubsetIndex(0);
        }
    }

    return (
        <React.Fragment>
            <Modal size="7xl" show={showModal} onClose={() => setShowModal(false)}>
                <Modal.Header>Edit Word Flash Set</Modal.Header>

                <Modal.Body >
                    <div className='flex gap-3 items-center mb-4'>
                        <div className='inline-block align-middle font-bold text-xl'>Title: </div>
                        {/* <TextInput className='w-full text-3xl' type='text' placeholder='Learning English: Chapter 1' value={tempWordFlashSet.title} onChange={(e) => setTempWordFlashSet({ ...tempWordFlashSet, title: e.target.value })} /> */}
                        <input
                            className="shadow text-xl appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder="Enter a title for the wordset here..."
                            value={tempWordFlashSet.title}
                            onChange={(e) => setTempWordFlashSet({ ...tempWordFlashSet, title: e.target.value })}
                        ></input>
                    </div>

                    <div className='grid grid-cols-12'>
                        <div className='col-span-11'>
                            {tempWordFlashSet.wordSets && tempWordFlashSet.wordSets.map((set: WordSet, i: number) => {
                                return renderWordSetStub(set, i);
                            })}
                            <div
                                className='rounded select-none m-3 p-1 min-w-28 w-fit min-h-12 inline-block text-2xl border-4 border-gray-400 cursor-pointer font-bold text-center text-gray-400'
                                onClick={() => {
                                    let newWordFlashSet = { ...tempWordFlashSet };
                                    newWordFlashSet.wordSets.push(new WordSet([], 'Set ' + (newWordFlashSet.wordSets.length + 1)));
                                    setSelectedWordSubsetIndex(newWordFlashSet.wordSets.length - 1);
                                    setTempWordFlashSet(newWordFlashSet);
                                    setShowTextToWordsModal(true);
                                }}>
                                Add Set
                            </div>
                        </div>
                        <div className='col-span-1 m-auto'>
                            <Button className=''
                                color='failure'
                                disabled={tempWordFlashSet.wordSets.length === 1}
                                outline onClick={() => handleDeleteWordSet()}
                            >{courseIcons.renderDeleteIcon(24, 24)} Set</Button>
                        </div>
                    </div>
                    <hr className="w-full h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />

                    {tempWordFlashSet.wordSets && tempWordFlashSet.wordSets[selectedWordSubSetIndex].words.map((word: SingleWord, i: number) => {
                        return <WordStub key={"wordStub" + i} word={word} index={i} />
                    })}
                    {tempWordFlashSet.wordSets[selectedWordSubSetIndex].words.length === 0 && <div className='inline-block text-xl'>There are no words in this set. Click here ➡ to add words.</div>}
                    <div
                        className='text-gray-400 font-bold rounded border-4 border-gray-400 bg-white m-3 p-1 w-fit inline-block text-2xl cursor-pointer'
                        onClick={() => {
                            setTempWords(tempWordFlashSet.wordSets[selectedWordSubSetIndex].words.map((word: SingleWord) => word.word).join('\n'));
                            setShowTextToWordsModal(true);
                        }}
                    >Add/Edit Words</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='bg-col-s1' onClick={() => setShowModal(false)}>Close</Button>
                    <Button className='bg-col-p1' disabled={tempWordFlashSet.title === ''} onClick={() => handlePersistWordFlashSet(tempWordFlashSet)}>Save</Button>
                </Modal.Footer>
                {/* {JSON.stringify(tempWordFlashSet)} */}
            </Modal>


            <Modal show={showTextToWordsModal} onClose={() => setShowTextToWordsModal(false)}>
                <Modal.Header>{tempWordFlashSet.wordSets[selectedWordSubSetIndex].setName} :Text to Words</Modal.Header>
                <Modal.Body>
                    <Textarea autoFocus rows={10} value={tempWords} onChange={(e) => setTempWords(e.target.value)}></Textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='bg-col-s1' onClick={() => { setTempWords(''); setShowTextToWordsModal(false) }}>Discard</Button>
                    <Button className='bg-col-s1' onClick={() => { parseTextToWords(); setTempWords(''); setShowTextToWordsModal(false) }}>Convert to Words</Button>
                </Modal.Footer>

            </Modal>
        </React.Fragment>
    );
}

export default CreateWordFlashSetModal;