
const getHead = (codeExerciseId: string) => {
    //let snippets = new CodeEditorSnippets();
    let snippetString = baseSourceCodeHead1 + codeExerciseId + baseSourceCodeHead2 + codeExerciseId + baseSourceCodeHead3;
    return snippetString;
}

const getTail = () => {
    return baseSourceCodeTail;
}

let baseSourceCodeHead1 = `
  <script>

  window.addEventListener("error", handleError, true);

  function handleError(evt) {
    evt.preventDefault();
      if (evt.message) { // Chrome sometimes provides this      
        //window.top.postMessage("error: "+evt.message +" at linenumber: "+evt.lineno+" of file: "+evt.filename, '*');
        window.top.postMessage(JSON.stringify({"codeExerciseId" : "`;

let baseSourceCodeHead2 = `","lineNumber": evt.lineno, "message":evt.message}), '*');
      } else {   
        //window.top.postMessage("error: "+evt.type+" from element: "+(evt.srcElement || evt.target), '*');
        window.top.postMessage(JSON.stringify({"codeExerciseId" : "`;

let baseSourceCodeHead3 = `", "message":evt.type}), '*');
      }
  }

  function print(text1, text2, text3, text4, text5)
{

var element = document.getElementById("workingSpace_id");

var newDiv = document.createElement("div");

var combinedText = text1;

if (text2 != undefined) combinedText = text1 + " " + text2;

newDiv.innerHTML = combinedText;
//newDiv.setAttribute("class", "workingText");
newDiv.setAttribute("style", "font-size: 20px");
newDiv.setAttribute("style", "font-family: 'Courier New', Courier, monospace");

element.appendChild(newDiv);	
}

function getRandom(range)
{
// return a number between 1 and range (inclusive)
return Math.floor( Math.random() * range) + 1;
}
  </script>
  
  <html>
<head>
  <title>Hello</title>
</head> 
<script> window.onload = function() {`;

let baseSourceCodeTail = `
}</script>
<body>
  <div id="workingSpace_id" style="white-space: pre-wrap" ></div>
</body>
</html>`;

export default { getHead, getTail };