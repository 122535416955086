let appDeployment = process.env.NODE_ENV;
//let appDeployment = "production";
let appSite = "synapp";
let configObject = {
    "apiUrl": "",
    //"gridslateUrl": "",
    "slideshowWebsocket": "",
    "slideJoinUrl": "",
    //"S3BucketURL": "",
    "S3Bucket": "",
    "S3Region": "",
    "site" : "",
    "deployment": appDeployment,
    "siteTitle" : ""
};

if (appSite === "synapp" && appDeployment === "development") {
    configObject = {
        "apiUrl": "https://localhost:56004",
        //"gridslateUrl": "https://localhost:56004",
        "slideshowWebsocket": "wss://eu30e67qx2.execute-api.ap-northeast-2.amazonaws.com/Prod/",
        "slideJoinUrl": "http://localhost:3000/join/",
        //"S3BucketURL": "https://s3.ap-northeast-2.amazonaws.com/synapp.slides.storage/development/",
        "S3Bucket": "synapp.frontend",
        "S3Region": "https://s3.ap-northeast-2",
        "site" : "synapp",
        "deployment": appDeployment,
        "siteTitle" : "Synapp"
    }
}

if (appSite === "synapp" && appDeployment === "production") {
    configObject = {
        "apiUrl": "https://larll8xata.execute-api.ap-northeast-2.amazonaws.com/Prod",
        //"gridslateUrl": "https://nny0tkrwa7.execute-api.ap-northeast-2.amazonaws.com/Prod",
        "slideshowWebsocket": "wss://eu30e67qx2.execute-api.ap-northeast-2.amazonaws.com/Prod/",
        "slideJoinUrl": "https://synappslides.com/join/",
        //"S3BucketURL": "https://s3.ap-northeast-2.amazonaws.com/synapp.slides.storage/production/",
        "S3Bucket": "synapp.frontend",
        "S3Region": "https://s3.ap-northeast-2",
        "site" : "synapp",
        "deployment": appDeployment,
        "siteTitle" : "Synapp"
    }
}

export default configObject;


