import { EditablePropertyMetadata } from "classes/gridslate/EditablePropertyMetadata";

class Account {
    id: string;
    username: string;
    handle: string;
    fullname: string;
    organisation: string;
    studentNumber: string;
    email: string;
    password: string;
    emailConfirmed: boolean;
    isGoogleLinked: boolean;
    googleCredentials: string;
    phoneNumber: string;
    profileImageSrc: string;

    constructor() {
        this.id = "";
        this.username = "";
        this.handle = "";
        this.fullname = "";
        this.organisation = "";
        this.studentNumber = "";
        this.email = "";
        this.password = "";
        this.emailConfirmed = false;
        this.isGoogleLinked = false;
        this.googleCredentials = "";
        this.phoneNumber = "";
        this.profileImageSrc = "";
    }
}

const getAccountProperties = (thisObject: Account) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'username', propertyType: 'string', displayName: "Username", value: thisObject? thisObject.username : ""});
    editableProperties.push({ propertyName: 'handle', propertyType: 'string', displayName: "Handle", value: thisObject? thisObject.username : ""});
    editableProperties.push({ propertyName: 'fullname', propertyType: 'string', displayName: "Fullname", value: thisObject? thisObject.username : ""});
    editableProperties.push({ propertyName: 'organisation', propertyType: 'string', displayName: "Organisation", value: thisObject? thisObject.username : ""});
    
    return editableProperties;
}

export { Account, getAccountProperties };