//React
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Components 

//Services/Helpers
import apiService from "services/apiService";
import errorService from "services/errorService";
import config from "config";

//Store

//Views/Components

//Classes

const StageFlashGame = () => {

    const { type, id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {

        const getGame = async () => {
            let response = await apiService.get(config.apiUrl + '/cms/getflashset/' + type + "/" + id);
            if (response.success){
                setIsLoading(false);
                navigate('/wordflash/run', { state: { gameType: type, gameObject: response.payload }, replace: true});
            } else {
                errorService.handleError(response);
            }
        }

        if (type === undefined || (type !== 'word' && type !== 'qa')) {
            console.log('StageFlashGame: invalid type:', type);
            navigate('/wordflash/browser');
        } else {
            getGame();
        }

        console.log('StageFlashGame: type:', type, 'id:', id);
    }, [type, id]);

    return (
        <div className='container mx-auto'>
        <div className='my-auto mx-auto'>
        <h1 className='text-3xl mt-8 text-center'>Loading</h1>
          <img className='animate-spin m-auto w-[32] h-[32]' src={require('assets/images/synapp.png')}></img>
        </div>
        </div>
    );
    }

export default StageFlashGame;