//React
import { useState, useEffect } from "react";

//UI

//Services
import config from 'config';
import apiService from "./apiService";
import errorService from "./errorService";

//Logics

//Components

//Store

//Classes

//TODO: Re-check AbortController for latest version of React
const useApiGet = (apiUrl : string, url : string) => {

    const [payload, setPayload] = useState<[] | null>(null);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setIsPending(true);
        
        const abortCont = new AbortController();
      
        if (apiUrl === undefined) {
            apiUrl = config.apiUrl;
        }

        apiService.get(apiUrl + url)
        .then((response) => {
            if (response.success){
                setPayload(response.payload);
                setIsPending(false);
                setError("");
            } else {
                if (response.statusCode === 401) {
                    errorService.handleError(response);
                }
                setIsPending(false);
                setError(response.message);
            }
            
        })
         
        //Abort controller functionality
        return () => abortCont.abort();
      
    }, [url])


    return {payload, isPending, error}
}

export default useApiGet;
