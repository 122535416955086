class ThemeColor {
    title: string;
    className: string;
    value: string;
   
    constructor(title: string, name: string, value: string) {
        this.title = title;
        this.className = name;
        this.value = value;
    }
   
}

export { ThemeColor};