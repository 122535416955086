// public class StoppingPointStatus {
//     public string Id { get; set; }
//     public string UserId { get; set; }
//     public string PageChallengeId { get; set; }
//     public string Type { get; set; } //Mcq, Text, Codeblock, etc
//     public string DataCompleted { get; set; }
    
// }

class StoppingPointStatus {
    id: string = "";
    ownerId: string = "";
    pageChallengeId: string = "";
    stoppingPointGuidRef: string = "";
    type: string = "";
    dateCompleted: string = "";
    isCompleted: boolean = false;

    reopen: boolean = false; //for client side
}

export { StoppingPointStatus };