import {ThemeColor} from './ThemeColor';
import {ComponentStylePair} from './ComponentStylePair';

class StyleThemeData {
    themeColors: ThemeColor[];
    componentPairs: ComponentStylePair[];

    constructor(themeColors: ThemeColor[] = [], componentPairs: ComponentStylePair[] = []) {
        this.themeColors = themeColors;
        this.componentPairs = componentPairs;
    }
}

export { StyleThemeData};