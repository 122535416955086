enum PageGroupCategory {
    Test = "Test",
    Gridslate = "Gridslate",
    Synapp = "Synapp"
}

enum PageGroupSubCategory {
    Test = "Test",
    Course = "Course",
    Admin = "Admin",
    Constructor = "Constructor",  
}

enum Pages {
    TestTurtle = "TestTurtle",
    Test = "Test",
    // GridslateConstructor = "GridslateConstructor",
    PageConstructor = "PageConstructor",
    NavbarConstructor = "NavbarConstructor",
    CourseInstanceConstructor = "CourseInstanceConstructor",
    CourseInstanceBrowser = "CourseInstanceBrowser",
    CourseTemplateBrowser = "CourseTemplateBrowser",
    ChallengeConstructor = "ChallengeConstructor",
    PageChallengeConstructor = "PageChallengeConstructor",
    PageChallengeViewer2 = "PageChallengeViewer2",
    PageChallengeInstanceViewer = "PageChallengeInstanceViewer",
    AccountManager = "AccountManager",
    Home = "Home",
    HelpPage = "HelpPage",
    StageFlashGame = "StageFlashGame",
    RunFlashGame = "RunFlashGame"
}

class ConstructedPageLookup {
    groupCategory: PageGroupCategory;;
    groupSubcategory: string = "";
    pages: string[] = [];

    constructor(groupCategory: PageGroupCategory, groupSubCategory: PageGroupSubCategory, pages: string[]) {
        this.groupCategory = groupCategory;
        this.groupSubcategory = groupSubCategory;
        this.pages = pages;        
    }
}

let preconstructedPages = [] as ConstructedPageLookup[];

preconstructedPages.push(new ConstructedPageLookup(PageGroupCategory.Test, PageGroupSubCategory.Test, [Pages.TestTurtle, Pages.Test]));
preconstructedPages.push(new ConstructedPageLookup(PageGroupCategory.Gridslate, PageGroupSubCategory.Constructor, [Pages.PageConstructor, Pages.NavbarConstructor]));
preconstructedPages.push(new ConstructedPageLookup(PageGroupCategory.Synapp, PageGroupSubCategory.Course, [Pages.CourseInstanceConstructor, Pages.CourseInstanceBrowser, Pages.CourseTemplateBrowser, Pages.ChallengeConstructor, Pages.PageChallengeConstructor]));

const convertPagesToGroupedOptions = () => {
    let groupedOptions = [] as any[];
    let groupCategories = preconstructedPages;
    groupCategories.forEach((groupCategory) => {
        let groupCategoryOptions = [] as any[];
        groupCategoryOptions.push({ value: "None", label: "Select page" });
        groupCategory.pages.forEach((page) => {
            groupCategoryOptions.push({ value: page, label: page });
        })
        groupedOptions.push({ label: groupCategory.groupCategory, options: groupCategoryOptions });
    })
    return groupedOptions;
}

export default {PageGroupCategory, PageGroupSubCategory, Pages, ConstructedPageLookup, preconstructedPages, convertPagesToGroupedOptions};