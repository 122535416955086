import { COMPONENT_TYPE } from 'classes/enums/component-types';
import {StyleSetting} from './StyleSetting';
import { COMPONENT_SUBTYPE } from 'classes/enums/component-subtypes';

class ComponentStylePair {
    name: string;
    styleSetting: StyleSetting;
    type: COMPONENT_TYPE;
    subtype: COMPONENT_SUBTYPE;

    constructor(name: string, componentStyle: StyleSetting, componentType: COMPONENT_TYPE, componentSubtype: COMPONENT_SUBTYPE = COMPONENT_SUBTYPE.none) {
        this.name = name;
        this.styleSetting = componentStyle;
        this.type = componentType;
        this.subtype = componentSubtype;
    }
}

export { ComponentStylePair};