import createGUID from "logic/utility/helper-functions/createGUID";

class CodeExerciseStoppingPoint {
    id: string = "";
    guidRef: string = createGUID(8);
    name : string = "";
    componentRefLocation: string = "";
    challengeGuidRef: string = "";
    minimumPointThreshold: number = 3;
    level0CategoryId: string = "None";
    level1CategoryId: string = "None";
    level2CategoryId: string = "None";
    isRequired: boolean = false;    
}

export { CodeExerciseStoppingPoint};