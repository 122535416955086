//React
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Components / helpers 
import { Button, Card, Breadcrumb, ToggleSwitch, Modal, Tooltip, TextInput, Alert, Select, Avatar } from "flowbite-react";
import { HiInformationCircle, HiHome, HiOutlineExclamationCircle } from "react-icons/hi";
import courseIcons from 'components/ui_components/icons/courseIcons';
import flashSetLogic from "logic/synapp/flashSetLogic";

//Services
//import useApiGet from "services/useApiGet";
import config from "config";
import apiService from "services/apiService";
import errorService from "services/errorService";
import { toast } from "react-toastify";
import QRCode from 'react-qr-code'; //TODO: Implement QR code for sharing games
import crudService from "services/crudService";

//Store
import { userAtom } from 'atom';
import { useAtom } from 'jotai';

//Views/Components
import CreateWordFlashSetModal from "./CreateWordFlashSetModal";
import CreateQAFlashSetModal from "./CreateQAFlashSetModal";

//Classes
import { WordFlashSet, WordSet } from "classes/synapp/wordflash/WordFlashSet";
import { CourseTemplate } from "classes/synapp/course/CourseTemplate";
import { QAFlashSet } from "classes/synapp/wordflash/QAFlashSet";
import { IsBusy } from "classes/general/IsBusy";
import { FilterModel } from "classes/models/request/FilterModel";
import { CLASS } from 'classes/enums/classes';
import { STATUS } from 'classes/enums/status';
import { Course, CourseEnrollment, getCourseProperties } from 'classes/synapp/course/Course';
import { StoppingPointStatus } from 'classes/synapp/page_challenge/StoppingPointStatus';
import { PageChallenge } from 'classes/synapp/page_challenge/PageChallenge';
import { PageChallengeInstance } from 'classes/synapp/page_challenge/PageChallengeInstance';
import { CRUD_ACTION } from 'classes/enums/crud-action';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';
import { InternalErrorCodes } from "classes/enums/internal-error-codes";

function CourseTemplateBrowser() {

  //=================================================================================================== SIMPLE HELPER CLASSES
  class CurrentAction {
    type: string = "";
    id: string;
    isSaving: boolean = false;
    isLoading: boolean = false;
    isCloning: boolean = false;
    isDeleting: boolean = false;
    isEditing: boolean = false;

    constructor(type: string, id: string) {
      this.type = type;
      this.id = id;
      if (type === 'saving') {
        this.isSaving = true;
      }
      if (type === 'loading') {
        this.isLoading = true;
      }
      if (type === 'cloning') {
        this.isCloning = true;
      }
      if (type === 'deleting') {
        this.isDeleting = true;
      }
      if (type === 'editing') {
        this.isEditing = true;
      }
    }

    reset = () => {
      this.type = '';
      this.isSaving = false;
      this.isLoading = false;
      this.isCloning = false;
      this.isDeleting = false;
      this.isEditing = false;
      return this;
    }
  }

  class BreadcrumbItem {
    title: string;
    path: string;
    icon: any;
    constructor(title: string, path: string, icon: any) {
      this.title = title;
      this.path = path;
      this.icon = icon;
    }
  }

  class CloneData {
    type: string;
    index: number;

    constructor(type: string, index: number) {
      this.type = type;
      this.index = index;

    }
  }

  //=================================================================================================== STATE

  const baseLink = 'https://synapp.org/wordflash/stage';
  const { stateEditingOn, courseInstanceId } = useParams();

  const [path, setPath] = useState<BreadcrumbItem[]>([new BreadcrumbItem('Courses', '/course/browser', HiHome)]);
  const [selectedCourseTemplate, setSelectedCourseTemplate] = useState<CourseTemplate | null>(null);
  const [user] = useAtom(userAtom);
  const [editingOn, setEditingOn] = useState<boolean>(false);
  const [hasEditingPrivileges, setHasEditingPrivileges] = useState<boolean>(false);

  const [filterPublic, setFilterPublic] = useState<boolean>(true);
  const [filterPrivate, setFilterPrivate] = useState<boolean>(false);

  //const { payload: publicCourseTemplates, isPending: isPendingCT, error: errorCT } = useApiGet(config.apiUrl, '/cms/GetPublicCourseTemplates');
  //const { payload: yourCourseTemplates, isPending: isPendingYCT, error: errorYCT } = useApiGet(config.apiUrl, '/cms/GetCourseTemplates');
  //const [privateCourseTemplates, setPrivateCourseTemplates] = useState<CourseTemplate[]>([]); 
  const [courseTemplates, setCourseTemplates] = useState<CourseTemplate[]>([]);

  const [wordFlashSets, setWordFlashSets] = useState<WordFlashSet[]>([]);
  const [qAFlashSets, setQAFlashSets] = useState<QAFlashSet[]>([]);

  //Modal states
  const [showCreateWordFlashSetModal, setShowCreateWordFlashSetModal] = useState<boolean>(false);
  const [showCreateQAFlashSetModal, setShowCreateQAFlashSetModal] = useState<boolean>(false);
  const [showCreateCourseTemplateModal, setShowCreateCourseTemplateModal] = useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showQRModal, setShowQRModal] = useState<boolean>(false);

  //For creating new courses and word flash sets
  const [newCourseTemplate, setNewCourseTemplate] = useState<CourseTemplate>(new CourseTemplate());
  const [tempWordFlashSet, setTempWordFlashSet] = useState<WordFlashSet>(new WordFlashSet('', '', [new WordSet([], 'Set 1')]));
  const [tempQAFlashSet, setTempQAFlashSet] = useState<QAFlashSet>(new QAFlashSet('', ''));

  //For delete confirmation prompt
  const [deleteID, setDeleteID] = useState<string>('');
  const [deleteType, setDeleteType] = useState<string>('');

  //For cloning/copying
  const [showCopyToModal, setShowCopyToModal] = useState<boolean>(false);
  const [cloneData, setCloneData] = useState<CloneData>(new CloneData('', -1));
  const [selectedCopyToCourseTemplateId, setSelectedCopyToCourseTemplateId] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);
  //const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<CurrentAction>(new CurrentAction('loading', 'courses')); //For spinners

  const [qrCodeLink, setQRCodeLink] = useState<string>('');

  const [isBusy, setIsBusy] = useState(new IsBusy());
  const [courses, setCourses] = useState<Course[]>([]);
  const [pageChallengeInstances, setPageChallengeInstances] = useState<PageChallengeInstance[]>([]);
  const [loadedCourseIndex, setLoadedCourseIndex] = useState<number>(-1);

  //let creatorId = "";

  const navigate = useNavigate();

  //=================================================================================================== USE EFFECTS

  useEffect(() => {

    //TODO: Change to courseInstance
    const loadCourses = () => {
      setIsBusy(isBusy.loading(["courses"]));
      let filterModel = new FilterModel();
      if (courseInstanceId && courseInstanceId !== 'undefined') {
        filterModel.PropertyFilters = [["Id", courseInstanceId]];
      }
      //filterModel.OnlyOwner = true;
      //filterModel.PropertyFilters = [["IsPublic", user.id]];
      crudService.get(CLASS.course, filterModel).then(response => {
        if (response.success) {
          //set status to unchanged
          let loadedCourses = response.payload as Course[];
          loadedCourses.forEach(course => {
            course.status = STATUS.unchanged;
          });
          setCourses(loadedCourses);
          if (courseInstanceId && courseInstanceId !== 'undefined') {
            if (loadedCourses.length > 0){
              setLoadedCourseIndex(0);
            } else {
              toast.error('Course not found');
            }
          }
        } else {
          errorService.handleError(response);
        }
        setIsBusy(isBusy.loaded("courses"));
      });
    }

    if (courses.length === 0 ) {
      loadCourses();
    }

  }, []);

  // useEffect(() => {
  //   const loadPageChallenges = () => {
  //     setIsBusy(isBusy.loading(["pageChallenges"]));
  //     let filterModel = new FilterModel();
  //     filterModel.OnlyOwner = true;
  //     crudService.get(CLASS.pageChallenge, filterModel).then(response => {
  //       if (response.success) {
  //         let loadedPageChallenges = response.payload as PageChallenge[];
  //         setPageChallenges(loadedPageChallenges);
  //         if (loadedPageChallenges.length > 0) {
  //           setSelectedPageChallengeId(loadedPageChallenges[0].id);
  //         }
  //       } else {
  //         errorService.handleError(response);
  //       }
  //       setIsBusy(isBusy.loaded("pageChallenges"));
  //     });
  //   }

  //   if (pageChallenges.length === 0) {
  //     loadPageChallenges();
  //   }

  // }, []);

  useEffect(() => {
    const loadPageChallengeInstances = () => {
      setIsBusy(isBusy.loading(["pageChallengeInstances"]));
      let filterModel = new FilterModel();
      //filterModel.OnlyOwner = true;
      filterModel.PropertyFilters = [["CourseId", courses[loadedCourseIndex].id]];
      crudService.get(CLASS.pageChallengeInstance, filterModel).then(response => {
        if (response.success) {
          //let loadedPageChallengeInstances = response.payload as PageChallengeInstance[];
          setPageChallengeInstances(response.payload);
        } else {
          errorService.handleError(response);
        }
        setIsBusy(isBusy.loaded("pageChallengeInstances"));
      });
    }

    if (loadedCourseIndex !== -1) {
      loadPageChallengeInstances();
    }

  }, [loadedCourseIndex]);

  // useEffect(() => {
  //   if (publicCourseTemplates && yourCourseTemplates) {
  //     applyFilters(filterPublic, filterPrivate);
  //     setCurrentAction(currentAction.reset());

  //   }
  // }, [publicCourseTemplates, yourCourseTemplates]);

  useEffect(() => {
    applyFilters(filterPublic, filterPrivate);
  }, [filterPublic, filterPrivate]);

  useEffect(() => {
    if (user.loggedIn) {
      if (user.claims.includes('Master') || user.claims.includes('Teacher')) {
        setHasEditingPrivileges(true);
      }
      setFilterPrivate(true);
    }
  }, [user]);

  useEffect(() => {
    if (stateEditingOn && stateEditingOn === 'true') {
      setEditingOn(true);
    }
  }, [stateEditingOn]);
  //=================================================================================================== CRUD I/O


  //=================================================================================================== FUNCTIONS



  const loadCourseTemplate = async (courseTemplate: CourseTemplate) => {
    setCurrentAction(new CurrentAction('loading', 'games'));
    let newPathItem = new BreadcrumbItem(courseTemplate.title, '', null);
    if (!selectedCourseTemplate) {
      setPath(path.concat(newPathItem));
    } else {
      setPath(path.slice(0, path.length - 1).concat(newPathItem));
    }
    setSelectedCourseTemplate(courseTemplate);

    let response = await apiService.get(config.apiUrl + '/cms/GetGamesByCourseTemplateId/' + courseTemplate.id);
    if (response.success) {
      //setQuestionAnswerFlashGames(response.payload.qAFlashSets);
      setQAFlashSets(response.payload.qaFlashSets);
      setWordFlashSets(response.payload.wordFlashSets);
    } else {
      //toast.error(response.message);
      errorService.handleError(response);
    }
    setCurrentAction(currentAction.reset());
  }

  const startFlashGame = async (type: string, index: number) => {
    if (type === 'qa') {
      //navigate('/wordflash/run', { state: { gameType: 'qa', gameObject: qAFlashSets[index] } });
      navigate('/wordflash/stage/qa/' + qAFlashSets[index].id);
    }
    if (type === 'word') {
      //navigate('/wordflash/run', { state: { gameType: 'word', gameObject: wordFlashSets[index] } });
      navigate('/wordflash/stage/word/' + wordFlashSets[index].id);
    }
  }

  //=================================================================================================== HELPER

  const applyFilters = (filterPublic: boolean, filterPrivate: boolean) => {
    // if (!publicCourseTemplates || !yourCourseTemplates) {
    //   return;
    // }
    // if (filterPublic && filterPrivate) {
    //   let allCourseTemplates = [...publicCourseTemplates].concat([...yourCourseTemplates]) as CourseTemplate[];
    //   //remove duplicates
    //   allCourseTemplates = allCourseTemplates.filter((courseTemplate, index, self) =>
    //     index === self.findIndex((t) => (
    //       t.id === courseTemplate.id
    //     ))
    //   );
    //   setCourseTemplates(allCourseTemplates);
    // } else if (filterPublic) {
    //   setCourseTemplates(publicCourseTemplates);
    // } else if (filterPrivate) {
    //   setCourseTemplates(yourCourseTemplates);
    // } else {
    //   setCourseTemplates([]);
    // }
  }

  //=================================================================================================== UI TOOLBARS AND MODALS

  const renderBreadcrumbs = () => {
    return (
      <div>
        <Breadcrumb className="bg-gray-50 px-5 py-6 dark:bg-gray-800 mb-3">
          {path.map((item: BreadcrumbItem, index: number) => (
            <Breadcrumb.Item className='text-2xl' key={item.title} href={item.path} icon={item.icon}>
              {item.title}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    );
  }

  const renderFilterToolbar = () => {
    return (
      <div className='grid grid-cols-3'>

        {!selectedCourseTemplate && <div className='flex align-middle mb-3'>
          {/* <span className='text-xl font-bold mr-3'>
            Filter:
          </span> */}
          <Button size='sm' pill outline={!filterPublic}
            className={filterPublic ? 'ml-3 text-2xl bg-col-s1 mr-3' : 'text-xs bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
            onClick={() => { applyFilters(!filterPublic, filterPrivate); setFilterPublic(!filterPublic) }}
          ><span className='mr-3'>{courseIcons.renderOpenHandsIcon(24, 24)}</span> Public Courses</Button>
          <Button size='sm' pill outline={!filterPrivate}
            className={filterPrivate ? 'bg-col-s1 mr-3' : 'bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
            onClick={() => { applyFilters(filterPublic, !filterPrivate); setFilterPrivate(!filterPrivate) }}
          ><span className='mr-3'>{courseIcons.renderLockedIcon(24, 24)}</span>Your Courses</Button>
        </div>}
        {selectedCourseTemplate && <h1 className='text-xl md:text-2xl my-2 font-bold'>Course: {selectedCourseTemplate.title}</h1>}
        <div></div>
        {hasEditingPrivileges && <div className='relative h-10'>
          <ToggleSwitch color='gray' className='absolute right-2' checked={editingOn} label={editingOn ? 'Editing on' : 'Editing off'} onChange={() => setEditingOn(!editingOn)} />
        </div>}
      </div>

    )
  }

  const renderQRCodeModal = () => {
    return (
      <Modal show={showQRModal} size="6xl" onClose={() => { setShowQRModal(false) }} popup>
        <Modal.Header>Launch Flashset Game</Modal.Header>
        <Modal.Body>
          <div className='flex flex-col items-center'>
            <QRCode value={qrCodeLink} />
            <p className='text-3xl my-6'>Share this link if you want your students to launch individual games</p>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  //render icons and information for the relevant card
  const renderCourseInfoBar = (type: string, object: CourseTemplate) => {

    if (type === 'course') {
      return (
        <div className='grid grid-cols-3 place-items-center'>
          <div className='border-4 p-2 border-col-p1'>{courseIcons.renderCourseIcon()}</div>
          {/* <div className='inline-flex'>{courseIcons.renderBrainIcon()}</div> */}
          {!object.isPublic && <Tooltip className="transition-opacity delay-700" content="Private">
            <div>{courseIcons.renderLockedIcon()}</div>
          </Tooltip>}
          {object.isPublic && <Tooltip className="transition-opacity delay-700" content="Publicly accessible">
            <div>{courseIcons.renderOpenHandsIcon()}</div>
          </Tooltip>}
          {object.creatorId === user.id && <Tooltip className="transition-opacity delay-700" content="Yours!">
            <Avatar className='cursor-default' alt="Owner" placeholderInitials={user?.initials} rounded />
          </Tooltip>}
        </div>
      )
    }



    return (
      <>
        <div className='grid grid-cols-3 place-items-center'>

          {/* {creatorId === user.id &&
            <Tooltip className="transition-opacity delay-700" content="Edit">
              <Button
                size='xs' className='bg-col-p2' outline
                disabled={currentAction.id === objectId}
                onClick={() => handleEdit()}>
                {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isEditing)) && courseIcons.renderEditIcon()}
                {currentAction.id === objectId && currentAction.isEditing && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
              </Button>
            </Tooltip>} */}

          {/* <Tooltip className="transition-opacity delay-700" content="Copy to">
            <Button
              size='xs' className='bg-col-p2' outline
              disabled={currentAction.id === objectId}
              onClick={() => { handleClone() }}>
              {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isCloning)) && courseIcons.renderCopyIcon()}
              {currentAction.id === objectId && currentAction.isCloning && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
            </Button>
          </Tooltip> */}

          {/* {creatorId === user.id &&
            <Tooltip className="transition-opacity delay-700" content="Delete">
              <Button
                size='xs' className='' outline color='failure'
                disabled={currentAction.id === objectId}
                onClick={() => {
                  setShowConfirmDeleteModal(true);
                  setDeleteID(objectId);
                  setDeleteType(type);
                }}>
                {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isDeleting)) && courseIcons.renderDeleteIcon()}
                {currentAction.id === objectId && currentAction.isDeleting && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
              </Button>
            </Tooltip>} */}
        </div>

      </>
    )


  }

  const renderLoadingCard = () => {
    return (<Card className="max-w-md border-dotted cursor-pointer">
      <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>Loading...</span>
    </Card>)
  }

  //=================================================================================================== RENDER

  return (
    <div className='container mx-auto'>

      {/* //TODO: Convert to components */}
      {renderBreadcrumbs()}
      {renderFilterToolbar()}
      {/* {JSON.stringify(isBusy)} */}

      {editingOn && !user.loggedIn && <Alert className='my-8 text-sm md:text-lg' color="failure" icon={HiInformationCircle}>
        <span className="text-sm md:text-lg">Info alert!</span> You are not logged in so you cannot create new course templates or flash sets.
        Log in or create an account to continue. <a href='/accounts/login' className='underline'>Click here to log in</a>
      </Alert>}

      {loadedCourseIndex === -1 &&
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-col-6'>
          {/* {currentAction.isLoading && currentAction.id === 'courses' && renderLoadingCard()} */}
          {isBusy.isLoading && isBusy.loadingQueue.includes('courses') && renderLoadingCard()}
          {courses && courses.map((course: Course, index: number) => (
            <Card className="max-w-md flex" key={course.name + index}>
              {/* {renderCourseInfoBar('course', course)} */}
              <div className='flex'>
                <h5 className='text-lg h-16 md:text-2xl font-bold'>{course.name}</h5>
              </div>

              <div className='h-32'>
                {/* <div className='text-md'>{courseTemplate.isPublic ? 'This course is public' : 'Your course'}</div>
                <div className='text-md'>{courseTemplate.creatorId === user.id ? 'You own this course' : ''}</div> */}
                <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal">{course.description}</div>
              </div>
              <Button className="bg-col-p2" onClick={() => setLoadedCourseIndex(index)}>Open</Button>
              {/* {editingOn && renderEditToolbar('course', index, courseTemplate)} */}
            </Card>

          ))}
          {editingOn && <Card className="max-w-sm border-dotted cursor-pointer" onClick={() => setShowCreateCourseTemplateModal(true)}>
            <span className='inline-flex text-lg min-h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new course template'}
              {!user.loggedIn && 'Log in to create new course templates'}
            </span>
          </Card>}
        </div>}
      {loadedCourseIndex !== -1 && <div>
        {/* <h1 className='text-xl md:text-2xl my-2'>{selectedCourseTemplate.title}</h1> */}

        {/* ======================================================================= Page Challenges */}

        <h2 className='text-lg md:text-2xl my-2 font-bold'>Chapter Challenges</h2>
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-col-6'>
        {/* {currentAction.isLoading && currentAction.id === 'games' && renderLoadingCard()} */}
        {isBusy.isLoading && isBusy.loadingQueue.includes('courses') && renderLoadingCard()}
          {pageChallengeInstances && pageChallengeInstances.map((pageChallengeInstance: PageChallengeInstance, index: number) => (
            <Card className="max-w-sm" key={pageChallengeInstance.name + index}>
              <div className="flex">
                <span className='inline-flex mr-3'>
                  {courseIcons.renderBrainIcon()}
                </span>
                <span className='inline-flex text-lg h-16 md:text-2xl font-bold'>{pageChallengeInstance.name}</span>
              </div>
              <div className="leading-snug h-16 whitespace-normal ml-3">{pageChallengeInstance.dueDate}</div>
              <div className="h-16 whitespace-normal ml-3">{pageChallengeInstance.completedUserIds.find(id => id === user.id) ? 'Completed' : 'Not completed'}</div>
              {/* //QR Code Icon */}
              {/* <div
                className='cursor-pointer mx-auto'
                onClick={() => { setQRCodeLink(baseLink + '/word/' + wordset.id); setShowQRModal(true) }}
              >{courseIcons.renderQRCodeIcon(32, 32)}
              </div> */}
              {/* //Launch button */}
              <Button className='bg-col-p2' onClick={() => navigate('/pagechallenge/' + pageChallengeInstance.id)}>Launch</Button>

              {/* {editingOn && renderEditToolbar('word', index, wordset)} */}
            </Card>

          ))}

        </div>

        {/* ======================================================================= WORDFLASH SETS */}

        <h2 className='text-lg md:text-2xl my-2 font-bold'>Wordflash Sets</h2>
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-col-6'>
          {/* {isBusy.isLoading && isBusy.loadingQueue.includes('games') && renderLoadingCard()} */}
          {isBusy.isLoading && isBusy.loadingQueue.includes('games') && renderLoadingCard()}
          {wordFlashSets && wordFlashSets.map((wordset: WordFlashSet, index: number) => (
            <Card className="max-w-sm" key={wordset.title + index}>
              <div className="flex">
                <span className='inline-flex mr-3'>
                  {courseIcons.renderBrainIcon()}
                </span>
                <span className='inline-flex text-lg h-16 md:text-2xl font-bold'>{wordset.title}</span>
              </div>
              <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal ml-3">{flashSetLogic.joinWordFlashSetWords(wordset)}</div>
              {/* //QR Code Icon */}
              <div
                className='cursor-pointer mx-auto'
                onClick={() => { setQRCodeLink(baseLink + '/word/' + wordset.id); setShowQRModal(true) }}
              >{courseIcons.renderQRCodeIcon(32, 32)}
              </div>
              {/* //Launch button */}
              <Button className='bg-col-p2' onClick={() => startFlashGame('word', index)}>Launch</Button>

              {/* {editingOn && renderEditToolbar('word', index, wordset)} */}
            </Card>

          ))}

          {/* {editingOn && <Card className="max-w-sm min-h-64 border-dotted cursor-pointer" onClick={() => handleCreateFlashSet('word')}>
            <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new wordflash set'}
              {!user.loggedIn && 'Log in to create wordflash sets'}
            </span>
          </Card>} */}
        </div>

        {/* ======================================================================= QA FLASH SETS */}
        <hr className="w-full h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <h2 className='text-lg md:text-2xl my-2 font-bold'>Question Answer Flash Sets</h2>
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg: grid-col-6'>
          {qAFlashSets && qAFlashSets.map((qaSet: QAFlashSet, index: number) => (
            <Card className="max-w-sm" key={qaSet.title + index}>
              <div className="flex">
                <span className='inline-flex mr-3'>
                  {courseIcons.renderSpeechIcon()}
                </span>
                <span className='inline-flex  text-lg h-16 md:text-2xl font-bold'>{qaSet.title}</span>
              </div>
              <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal ml-3">{flashSetLogic.joinQASets(qaSet)}</div>
              {/* //QR Code Icon */}
              <div
                className='cursor-pointer mx-auto'
                onClick={() => { setQRCodeLink(baseLink + '/qa/' + qaSet.id); setShowQRModal(true) }}
              >{courseIcons.renderQRCodeIcon(32, 32)}
              </div>
              {/* //Launch button */}
              <Button className="bg-col-p2" onClick={() => startFlashGame('qa', index)}>Launch</Button>
              {/* {editingOn && renderEditToolbar('qa', index, qaSet)} */}
            </Card>
          ))}

          {/* {editingOn && <Card className="max-w-sm min-h-64 border-dotted cursor-pointer" onClick={() => handleCreateFlashSet('qa')}>
            <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new question/answer set'}
              {!user.loggedIn && 'Log in to create question/answer sets'}
            </span>
          </Card>} */}
        </div>
      </div>}

      {/* {renderCreateCourseTemplateModal()}
      {renderConfirmDeleteModal()}
      {renderQRCodeModal()}
      {renderCopyToModal()} */}
      {/* {showCreateWordFlashSetModal &&
        <CreateWordFlashSetModal
          showModal={showCreateWordFlashSetModal}
          setShowModal={setShowCreateWordFlashSetModal}
          wordFlashSet={tempWordFlashSet}
          handlePersistWordFlashSet={handlePersistWordFlashSet}
        />}
      {showCreateQAFlashSetModal &&
        <CreateQAFlashSetModal
          showModal={showCreateQAFlashSetModal}
          setShowModal={setShowCreateQAFlashSetModal}
          qAFlashSet={tempQAFlashSet}
          handlePersistWordFlashSet={handlePersistQAFlashSet}
        />} */}

    </div>
  );


}

export default CourseTemplateBrowser;