//React
import { Link } from "react-router-dom";
import React from "react";
import { useEffect } from "react";

//UI
import { Avatar, Navbar, Dropdown } from "flowbite-react";

//Services
import configObject from "config";

//Logics
import constructedPageLogic from 'logic/gridslate/constructedPagesLogic';

//Components
import SynappIcon from "components/ui_components/icons/SynappIcon";

//Store
import { userAtom } from 'atom';
import { useAtom } from 'jotai';

//Classes
import { NavbarClass } from "classes/gridslate/navbar/Navbar";
import { NavbarElement } from "classes/gridslate/navbar/NavbarElement";
import { NavbarChild } from "classes/gridslate/navbar/NavbarChild";

type Props = {
  navbar: NavbarClass | undefined;
}

const GridslateNavbar = (props: Props) => {
  const { navbar } = props;
  const [user] = useAtom(userAtom);
  //const [darkMode, setDarkMode] = useState('light');

  useEffect(() => {
    console.log('User changed');
  }, [user.loggedIn]);

  // const setDarkModeFromStorage = () => {
  //   if (localStorage.getItem('color-theme')) {
  //     setDarkMode(localStorage.getItem('color-theme'));
  //     // if NOT set via local storage previously
  //   } else {
  //     // if user prefers dark mode
  //     if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //       setDarkMode('dark');
  //       localStorage.setItem('color-theme', 'dark');
  //     } else {
  //       setDarkMode('light');
  //       localStorage.setItem('color-theme', 'light');
  //     }
  //   }
  // }

  // const toggleDarkMode = () => {
  //   if (darkMode === 'dark') {
  //     setDarkMode('light');
  //     localStorage.setItem('color-theme', 'light');
  //     document.documentElement.classList.add('dark');
  //   } else {
  //     setDarkMode('dark');
  //     localStorage.setItem('color-theme', 'dark');
  //     document.documentElement.classList.remove('dark');
  //   }
  // }

  const checkClaims = (requiredClaims: string[]) => {
    if (!requiredClaims || requiredClaims.length === 0) {
      return true;
    }
    if (user.loggedIn) {
      let claimsString = user.claims;
      for (let i = 0; i < requiredClaims.length; i++) {
        if (claimsString.includes(requiredClaims[i])) {
          return true;
        }
      }
    }
    return false;
  }

  return (

    <div className='container mx-auto'>

      <Navbar fluid rounded className='bg-col-t1 border-b-4 border-col-s2'>
        <Navbar.Brand href="/">
          {/* <img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" /> */}
          <SynappIcon className="w-8 h-8 mr-3" />
          <span className="self-center whitespace-nowrap text-xl md:text-3xl text-col-p2 font-semibold dark:text-white">{configObject.siteTitle}</span>
        </Navbar.Brand>

        <Navbar.Collapse>

          <Link to={"/"}><span className='text-col-p2 md:text-xl'>Home</span></Link>

          {navbar && navbar.elements.map((item: NavbarElement, index1: number) => (
            <React.Fragment key={"navdiv" + index1}>
              {item.pageId !== "dropdown" && checkClaims(item.requiredClaims) &&
                <Navbar.Link className='text-col-p2 text-xl' href={"/" + item.route}>{item.title}</Navbar.Link>}
              {item.pageId === "dropdown" && checkClaims(item.requiredClaims) &&
                <Dropdown
                  label={<span className='text-col-p2 md:text-xl'>{item.title}</span>}
                  arrowIcon={true}
                  inline
                >
                  {item.children.map((child: NavbarChild, index2: number) => (
                    // <Dropdown.Item href={"/" + child.route} key={"navbar" + index1 + "-" + index2}><span className='md:text-lg'>{child.title}</span></Dropdown.Item>
                    <React.Fragment key={"navbar" + index1 + "-" + index2}>
                      {checkClaims(child.requiredClaims) &&
                        <Dropdown.Item href={"/" + item.route + "/" + child.route}><span className='md:text-lg'>{child.title}</span></Dropdown.Item>
                      }
                    </React.Fragment>
                  ))
                  }
                </Dropdown>
              }

            </React.Fragment>
          ))}
        </Navbar.Collapse>
        {!user.loggedIn &&
          <div>
            <Link to={"/accounts/login"} >Log in</Link>
          </div>}
        {/* {user.loggedIn && user.claims.includes("Master") &&

          <Dropdown
            label='Slides'
            arrowIcon={true}
            inline
          >
            <Dropdown.Item href={"/slideshow/constructor"}>Constructor</Dropdown.Item>
            <Dropdown.Item href={"/slideshow/browser"}>Browser</Dropdown.Item>
          </Dropdown>
        } */}


        {
          user.loggedIn &&
          <Dropdown
            arrowIcon={false}
            inline
            label={
              <Avatar alt="User settings" placeholderInitials={user.initials} rounded />
            }
          >
            <Dropdown.Header>
              <span className="block text-sm">{user.username}</span>
            </Dropdown.Header>

            {user.claims.includes("Master") && <>

              <Dropdown.Item href={"/teststuff"}>Test Stuff</Dropdown.Item>
              <Dropdown.Item href={"/testcamera"}>Test Camera</Dropdown.Item>
              <Dropdown.Item href={"/gridslate/constructor"}>Gridslate Constructor</Dropdown.Item>
              <Dropdown.Item href={"/gridslate/PageChallengeConstructor"}>Page Challenge Constructor</Dropdown.Item>
              <Dropdown.Item href={"/admin/accountmanager"}>Account Manager</Dropdown.Item>
              <Dropdown.Item href={"/CourseInstanceConstructor"}>Course Instance Constructor</Dropdown.Item>

            </>}
            <Dropdown.Item href={"/accounts/myaccount"}>My Account</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href={"/accounts/logout"}>Sign out</Dropdown.Item>
          </Dropdown>
        }
        {/* < ToggleSwitch checked={darkMode === 'dark' ? true : false} label="Darkmode" onChange={() => toggleDarkMode()} /> */}
        {/* <button id="theme-toggle" type="button" className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
          <svg id="theme-toggle-dark-icon" className="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path></svg>
          <svg id="theme-toggle-light-icon" className="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </button> */}
        <Navbar.Toggle />
      </Navbar>

    </div>
  );
}

export default GridslateNavbar;