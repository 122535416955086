import {CategoryStatus} from "classes/synapp/CategoryStatus";
import createGuid from "logic/utility/helper-functions/createGUID";

class McqStoppingPoint {
    id: string = "";
    guidRef: string = createGuid();
    name : string = "New stopping point";
    passGrade: number = 50;
    numberOfQuestionsPerChallenge: number = 3;
    componentRefLocation: string = "";
    //categoryId: string = "";
    level0CategoryId: string = "None";
    level1CategoryId: string = "None";
    level2CategoryId: string = "None";
    
    //categoryStatus: CategoryStatus = new CategoryStatus(); //for client side
    //completed: Boolean = false; //for client side
}

export { McqStoppingPoint};