import { CodeExercise } from "classes/synapp/code_exercise/CodeExercise";
import { CodeExerciseSubmission } from "classes/synapp/code_exercise/CodeExerciseSubmission";
import { CodeExerciseSubmissionGrade } from "classes/synapp/code_exercise/CodeExerciseSubmissionGrade";

// const parseAIResponse = (inputString: string) => {

//     let grade = new CodeExerciseSubmissionGrade();

//     // Split the input string into lines
//     let lines = inputString.trim().split('\n');

//     //trim the lines
//     lines.forEach((line, index) => {
//         lines[index] = line.trim();
//     });

//     let lineSpacingGrade = (lines[0] !== undefined) ? parseInt(lines[0]) : 0;
//     let variableNamesGrade = (lines[1] !== undefined) ? parseInt(lines[1]) : 0;
//     let correctAnswerGrade = (lines[2] !== undefined) ? parseInt(lines[2]) : 0;
//     let comment = (lines[3] !== undefined) ? lines[3] : "";

//     //Build comment
//     let commentComplete = "Line spacing grade: " + lineSpacingGrade + "\nVariable names grade: " + variableNamesGrade + "\nCorrect answer grade: " + correctAnswerGrade;
//     commentComplete += "\nComment: " + comment;

//     grade.grade = lineSpacingGrade + variableNamesGrade + correctAnswerGrade;
//     grade.comments = commentComplete;
//     grade.gradeType = 1;
//     grade.dateGraded = new Date().toString();

//     return grade;
// }

// const parseAIResponse = (inputString: string) => {

//     let grade = new CodeExerciseSubmissionGrade();

//     // Split the input string into lines
//     let lines = inputString.trim().split('\n');

//     //trim the lines
//     lines.forEach((line, index) => {
//         lines[index] = line.trim();
//     });

//     let formattingGrade = (lines[0] !== undefined) ? parseInt(lines[0]) : 0;
//     let correctAnswerGrade = (lines[1] !== undefined) ? parseInt(lines[1]) : 0;
//     let comment = (lines[2] !== undefined) ? lines[2] : "";

//     //Build comment
//     let commentComplete = "Formatting grade: " + formattingGrade + "\nCorrect answer grade: " + correctAnswerGrade;
//     commentComplete += "\nComment: " + comment;

//     grade.grade = formattingGrade + correctAnswerGrade;
//     grade.comments = commentComplete;
//     grade.gradeType = 1;
//     grade.dateGraded = new Date().toString();

//     return grade;
// }

const parseAIResponse = (inputString: string) => {

    let grade = new CodeExerciseSubmissionGrade();

    // Split the input string into lines
    let lines = inputString.trim().split('\n');

    //trim the lines
    lines.forEach((line, index) => {
        lines[index] = line.trim();
    });

    let formattingGrade = (lines[0] !== undefined) ? parseInt(lines[0]) : 0;
    let correctAnswerGrade = (lines[1] !== undefined) ? parseInt(lines[1]) : 0;
    let comment = (lines[2] !== undefined) ? lines[2] : "";

    //Build comment
    let commentComplete = "Formatting grade: " + formattingGrade + "\nCorrect answer grade: " + correctAnswerGrade;
    commentComplete += "\nComment: " + comment;

    grade.grade = formattingGrade + correctAnswerGrade;
    grade.comments = commentComplete;
    grade.gradeType = 1;
    grade.dateGraded = Date.now().toString();

    return grade;
}

const returnPrompt = (codeExercise: CodeExercise, codeExerciseSubmission: CodeExerciseSubmission, responseLanguage?: string) => {

    let firstPromptAttempt =
        "Grade the following javascript coding exercise. The maximum score is 10. Assign up to 2 points for well chosen variable names." +
        "Assign up to 2 points for formatting, which includes correct line spacing, indentation, and ending lines with a semicolon." +
        "Assign up to 6 points for the correct answer. " +
        "The instruction for the coding exercise is as follows: " + codeExercise.instructionText +
        "The submitted source code for the coding exercise is as follows: " + codeExerciseSubmission.sourceCode +
        "When grading, also make a short comment on the quality of the answer or make an encouraging comment to the student." +
        "Return the grade in the following format: 2\n2\n6\ncomment. The first number is the grade for line spacing," +
        "the second number is the grade for variable names, the third number is the grade for the correct answer," +
        "and the fourth is the comment. If indentation is not necessary, assign the full 2 points for each category. " +
        "If there are no variables in the source code, assign the full 2 points for that category.";

    let secondPromptAttempt =
        "Grade the following javascript coding exercise. The maximum score is 10. Assign up to 2 points for well chosen variable names." +
        "Assign up to 2 points for formatting, which includes correct line spacing, indentation, and ending lines with a semicolon." +
        "Assign up to 6 points for the correct answer. " +
        "The instruction for the coding exercise is as follows: " + codeExercise.instructionText +
        "The submitted source code for the coding exercise is as follows: " + codeExerciseSubmission.sourceCode +
        "Note that students might use custom functions: print and getRandom. print takes outputs strings to an HTML page where the code runs. getRandom is a simplified function to get random numbers." +
        //"When grading, also make a short comment on the quality of the answer or make an encouraging comment to the student." +
        "Return the grade in the following format: 3\n7\ncomment. The first number is the grade for formatting - a number between 0 and 3. Formatting includes proper indentation, good line spacing, using semicolons at the end of each statement, naming variables properly, and using a consistent case convention" +
        "the second number is for the correct answer. The 'comment' part is an explanation for the grades. For example, " +
        "'Improve your formatting by using camelCase for variable names. Also, use indentation inside codeblocks.'" +
        "Very importantly: if there are no variables or no need for indentation in the source code, don't grade for these categories.";

    let thirdPromptAttempt =
        "A student has submitted a coding exercise for grading. The students are second language speakers with no previous coding experience." +
        "Grade the following javascript coding exercise. The maximum score is 10, 3 point for formatting and 7 for the correct answer." +
        "The instructions given to the student for the coding exercise is as follows: '" + codeExercise.instructionText + "'." +
        "Note that students might use custom functions: print and getRandom. print takes a string and output the string to an HTML page where the code runs. getRandom is a simplified function to get random numbers." +
        "The source code submitted by the student is as follows: '" + codeExerciseSubmission.sourceCode + "'." +
        "An example of a correct answer is as follows: '" + codeExercise.possibleAnswer + "'." +
        "When grading, also make a short comment explaining why you have awarded the score you have." +
        "Be a generous grader and do not try to find faults if the answer is mostly correct." +
        "Return your answer in the following format: number1\nnumber2\ncomment. number1 is the formatting grade, with a maximum of 3 points. number2 is the correct answer grade, with a maximum of 7 points. comment is the comment explaining the grade" +
        "For example, if you give the student 3 points and 4 points and your comment is 'Good overall. Formatting was good. But the answer was not completely correct.', return '3\n4\nGood overall. Formatting was good. But the answer was not completely correct'.";
        
    if (responseLanguage !== undefined && responseLanguage !== "English") {        
        thirdPromptAttempt += "Finally, translate your comment into " + responseLanguage;
    }
    // "the second number is the grade for variable names, the third number is the grade for the correct answer," + 
    // "and the fourth is the comment. If indentation is not necessary, assign the full 2 points for each category. " +
    // "If there are no variables in the source code, assign the full 2 points for that category.";

    let prompt = thirdPromptAttempt;
    return prompt;
}

export default { returnPrompt, parseAIResponse };