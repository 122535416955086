//TODO: Remove categorystatus

//React
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';

//UI
import { Button, Tabs, TabsRef } from 'flowbite-react';
import { Row } from 'components/ui_components/helper/HelperComponents';

//Services

//Logics
import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';

//Components
import ComponentRenderer from 'views/render/ComponentRendererStatic';
import Dropzone from 'react-dropzone';
import CategorySelector from './CategorySelector';
import MCQConstructor2 from './mcq/MCQConstructor';
import CodeExerciseConstructor2 from "components/synapp/code_exercise/CodeExerciseConstructor2";

//Store

//Classes
import { COMPONENT_TYPE } from "classes/enums/component-types";
import { Component } from 'classes/gridslate/components/Component';
import { StyleTheme } from 'classes/gridslate/style/StyleTheme';
import { GridConstructorDynamicInterface } from 'classes/interfaces/GridConstructorDynamicInterface';
import { CategoryStatus } from 'classes/synapp/CategoryStatus';
import { McqStoppingPoint } from 'classes/synapp/page_challenge/McqStoppingPoint';
import { IsBusy } from 'classes/general/IsBusy';
import { CodeExerciseStoppingPoint } from 'classes/synapp/code_exercise/CodeExerciseStoppingPoint';
import { PageChallenge } from 'classes/synapp/page_challenge/PageChallenge';
import { StoppingPoint } from 'classes/synapp/page_challenge/StoppingPoint';
import { CLASS } from 'classes/enums/classes';

//shift + Mousewheel increase fontsize and image size

type Props = {
    parentGridArray: string[][],
    parentComponents: Component[],
    theme: StyleTheme,
    categoryStatus: CategoryStatus
    parentCategoryObject: any
    //loadedMcqStoppingPoints: McqStoppingPoint[]
    pageChallenge: PageChallenge
}

interface ChildComponentProps {
    parentGridArray: string[][],
    parentComponents: Component[],
    theme: StyleTheme,
    categoryStatus: CategoryStatus
    parentCategoryObject: any
    //loadedMcqStoppingPoints: McqStoppingPoint[]
    pageChallenge: PageChallenge
}

const GridConstructorDynamic = forwardRef<GridConstructorDynamicInterface, ChildComponentProps>((props: Props, ref) => {

    const { parentGridArray, parentComponents, theme, categoryStatus, parentCategoryObject, pageChallenge } = props;

    // const parentGridArray = props.parentGridArray;
    // const parentComponents = props.parentComponents;
    // const theme = props.theme;
    // const categoryStatus = props.categoryStatus;
    // const loadedMcqStoppingPoints = props.loadedMcqStoppingPoints;

    const [components, setComponents] = useState<Component[]>(parentComponents);
    const [gridArray, setGridArray] = useState<string[][]>(parentGridArray);
    const [currentTheme, setCurrentTheme] = useState<StyleTheme>(theme);

    const [activeTab, setActiveTab] = useState(0);
    const [activeToolPaneTab, setActiveToolPaneTab] = useState(0);
    const [fileToUpload, setFileToUpload] = useState<any>(null);

    //const [currentThemeIndex, setCurrentThemeIndex] = useState<number>(0);

    const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
    const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);

    //const [allThemes, setAllThemes] = useAtom(themesAtom);

    const [tempCategoryStatus, setTempCategoryStatus] = useState<CategoryStatus>(categoryStatus);

    //const [selectedComponent, setSelectedComponent] = useState<SelectedComponentData>(new SelectedComponentData("", null, 0, 0));
    const [selectedComponentRefs, setSelectedComponentRefs] = useState<string[]>([]);

    const [stoppingPoints, setStoppingPoints] = useState<StoppingPoint[]>(pageChallenge.stoppingPoints);
    const [mcqStoppingPoints, setMcqStoppingPoints] = useState<McqStoppingPoint[]>(pageChallenge.mcqStoppingPoints);
    const [codeExerciseStoppingPoints, setCodeExerciseStoppingPoints] = useState<CodeExerciseStoppingPoint[]>(pageChallenge.codeExerciseStoppingPoints);

    const [pageChallengeState, setPageChallengeState] = useState<PageChallenge>(pageChallenge);

    const [selectedMcqStoppingPointIndex, setSelectedMcqStoppingPointIndex] = useState(-1);
    const [selectedCodeExerciseStoppingPointIndex, setSelectedCodeExerciseStoppingPointIndex] = useState(-1);

    const mainTabsRef = useRef<TabsRef>(null);

    const [selectedContent, setSelectedContent] = useState("");
    const [selectingContentPending, setSelectingContentPending] = useState(false);

    useImperativeHandle(ref, () => ({
        getComponents: () => { return components },
        getGridArray: () => gridArray,
        getSelectedContent: (seperator: string) => { return getSelectedContent(seperator) },
        //getMcqStoppingPoints: () => { return mcqStoppingPoints },
        //getCodeExerciseStoppingPoints: () => { return codeExerciseStoppingPoints }
        getPageChallenge: () => {
            let tempPageChallenge = pageChallengeState;
            tempPageChallenge.mcqStoppingPoints = mcqStoppingPoints;
            tempPageChallenge.codeExerciseStoppingPoints = codeExerciseStoppingPoints;
            tempPageChallenge.stoppingPoints = stoppingPoints;
            return tempPageChallenge;
        }
    }));

    useEffect(() => {
        setComponents(parentComponents);
        setGridArray(parentGridArray);
        setPageChallengeState(pageChallenge);
        //setMcqStoppingPoints(loadedMcqStoppingPoints);
        //setMcqStoppingPoints(pageChallenge.mcqStoppingPoints);
        //setCodeExerciseStoppingPoints(pageChallenge.codeExerciseStoppingPoints);
    }, [parentComponents, parentGridArray, pageChallenge]);

    useEffect(() => {
        setCurrentTheme(theme);
    }, [theme]);

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            //event.preventDefault();
            const code = event.which || event.keyCode;

            let charCode = String.fromCharCode(code).toLowerCase();
            if ((event.ctrlKey || event.metaKey) && charCode === 's') {
                event.preventDefault();
                console.log('save');
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
                console.log('ctrl+c');
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
                //console.log(selectedComponent);
                //let component = components.find((component) => component.componentRef === selectedComponent.componentRef);
                //console.log('ctrl+v ' + component?.type);
                // if (component != undefined &&
                //     component.type === COMPONENT_TYPE.image) {
                // }
            }

            if (event.shiftKey) {
                setShiftKeyPressed(true);
            } else {
                setShiftKeyPressed(false);
            }

            if (event.ctrlKey) {
                setCtrlKeyPressed(true);
            } else {
                setCtrlKeyPressed(false);
            }

        };

        const handleKeyUp = (event: any) => {
            if (event.ctrlKey) {
                setCtrlKeyPressed(true);
            } else {
                setCtrlKeyPressed(false);
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }
        // }, [selectedComponent]);
    }, []);

    //COMPONENT HANDLERS ========================================

    // const parseMcqStoppingPoints = (mcqStoppingPoints: McqStoppingPoint[]) => {
    //     for (let i = 0; i < mcqStoppingPoints.length; i++) {
    //         //mcqStoppingPoints[i].categoryId = categoryStatusLogic.findParentCategoryId(mcqStoppingPoints[i].categoryStatus);

    //     }
    //     return mcqStoppingPoints;
    // }

    //TOOLPANE RENDERERS ========================================

    const GridElementToolpane = () => {
        return (
            <div style={{ position: 'absolute' }}>
                {/* <Button size='xs' className='m-1' onClick={() => handleInsertColumn()}>Add Col</Button> */}
                {/* <Button size='xs' className='m-1' onClick={() => handleInsertStoppingPoint()}>Insert Row</Button> */}
                {/* <Button size='xs' className='m-1' onClick={() => handleCloneComponent()}>Clone</Button> */}
                {/* <Button size='xs' className='m-1' onClick={() => handleDeleteStoppingPoint()}>Del</Button> */}
            </div>
        )
    }


    const handleSelectStoppingPoint = (stoppingPointIndex: number) => {
        //setSelectedComponentRefs([]);
        setSelectedMcqStoppingPointIndex(stoppingPointIndex);
    }

    const handleSelectComponent = (componentRef: string) => {
        //TODO: fix ordering of elements
        //TODO: implement shift at a later date
        if (ctrlKeyPressed) {
            let tempSelectedComponentRefs = [...selectedComponentRefs];

            if (selectedComponentRefs.includes(componentRef)) {
                tempSelectedComponentRefs = tempSelectedComponentRefs.filter((ref) => ref !== componentRef);
            } else {
                tempSelectedComponentRefs.push(componentRef);
            }
            setSelectedComponentRefs(tempSelectedComponentRefs);
        } else {
            setSelectedComponentRefs([componentRef]);
        }
        setSelectedContent(getSelectedContent("\n"));

        //setSelectedMcqStoppingPointIndex(-1);
    }

    const getSelectedContent = (seperator: string, theseSelectedComponentsRefs?: string[]) => {
        let content = '';
        let tempSelectedComponentRefs = theseSelectedComponentsRefs ? theseSelectedComponentsRefs : selectedComponentRefs;
        tempSelectedComponentRefs.forEach((componentRef) => {
            let component = components.find((component) => component.componentRef === componentRef);
            if (component != undefined) {
                content += component.text + seperator;
            }
        })
        return content;
    }

    //TEST HANDLERS ========================================


    //RENDER ========================================

    const parseCategoriesFromParentObject = (sourceObject: any) => {
        if (parentCategoryObject.level0CategoryId) {
            sourceObject.level0CategoryId = parentCategoryObject.level0CategoryId;
        }
        if (parentCategoryObject.level1CategoryId) {
            sourceObject.level1CategoryId = parentCategoryObject.level1CategoryId;
        }
        if (parentCategoryObject.level2CategoryId) {
            sourceObject.level2CategoryId = parentCategoryObject.level2CategoryId;
        }
        return sourceObject;
    }

    const parseSelectedComponentRefs = (componentRef: string) => {
        //selectedComponent.componentRef === componentRef ? 'border-4 border-red-400' : ''
        let className = '';
        if (selectedComponentRefs.includes(componentRef)) {
            className = 'border-4 border-green-400';
        }

        return className;
    }

    const handleAddMcqStoppingPoint = () => {
        if (selectedComponentRefs.length === 0) {
            //toast.error('Please select a component to add a stopping point to');
            return;
        }

        let tempMcqStoppingPoints = [...mcqStoppingPoints];
        let newMcqStoppingPoint = new McqStoppingPoint();
        //newStoppingPoint.categoryStatus = categoryStatusLogic.returnClonedCategoryStatus(categoryStatus);
        // newMcqStoppingPoint.level0CategoryId = categoryStatus.level0CategoryId;
        // newMcqStoppingPoint.level1CategoryId = categoryStatus.level1CategoryId;
        // newMcqStoppingPoint.level2CategoryId = categoryStatus.level2CategoryId;
        newMcqStoppingPoint = parseCategoriesFromParentObject(newMcqStoppingPoint);
        newMcqStoppingPoint.componentRefLocation = selectedComponentRefs[0];
        tempMcqStoppingPoints.push(newMcqStoppingPoint);
        setMcqStoppingPoints(tempMcqStoppingPoints);

        //Add new stopping point
        let newStoppingPoint = new StoppingPoint();
        newStoppingPoint.componentRefLocation = selectedComponentRefs[0];
        newStoppingPoint.type = CLASS.mcq;
        newStoppingPoint.isRequired = true;
        newStoppingPoint.stoppingPointRef = newMcqStoppingPoint.guidRef;
        setStoppingPoints([...stoppingPoints, newStoppingPoint]);
    }

    const handleDeleteMcqStoppingPoint = () => {
        if (selectedMcqStoppingPointIndex === -1) {
            //toast.error('Please select a stopping point to delete');
            return;
        }
        //Delete stopping point
        let tempStoppingPoints = [...stoppingPoints];
        let index = tempStoppingPoints.findIndex((stoppingPoint) => stoppingPoint.stoppingPointRef === mcqStoppingPoints[selectedMcqStoppingPointIndex].guidRef);
        tempStoppingPoints.splice(index, 1);
        setStoppingPoints(tempStoppingPoints);

        //Delete mcq stopping point
        let tempMcqStoppingPoints = [...mcqStoppingPoints];
        tempMcqStoppingPoints.splice(selectedMcqStoppingPointIndex, 1);
        setMcqStoppingPoints(tempMcqStoppingPoints);
        setSelectedMcqStoppingPointIndex(-1);
    }

    const handleAddCodeExerciseStoppingPoint = () => {
        if (selectedComponentRefs.length === 0) {
            //toast.error('Please select a component to add a stopping point to');
            return;
        }
        let tempCodeExerciseStoppingPoints = [...codeExerciseStoppingPoints];
        let newCodeExerciseStoppingPoint = new CodeExerciseStoppingPoint();
        newCodeExerciseStoppingPoint.challengeGuidRef = pageChallenge.guidRef;
        // newCodeExerciseStoppingPoint.level0CategoryId = categoryStatus.level0CategoryId;
        // newCodeExerciseStoppingPoint.level1CategoryId = categoryStatus.level1CategoryId;
        // newCodeExerciseStoppingPoint.level2CategoryId = categoryStatus.level2CategoryId;
        newCodeExerciseStoppingPoint = parseCategoriesFromParentObject(newCodeExerciseStoppingPoint);
        newCodeExerciseStoppingPoint.componentRefLocation = selectedComponentRefs[0];
        tempCodeExerciseStoppingPoints.push(newCodeExerciseStoppingPoint);
        setCodeExerciseStoppingPoints(tempCodeExerciseStoppingPoints);

        //Add new stopping point
        let newStoppingPoint = new StoppingPoint();
        newStoppingPoint.componentRefLocation = selectedComponentRefs[0];
        newStoppingPoint.type = CLASS.codeExercise;
        //newStoppingPoint.isRequired = true;
        newStoppingPoint.stoppingPointRef = newCodeExerciseStoppingPoint.guidRef;
        setStoppingPoints([...stoppingPoints, newStoppingPoint]);
    }

    const handleDeleteCodeExerciseStoppingPoint = () => {
        if (selectedCodeExerciseStoppingPointIndex === -1) {
            //toast.error('Please select a stopping point to delete');
            return;
        }
        //Delete stopping point
        let tempStoppingPoints = [...stoppingPoints];
        let index = tempStoppingPoints.findIndex((stoppingPoint) => stoppingPoint.stoppingPointRef === codeExerciseStoppingPoints[selectedCodeExerciseStoppingPointIndex].guidRef);
        tempStoppingPoints.splice(index, 1);
        setStoppingPoints(tempStoppingPoints);

        //Delete code exercise stopping point
        let tempCodeExerciseStoppingPoints = [...codeExerciseStoppingPoints];
        tempCodeExerciseStoppingPoints.splice(selectedCodeExerciseStoppingPointIndex, 1);
        setCodeExerciseStoppingPoints(tempCodeExerciseStoppingPoints);
        setSelectedCodeExerciseStoppingPointIndex(-1);
    }

    const handleAutoSelectContent = () => {
        //iterate through all components and select all that are between the currently selected stopping point
        //and the component with type heading that is immediately before it
        let tempSelectedComponentRefs = [] as string[];
        let stoppingPointComponentRef = mcqStoppingPoints[selectedMcqStoppingPointIndex].componentRefLocation;
        //console.log(stoppingPointComponentRef);  

        for (let row in gridArray) {
            for (let col in gridArray[row]) {
                let component = components.find((component) => component.componentRef === gridArray[row][col]);
                if (component?.componentRef === stoppingPointComponentRef) {
                    //console.log("RETURN!");
                    setSelectedComponentRefs(tempSelectedComponentRefs);
                    setSelectedContent(getSelectedContent("\n", tempSelectedComponentRefs));
                    return true;
                } else if (component !== undefined && component.type === COMPONENT_TYPE.heading) {
                    tempSelectedComponentRefs = [component.componentRef];
                } else if (component) {
                    tempSelectedComponentRefs.push(component?.componentRef as string);
                }
                //console.log(component?.componentRef + " " + stoppingPointComponentRef);
            }
        }
        setSelectedComponentRefs(tempSelectedComponentRefs);
        setSelectedContent(getSelectedContent("\n", tempSelectedComponentRefs));
        return true;
    }

    const renderMcqStoppingPoint = (stoppingPointIndexFound: number) => {
        return (
            <Row>
                <div className='flex-1' onClick={() => handleSelectStoppingPoint(stoppingPointIndexFound)}>
                    <div className={stoppingPointIndexFound === selectedMcqStoppingPointIndex ? 'border-4 border-green-400' : 'border-2 border-red-400'}>
                        <div className='grid grid-cols-1 place-items-center'>
                            <p>Mcq Stopping Point</p>
                            {mcqStoppingPoints[stoppingPointIndexFound] &&
                                <div>
                                    <CategorySelector
                                        categories={categoryStatus.categories}
                                        categoryObject={mcqStoppingPoints[stoppingPointIndexFound]}
                                        setCategoryObject={
                                            (categoryObject: McqStoppingPoint) => {
                                                let tempMcqStoppingPoints = [...mcqStoppingPoints];
                                                tempMcqStoppingPoints[stoppingPointIndexFound] = categoryObject;
                                                setMcqStoppingPoints(tempMcqStoppingPoints);
                                            }
                                        }
                                        isBusy={new IsBusy()}
                                    />
                                    <div>
                                        Number of Mcqs per Stopping Point Challenge:
                                        <select
                                            className="mr-4 mt-4"
                                            value={mcqStoppingPoints[stoppingPointIndexFound].numberOfQuestionsPerChallenge}
                                            onChange={(e) => {
                                                let tempMcqStoppingPoints = [...mcqStoppingPoints];
                                                tempMcqStoppingPoints[stoppingPointIndexFound].numberOfQuestionsPerChallenge = parseInt(e.target.value);
                                                setMcqStoppingPoints(tempMcqStoppingPoints);
                                            }}
                                        >
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                        </select>
                                        Pass Grade:
                                        <select
                                            value={mcqStoppingPoints[stoppingPointIndexFound].passGrade}
                                            onChange={(e) => {
                                                let tempMcqStoppingPoints = [...mcqStoppingPoints];
                                                tempMcqStoppingPoints[stoppingPointIndexFound].passGrade = parseInt(e.target.value);
                                                setMcqStoppingPoints(tempMcqStoppingPoints);
                                            }}
                                        >
                                            <option value={0}>0</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                            <option value={60}>60</option>
                                            <option value={70}>70</option>
                                            <option value={80}>80</option>
                                            <option value={90}>90</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                    <MCQConstructor2
                                        content={selectedContent}
                                        level0CategoryId={mcqStoppingPoints[stoppingPointIndexFound].level0CategoryId}
                                        level1CategoryId={mcqStoppingPoints[stoppingPointIndexFound].level1CategoryId}
                                        level2CategoryId={mcqStoppingPoints[stoppingPointIndexFound].level2CategoryId}
                                        selectingContentPending={selectingContentPending}
                                        setSelectingContentPending={setSelectingContentPending} />
                                </div>}
                        </div>
                    </div>
                </div>
            </Row>
        )
    }

    const renderCodeExerciseStoppingPoint = (stoppingPointIndexFound: number) => {
        return (
            <Row>
                <div className='flex-1' onClick={() => handleSelectStoppingPoint(stoppingPointIndexFound)}>
                    <div className={stoppingPointIndexFound === selectedCodeExerciseStoppingPointIndex ? 'border-4 border-green-400' : 'border-2 border-red-400'}>
                        <div className='grid grid-cols-1 place-items-center'>
                            <p>Code Exercise Stopping Point</p>
                            {codeExerciseStoppingPoints[stoppingPointIndexFound] &&
                                <div>
                                    <CategorySelector
                                        categories={categoryStatus.categories}
                                        categoryObject={codeExerciseStoppingPoints[stoppingPointIndexFound]}
                                        setCategoryObject={
                                            (categoryObject: CodeExerciseStoppingPoint) => {
                                                let tempCodeExerciseStoppingPoints = [...codeExerciseStoppingPoints];
                                                tempCodeExerciseStoppingPoints[stoppingPointIndexFound] = categoryObject;
                                                setCodeExerciseStoppingPoints(tempCodeExerciseStoppingPoints);
                                                console.log(tempCodeExerciseStoppingPoints);
                                            }
                                        }
                                        isBusy={new IsBusy()}
                                    />
                                    <div>
                                        Minimum point threshold:
                                        <select className="mr-4 mt-4"
                                            value={codeExerciseStoppingPoints[stoppingPointIndexFound].minimumPointThreshold}
                                            onChange={(e) => {
                                                let tempCodeExerciseStoppingPoints = [...codeExerciseStoppingPoints];
                                                tempCodeExerciseStoppingPoints[stoppingPointIndexFound].minimumPointThreshold = parseInt(e.target.value);
                                                setCodeExerciseStoppingPoints(tempCodeExerciseStoppingPoints);
                                            }}
                                        >
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                            <option value={20}>20</option>
                                            <option value={25}>25</option>
                                            <option value={30}>30</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                            <option value={60}>60</option>
                                            <option value={70}>70</option>
                                            <option value={80}>80</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                    <CodeExerciseConstructor2
                                        content={selectedContent}
                                        level0CategoryId={codeExerciseStoppingPoints[stoppingPointIndexFound].level0CategoryId}
                                        level1CategoryId={codeExerciseStoppingPoints[stoppingPointIndexFound].level1CategoryId}
                                        level2CategoryId={codeExerciseStoppingPoints[stoppingPointIndexFound].level2CategoryId}
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
            </Row>
        )
    }

    const renderStoppingPoint = (componentRef: string, rowIndex: number, colIndex: number) => {
        let mcqStoppingPointIndexFound = -1;
        let codeExerciseStoppingPointIndexFound = -1;
        for (let i = 0; i < mcqStoppingPoints.length; i++) {
            if (mcqStoppingPoints[i].componentRefLocation === componentRef) {
                mcqStoppingPointIndexFound = i;
            }
        }

        for (let i = 0; i < codeExerciseStoppingPoints.length; i++) {
            if (codeExerciseStoppingPoints[i].componentRefLocation === componentRef) {
                codeExerciseStoppingPointIndexFound = i;
            }
        }

        if (mcqStoppingPointIndexFound !== -1) {
            return renderMcqStoppingPoint(mcqStoppingPointIndexFound);
        }

        if (codeExerciseStoppingPointIndexFound !== -1) {
            return renderCodeExerciseStoppingPoint(codeExerciseStoppingPointIndexFound);
        }

    }

    return (
        <div className='container mx-auto'>
            <Tabs aria-label="Default tabs" ref={mainTabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
                <Tabs.Item active title="Constructor">
                </Tabs.Item>

                <Tabs.Item title="Import">
                    <Dropzone onDrop={(acceptedFiles) => setFileToUpload(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} className="flex w-full items-center justify-center h-64 cursor-pointer">
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>

                    {fileToUpload && <p>{fileToUpload[0].name}</p>}

                </Tabs.Item>
                <Tabs.Item title="View Mode">
                </Tabs.Item>
            </Tabs>

            {/* ===================================================================== Constructor ============================== */}
            {activeTab == 0 && <div>

                <div className='sticky top-0 z-50 bg-white rounded border-neutral-600 border-2'>
                    <Tabs aria-label="Default tabs" onActiveTabChange={(tab) => setActiveToolPaneTab(tab)}>
                        <Tabs.Item active title="Mcq Stopping Points">
                            {/* <Button onClick={() => handleDoubleClickCreateComponent(COMPONENT_TYPE.text)}>Add MCQ Stopping Point</Button> */}
                            <Button disabled={selectedComponentRefs.length === 0} onClick={() => handleAddMcqStoppingPoint()}> Add Mcq Stopping Point</Button>
                            <Button disabled={selectedMcqStoppingPointIndex === -1} onClick={() => handleDeleteMcqStoppingPoint()}> Remove Mcq Stopping Point</Button>
                            <Button
                                disabled={selectedMcqStoppingPointIndex === -1}
                                onClick={() => handleAutoSelectContent()}
                            >Auto select content</Button>
                        </Tabs.Item>
                        <Tabs.Item active title="Code Stopping Points">

                            <Button disabled={selectedComponentRefs.length === 0} onClick={() => handleAddCodeExerciseStoppingPoint()}> Add Code Exercise Stopping Point</Button>
                            <Button disabled={selectedCodeExerciseStoppingPointIndex === -1} onClick={() => handleDeleteCodeExerciseStoppingPoint()}> Remove Code Exercise Stopping Point</Button>
                            <Button
                                disabled={selectedCodeExerciseStoppingPointIndex === -1}
                                onClick={() => handleAutoSelectContent()}
                            >Auto select content</Button>
                        </Tabs.Item>
                        <Tabs.Item title="Settings">
                            {/* {selectedComponent.componentRef != "" && <ComponentSettingsPanel
                                selectedComponent={selectedComponent}
                                setComponentSettings={setComponentSettings}
                            />} */}
                        </Tabs.Item>
                        <Tabs.Item title="Select Content">
                            <div>
                                {/* <Button
                                    disabled={selectedMcqStoppingPointIndex === -1}
                                    onClick={() => setSelectedContent(getSelectedContent("\n"))}
                                >Send Content to Mcq Generator</Button> */}

                            </div>
                        </Tabs.Item>

                    </Tabs>

                </div>

                <Row>
                    <div className='container mx-auto'>

                        {gridArray && gridArray.map((row, rowIndex) => {
                            return (
                                <div key={"row" + rowIndex} className='grid grid-cols-12'>
                                    <div className='col-span-11'>
                                        <div className='flex gap-2'>
                                            {row.map((componentRef, colIndex) => {
                                                return (
                                                    <div key={"columns-" + colIndex} className='flex-1'>
                                                        {renderStoppingPoint(componentRef, rowIndex, colIndex)}
                                                        <div className='flex-1'>
                                                            <div
                                                                //onClick={() => setSelectedComponent(new SelectedComponentData(componentRef, components.find((component) => component.componentRef === componentRef), rowIndex, colIndex))}
                                                                //className={selectedComponent.componentRef === componentRef ? 'border-4 border-red-400' : ''}
                                                                className={parseSelectedComponentRefs(componentRef)}
                                                                onClick={() => handleSelectComponent(componentRef)}
                                                            >
                                                                <ComponentRenderer
                                                                    component={components.find((component) => component.componentRef === componentRef) as Component}
                                                                    rowIndex={rowIndex}
                                                                    colIndex={colIndex}
                                                                    setComponentRichText={() => { }}
                                                                    setComponentText={() => { }}
                                                                    handleDrop={() => { }}
                                                                    setImageAttribute={() => { }}
                                                                    styleTheme={theme}
                                                                    editingEnabled={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>)
                                            })}
                                        </div>
                                    </div>
                                    {/* <div className=''>
                                        {selectedComponent.rowIndex === rowIndex && <GridElementToolpane />}
                                    </div> */}
                                </div>
                            )
                        })}
                    </div>
                </Row>
            </div>
            }
        </div>
    )
})

export default GridConstructorDynamic;
