//React
import React from 'react';

//UI
import { Table } from 'flowbite-react';

//Services
import { useWindowDimensions } from "logic/utility/hooks/useWindowDimensions";

//Logics
import styleLogic from 'logic/gridslate/style/styleLogic';
import parseCodeEditor from 'logic/parse/parseCodeEditor';

//Components
import ImageComponent from 'components/ui_components/ImageComponent';
import Editor from 'react-simple-code-editor';

//Store

//Classes
import { COMPONENT_TYPE } from 'classes/enums/component-types';
import { Component } from 'classes/gridslate/components/Component';
import { StyleTheme } from 'classes/gridslate/style/StyleTheme';
import { COMPONENT_SUBTYPE } from 'classes/enums/component-subtypes';

//import { ReactFitty } from "react-fitty";

type Props = {
    component: Component;
    rowIndex: number;
    colIndex: number;
    handleDrop: Function;
    setImageAttribute: Function;
    setComponentRichText: Function;
    setComponentText: Function;
    styleTheme: StyleTheme;
    editingEnabled: boolean;
    data?: any;
}

const ComponentRendererStatic = (props: Props) => {

    const { component, rowIndex, colIndex, setComponentText, setComponentRichText, handleDrop, setImageAttribute, styleTheme, editingEnabled, data } = props;
    const { viewBreakpoint } = useWindowDimensions();
    const clean = (data: string) => {
        data = data.replaceAll('&lt;', '<');
        data = data.replaceAll('&gt;', '>');
        return data;
    }

    const onDrop = (e: any) => {
        e.preventDefault();
        handleDrop(e.dataTransfer.getData('type'), rowIndex, colIndex);
    }

    const parseStyle = () => {
        let data;

        if (component.data.masterStyleSetting === 'theme') {
            data = styleLogic.getComponentStyleFromTheme(styleTheme, component.type, component.subtype);
            data = styleLogic.parseStyle(data, viewBreakpoint, styleTheme.data.themeColors);
        } else {
            data = styleLogic.parseStyle(component.data.style, viewBreakpoint, styleTheme.data.themeColors);
        }
        //TODO: Add padding to all elements, add default 2px to list
        if (component.type === COMPONENT_TYPE.list) {
            data.padding = "0px 0px 0px 2px";
        }
        return data;
    }

    const renderToListItems = (text: string) => {
        let returnedText = "";
        let lines = text.split('\n');
        for (let i = 0; i < lines.length; i++) {
            returnedText += `<li>${lines[i]}</li>`;
        }

        //remove all empty list items
        returnedText = returnedText.replaceAll('<li></li>', '');

        return returnedText;
    }

    const renderTable = () => {
        let table = component;
        let data = table.data.specialisedData as string[][];

        for (let i = 0; i < data.length; i++) {
            let row = data[i];
            let rowString = '<tr>';
            for (let j = 0; j < row.length; j++) {
                rowString += `<td>${row[j]}</td>`;
            }
            rowString += '</tr>';
        }

        return (
            <Table>
                {data.map((row, index) => {
                    return (
                        <React.Fragment key={"row_" + component.componentRef + index}>
                            {index === 0 &&
                                <Table.Head>
                                    {row.map((cell, cellIndex) => {
                                        return (
                                            <Table.HeadCell key={"head_" + component.componentRef + cellIndex}>{cell}</Table.HeadCell>
                                        )
                                    })}
                                </Table.Head>}
                        </React.Fragment>
                    )
                })}
                <Table.Body>
                    {data.map((row, index) => {
                        return (
                            <React.Fragment key={"body_" + component.componentRef + index}>
                                {index != 0 && <Table.Row key={"row_" + component.componentRef + index}>
                                    {row.map((cell, cellIndex) => {
                                        return (
                                            <Table.Cell key={"cell_" + component.componentRef + cellIndex}>{cell}</Table.Cell>
                                        )
                                    })}
                                </Table.Row>}
                            </React.Fragment>
                        )
                    })}
                </Table.Body>
            </Table >
        )
    }

    return (
        <div>
            {/* =================================================== TITLE ==================================*/}
            {component.type === COMPONENT_TYPE.title && editingEnabled &&
                <div
                    id={component.componentRef}
                    style={parseStyle()}
                    suppressContentEditableWarning
                    contentEditable={true}
                    onBlur={() => setComponentRichText(component.type, component.componentRef, rowIndex, colIndex)}
                >
                    {component.text}
                </div>
            }
            {component.type === COMPONENT_TYPE.title && !editingEnabled &&
                <div
                    id={component.componentRef}
                    style={parseStyle()}
                >
                    {component.text}
                </div>
            }
            {/* =================================================== HEADING ==================================*/}
            {component.type === COMPONENT_TYPE.heading && editingEnabled &&
                <div
                    id={component.componentRef}
                    style={parseStyle()}
                    suppressContentEditableWarning
                    contentEditable={true}
                    onBlur={() => setComponentRichText(component.type, component.componentRef, rowIndex, colIndex)}
                >
                    {component.text}
                </div>
            }
            {component.type === COMPONENT_TYPE.heading && !editingEnabled &&
                <div
                    id={component.componentRef}
                    style={parseStyle()}
                >
                    {component.text}
                </div>
            }
            {/* =================================================== TEXT ==================================*/}
            {component.type === COMPONENT_TYPE.text && editingEnabled &&
                <div
                    id={component.componentRef}
                    style={parseStyle()}
                    suppressContentEditableWarning
                    contentEditable={true}
                    onBlur={() => setComponentRichText(component.type, component.componentRef, rowIndex, colIndex)}
                    dangerouslySetInnerHTML={{ __html: clean(component.text) }}
                />
            }
            {component.type === COMPONENT_TYPE.text && !editingEnabled &&
                <div
                    id={component.componentRef}
                    style={parseStyle()}
                    dangerouslySetInnerHTML={{ __html: clean(component.text) }}
                />
            }
            {/* =================================================== IMAGE ==================================*/}
            {component.type === COMPONENT_TYPE.image && editingEnabled &&
                <ImageComponent
                    style={parseStyle()}
                    setImageAttribute={setImageAttribute}
                    image={component}
                />
            }
            {component.type === COMPONENT_TYPE.image && component.subtype == COMPONENT_SUBTYPE.complete && !editingEnabled &&
                <ImageComponent
                    style={parseStyle()}
                    setImageAttribute={() => { }}
                    image={component}
                />
            }
            {/* =================================================== LIST ==================================*/}
            {component.type === COMPONENT_TYPE.list && component.subtype === COMPONENT_SUBTYPE.unordered && editingEnabled &&
                <ul className="list-disc ml-4 list-outside"
                    id={component.componentRef}
                    suppressContentEditableWarning
                    contentEditable={true}
                    style={parseStyle()}
                    onBlur={() => setComponentRichText(component.type, component.componentRef, rowIndex, colIndex)}
                    dangerouslySetInnerHTML={{ __html: renderToListItems(component.text) }}
                >
                </ul>
            }
            {component.type === COMPONENT_TYPE.list && component.subtype === COMPONENT_SUBTYPE.unordered && !editingEnabled &&
                <ul className="list-disc ml-4 list-outside"
                    id={component.componentRef}
                    style={parseStyle()}
                    dangerouslySetInnerHTML={{ __html: renderToListItems(component.text) }}
                >
                </ul>
            }
            {component.type === COMPONENT_TYPE.list && component.subtype === COMPONENT_SUBTYPE.ordered && editingEnabled &&
                <ol className="list-decimal ml-4 list-outside">
                    <div
                        id={component.componentRef}
                        suppressContentEditableWarning
                        contentEditable={true}
                        style={parseStyle()}
                        onBlur={() => setComponentRichText(component.type, component.componentRef, rowIndex, colIndex)}
                        dangerouslySetInnerHTML={{ __html: renderToListItems(component.text) }}
                    />
                </ol>
            }
            {component.type === COMPONENT_TYPE.list && component.subtype === COMPONENT_SUBTYPE.ordered && !editingEnabled &&
                <ol className="list-decimal ml-4 list-outside">
                    <div
                        id={component.componentRef}
                        style={parseStyle()}
                        dangerouslySetInnerHTML={{ __html: renderToListItems(component.text) }}
                    />
                </ol>
            }
            {/* =================================================== EMPTY ==================================*/}
            {component.type === COMPONENT_TYPE.empty && editingEnabled &&
                <div
                    onDrop={(e) => onDrop(e)}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <div
                        id={component.componentRef}
                    >
                        Empty
                    </div>
                </div>
            }
            {/* =================================================== TABLE ==================================*/}
            {component.type === COMPONENT_TYPE.table &&
                renderTable()
            }
            {/* =================================================== CODEBLOCK ==================================*/}
            {component.type === COMPONENT_TYPE.codeblock &&
                <Editor
                    value={component.text}
                    padding={10}
                    onValueChange={(text: string) => setComponentText(component.componentRef, text)}
                    className="code-editor"
                    highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode)}
                    textareaId="codeArea"
                    style={parseStyle()}
                />
            }
            {/* =================================================== PAGE CHALLENGE =============================*/}
            {component.type === COMPONENT_TYPE.pageChallenge && editingEnabled &&
                <div
                    id={component.componentRef}
                    style={parseStyle()}
                >
                    <select
                        className="w-full border-2 border-slate-50 p-2"
                        value={component.text}
                        onChange={(e) => { setComponentText(component.componentRef, e.target.value); }}
                    >
                        {data && data.map((pageChallengeItem: any, index: number) => {
                            return (
                                <option key={'page-challenge-select-' + index} value={pageChallengeItem.id}>{pageChallengeItem.name}</option>
                            )
                        })}
                    </select>
                </div>
            }
            {component.type === COMPONENT_TYPE.pageChallenge && !editingEnabled &&
                <div
                    id={component.componentRef}
                    style={parseStyle()}
                >
                    Page Challenge:
                    <a href={"/pagechallenge/" + component.text}>Test page challenge</a>
                </div>
            }
        </div>
    )
}

export default ComponentRendererStatic;