// src/Tiptap.tsx
import { useEditor, EditorContent, FloatingMenu, BubbleMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

// define your extension array
const extensions = [StarterKit]

// content: JSON.parse(window.localStorage.getItem('editor-content') || '{}'),
//     onUpdate: ({ editor }) => {
//       const jsonContent = JSON.stringify(editor.getJSON());
//      window.localStorage.setItem('editor-content', jsonContent)
//     }

const content = '<p>Hello World!</p>'

const Tiptap = () => {

  const editor = useEditor({
    extensions: [StarterKit],
    content: content,
    // extensions,
    // content,
  })

  if(!editor) {
    return null;
  }

  return (
    <>
      <div className="my-16"></div>
      <EditorContent editor={editor} />
      <FloatingMenu editor={editor}>This is the floating menu</FloatingMenu>
      <BubbleMenu editor={editor}>
        <div className="bubble-menu">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          Bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          Italic
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          Strike
        </button>
      </div></BubbleMenu>
    </>
  )
}

export default Tiptap