
class McqSubmittedAnswer {
    id: string = "";
    userId: string = "";
    parentId: string = "";
    parentType: string = "";
    mcqId: string = "";
    submittedAnswerGuidRef: string = "";   
    incorrectSubmittedAnswerGuidRefs: string[] = [];
    isCorrect: boolean = false;
    isGraded: boolean = false;
    pointsAwarded: number = 0;
    isReset: boolean = false; 
}

export { McqSubmittedAnswer};