class WordFlashSet {
    id: string = "";
    title: string;
    courseId: string;
    description: string = "";
    creatorId: string = "";
    imageSrc: string = "";
    wordSets: WordSet[];
    isPublic: boolean = false;

    constructor(courseId: string, title: string, wordSets: WordSet[]) {
        this.courseId = courseId;
        this.title = title;
        this.wordSets = wordSets;
    }
}

class WordSet {
    words: SingleWord[] = [];
    setName: string = "";

    constructor(words: SingleWord[], setName: string) {
        this.words = words;
        this.setName = setName;
    }
}

class SingleWord {
    word: string;
    translationPairings: TranslationKeyValue[] = [];
    imageSrc: string = "";

    constructor(word: string, translationPairings: TranslationKeyValue[]) {
        this.word = word;
        this.translationPairings = translationPairings;
    }

}

class TranslationKeyValue {
    languageCode: string;
    Value: string;

    constructor(languageCode: string, Value: string) {
        this.languageCode = languageCode;
        this.Value = Value;
    }
}

export { WordFlashSet, WordSet, SingleWord, TranslationKeyValue};