import apiService from "services/apiService";
import config from "config";
import {FilterModel} from "classes/models/request/FilterModel";

class CRUDRequest {
    Target: string;
    Action: string;
    Value: any;
    FilterModel: FilterModel | null;

    constructor(Target: string, Action: string, Value: any, FilterModel: FilterModel | null = null) {
        this.Target = Target;
        this.Action = Action;
        this.Value = Value;
        this.FilterModel = FilterModel;
    }
}

//public List<KeyValuePair<string, string>> PropertyFilters { get; set; }

const create = async (target: string, model: any) => {
    let request = new CRUDRequest(target, "create", model);
    const response = await apiService.post(config.apiUrl + '/cms/CRUD' + target, request);
    return response;
}

const update = async (target: string, model: any) => {
    let request = new CRUDRequest(target, "update", model);
    const response = await apiService.post(config.apiUrl + '/cms/CRUD' + target, request);
    return response;
}

const del = async (target: string, filterModel: FilterModel) => {
    let request = new CRUDRequest(target, "delete", null, filterModel);
    const response = await apiService.post(config.apiUrl + '/cms/CRUD' + target, request);
    return response;
}

const get = async (target: string, filterModel: FilterModel) => {
    let request = new CRUDRequest(target, "get", null, filterModel);
    const response = await apiService.post(config.apiUrl + '/cms/CRUD' + target, request);
    return response;
}

const load = async (target: string, filterModel: FilterModel) => {
    let request = new CRUDRequest(target, "load", null, filterModel);
    const response = await apiService.post(config.apiUrl + '/cms/CRUD' + target, request);
    return response;
}


export default { create, update, del, get, load};