//React
import { useState, useEffect } from "react";

//UI

//Services
import { useWindowDimensions } from "logic/utility/hooks/useWindowDimensions";
import apiService from "services/apiService";
import { toast } from "react-toastify";
import config from "config";
import errorService from "services/errorService";

//Logics
import S3RouteLogic from "logic/gridslate/S3RouteLogic";
import styleLogic from "logic/gridslate/style/styleLogic";
import parseLogic from "logic/parse/parseLogic";

//Components
import ComponentRendererStatic from "views/render/ComponentRendererStatic";
import { Container } from 'components/ui_components/helper/HelperComponents';
//import Zoom from 'react-medium-image-zoom';
//import 'react-medium-image-zoom/dist/styles.css'

//Store
import { themesAtom } from 'atom';
import { useAtom } from 'jotai';

//Classes
import { Page } from "classes/gridslate/page/Page";
import { Component } from "classes/gridslate/components/Component";
import {StyleTheme} from "classes/gridslate/style/StyleTheme";

//Bounce, Fade, Flip, Hinge, JackInTheBox, Roll, Rotate, Slide and Zoom

//import { Fade, Flip, Hinge, Bounce, JackInTheBox, Roll, Rotate, Slide } from "react-awesome-reveal";
//import { Fade } from "react-awesome-reveal";

//Views/Components
//import Editor from 'react-simple-code-editor';
//import CodeTryItViewer from "components/page/CodeTryItViewer";
//import ProgressViewer from "components/page/ProgressViewer";

type Props = {
    pageRef: string;
    pageId: string;
}

const ViewPage = (props: Props) => {
    const { pageRef, pageId } = props;
    const [page, setPage] = useState(new Page('none', ''));
    const [components, setComponents] = useState<Component[]>([]);
    const [errorLine, setErrorLine] = useState(null);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [isLoadingTheme, setIsLoadingTheme] = useState(true);
    const [isError, setIsError] = useState(null);
    const [themeIndex, setThemeIndex] = useState<number>(-1);
    const [allThemes, setAllThemes] = useAtom(themesAtom);

    const { viewWidth, viewBreakpoint } = useWindowDimensions();


    useEffect(() => {

        const loadPage = async (pageId: string) => {
            let response = await apiService.get(config.apiUrl + '/cms/LoadPage/' + pageId);
            if (response.success) {
                response.payload.components = parseLogic.unstringifyDataPropertyOnList(response.payload.components);
                setPage(response.payload.page);
                setComponents(response.payload.components);
                checkForTheme(response.payload.page.styleThemeId);
                setIsLoadingPage(false);
            } else {
                setIsLoadingPage(false);
                errorService.handleError(response);
            }
        }

        const fetchTheme = async (styleThemeId: string) => {
            let response = await apiService.getS3(S3RouteLogic.GetRouteByClass("styletheme", styleThemeId));
            if (response.success) {
                setIsLoadingTheme(false);
                response.payload = parseLogic.unstringifyDataProperty(response.payload);
                setAllThemes([...allThemes, response.payload]);
                setThemeIndex(allThemes.length);
            } else {
                toast.warn('Theme not found. Using default theme.');
                setDefaultTheme();
            }
        }

        const checkForTheme = (styleThemeId: string) => {
             //Check if theme is in allThemes
             let themeId = styleThemeId;
             if (themeId === 'default') {
                 setDefaultTheme();
                 return;
             } else {
                 //Need to fetch theme if not in allThemes
                 let themeIndex = allThemes.findIndex((theme) => theme.id === themeId);
                 if (themeIndex === -1) {
                     fetchTheme(styleThemeId);
                 } else {
                     setThemeIndex(themeIndex);
                 }
             }
        }

        const fetchPage = async () => {
            let response = await apiService.getS3(S3RouteLogic.GetRouteByClass("page", pageRef));
            if (response.success) {
                if (response.payload.redirect) {
                    loadPage(response.payload.id);
                    return;
                }
                setIsLoadingPage(false);
                setPage(response.payload.page);
                response.payload.components = parseLogic.unstringifyDataPropertyOnList(response.payload.components);
                setComponents(response.payload.components);
                checkForTheme(response.payload.page.styleThemeId);
            } else {
                setIsLoadingPage(false);
                //TODO: error handle here
                console.log(response);
            }
        }

        fetchPage();

    }, [pageRef, pageId]);

    const setDefaultTheme = () => {
        //Assuming that themes are empty here
        if (allThemes.find((theme) => theme.id === 'default') === undefined) {
            setAllThemes([...allThemes, styleLogic.returnDefaultStyleTheme('Default', 'page', '')]);
        }
        setThemeIndex(0);
        setIsLoadingTheme(false);
    }

    const returnResponsiveColumn = (numberOfColumns: number) => {
        if (viewWidth > 1024) {
            return numberOfColumns;
        } else {
            return 1;
        }
    }

    return (
        <Container>
            {!isLoadingPage && !isLoadingTheme && page.gridArray.map((row, rowIndex) => {
                return (
                    <div key={"row" + rowIndex}>
                        {row.map((col, colIndex) => {
                            return (
                                <div className={'flex-1'} key={"columns-" + colIndex}>
                                    <ComponentRendererStatic
                                        component={components.find((component) => component.componentRef === col) as Component}
                                        rowIndex={rowIndex}
                                        colIndex={colIndex}
                                        setComponentRichText={() => { }}
                                        setComponentText={() => { }}
                                        handleDrop={() => { }}
                                        setImageAttribute={() => { }}
                                        styleTheme={allThemes[themeIndex] as StyleTheme}
                                        editingEnabled={false}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )
            })}

            {isLoadingPage &&
                <div className="text-center">
                    Loading page...
                </div>}
            {isError &&
                <div className="text-center">
                    An error has occured! Please refresh the page.
                    {JSON.stringify(isError)}
                </div>}
        </Container>

    );
}

export default ViewPage;