//React

//UI

//Services

//Logics

//Components

//Store

//Classes
import { InternalErrorCodes } from "classes/enums/internal-error-codes";

import { ResponseObject } from "classes/models/response/ResponseObject";
class requestHeaders {
    authorization: string;
    refresh: string;

    constructor() {
        this.authorization = localStorage.getItem("accessToken") || "";
        this.refresh = localStorage.getItem("refreshToken") || "";
    }
}

//Try new Get
const get = async (url: string): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();
    let response;

    try {
        response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            }
        });
    }
    catch (e) {
        console.log("Get error ", e);
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client GET Error";
        return responseObj;
    }

    let dataResponse = null;

    //See solution in POST below

    if (response.ok) {
        dataResponse = await response.json();
        responseObj.statusCode = response.status;
        responseObj.internalErrorCode = dataResponse.internalErrorCode;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    } else {
        responseObj.success = false;
        responseObj.statusCode = response.status;
        responseObj.message = JSON.stringify(response);
        responseObj.errorSource = "Server problem";
    }

    //console.log("getting here: ", responseObj);
    return responseObj;
}

const getS3 = async (url: string): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();
    let response;
    try {
        response = await fetch(url, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        })
    }
    catch (e) {
        //console.log(e);
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client GET Error";
        return responseObj;
    }

    let dataResponse = null;

    //See solution in POST below

    if (response.ok) {
        dataResponse = await response.json();
        responseObj.statusCode = response.status;
        //responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse;
        responseObj.success = true;
    } else {
        responseObj.success = false;
        responseObj.statusCode = response.status;
        responseObj.message = JSON.stringify(response);
        responseObj.errorSource = "Server problem";
    }
    return responseObj;
}

// const post = async (url: string, data: object): Promise<ResponseObject> => {
//     const headers = new requestHeaders();
//     let responseObj = new ResponseObject();
//     //let response;

//     return fetch(url, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': headers.authorization,
//             'Refresh': headers.refresh
//         },
//         body: JSON.stringify(data)
//     }).then(processedResponse => {

//         if (!processedResponse.ok) {
//             console.log("processedResponse not okay: ", processedResponse);
//             responseObj.success = false;
//             responseObj.statusCode = processedResponse.status;
//             responseObj.success = false;
//             return responseObj;
//         }

//         //const string = await response.text();
//         //let string = processedResponse.text();
//         processedResponse.text().then(string => {
//             if (string === "") {
//                 responseObj.statusCode = processedResponse.status;
//                 responseObj.success = false;
//                 responseObj.message = processedResponse.status === 403 ? "You don't have permission to do that!" : "Empty response from server";
//                 responseObj.errorSource = "Server problem";
//                 return responseObj;
//             }
//             const dataResponse = JSON.parse(string);
//             responseObj.statusCode = processedResponse.status;
//             responseObj.message = dataResponse.message;
//             responseObj.payload = dataResponse.payload;
//             responseObj.success = dataResponse.success;
//             return responseObj;
//         }).catch(e => {
//             console.log("Post error ", e);
//             responseObj.success = false;
//             responseObj.message = JSON.stringify(e);
//             responseObj.errorSource = "Client POST Error";
//             return responseObj;
//         });


//     });
   
//     if (!response) {
//         return responseObj;
//     }

//     return response;
// }

const post = async (url: string, data: object): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let response: any = null;
    let responseObj = new ResponseObject();

    try {
        response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            },
            body: JSON.stringify(data)
        })
        //.then(processedResponse => {
        //     if (!processedResponse.ok) {
        //         responseObj.success = false;
        //         responseObj.statusCode = processedResponse.status;
        //         responseObj.success = false;
        //         return responseObj;
        //     }

        //     //const string = await response.text();
        //     //let string = processedResponse.text();
        //     let string = processedResponse.text();

        //     if (string === "") {
        //         responseObj.statusCode = response.status;
        //         responseObj.success = false;
        //         responseObj.message = response.status === 403 ? "You don't have permission to do that!" : "Empty response from server";
        //         responseObj.errorSource = "Server problem";
        //         return responseObj;
        //     }
        //     const dataResponse = JSON.parse(string);
        //     responseObj.statusCode = response.status;
        //     responseObj.message = dataResponse.message;
        //     responseObj.payload = dataResponse.payload;
        //     responseObj.success = dataResponse.success;

        // });

        // if (!response) {
        //     return responseObj;
        // }

        const string = await response.text();
        //let string = response.text();

        if (string === "") {
            responseObj.statusCode = response.status;
            responseObj.success = false;
            responseObj.message = response.status === 403 ? "You don't have permission to do that!" : "Empty response from server";
            responseObj.errorSource = "Server problem";
            return responseObj;
        }
        const dataResponse = JSON.parse(string);
        responseObj.statusCode = response.status;
        responseObj.internalErrorCode = dataResponse.internalErrorCode;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    }
    catch (e) {
        //console.log("Post error:", e);
        //responseObj.internalErrorCode = InternalErrorCodes.ServerOffline;
        responseObj.statusCode = 503; //Service Unavailable
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client POST Error";
    }

    return responseObj;
}

const upload = async (url: string, data: FormData): Promise<ResponseObject> => {
    const headers = new requestHeaders();

    let responseObj = new ResponseObject();
    //'Content-Type': 'multipart/form-data',
    try {
        let response = await fetch(url, {
            method: 'POST',
            headers: {

                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            },
            body: data
        });


        //const dataResponse = await response.json();
        const string = await response.text();
        //const dataResponse = string === "" ? {} : JSON.parse(string);
        if (string === "") {
            responseObj.statusCode = response.status;
            responseObj.success = false;
            responseObj.message = response.status === 403 ? "You don't have permission to do that!" : "Empty response from server";
            responseObj.errorSource = "Server problem";
            return responseObj;
        }
        const dataResponse = JSON.parse(string);
        responseObj.statusCode = response.status;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    }
    catch (e) {
        console.log(e);
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client Upload Error";
    }

    return responseObj;
}

//S3 - will not return a GenericResponse, so no double payload
const put = async (url: string, data: any): Promise<ResponseObject> => {

    let responseObj = new ResponseObject();

    //const headers = new requestHeaders();
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'image/jpeg/png',
        },
        body: data
    });

    let dataResponse;

    try {
        dataResponse = await response.json();
    }
    catch (e) {
        console.log(e);
        dataResponse = null;
    }

    //If response is not empty then set payload to response.json() else set it to null
    //const payload = response.status !== 204 ? response.json() : null;
    console.log(response);
    //let payload = null;

    // let responseObj: httpResponse = {
    //     payload: dataResponse ? dataResponse.payload: null,
    //     errorCaught: false,
    //     statusCode: response.status,
    //     message: ""
    // }
    responseObj.statusCode = response.status;
    responseObj.payload = dataResponse ? dataResponse.payload : null;

    return responseObj;
}

//Try new Get
const del = async (url: string): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();
    let response;
    try {
        response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            }
        });
    }
    catch (e) {
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client DELETE Error";
        return responseObj;
    }

    let dataResponse = null;

    if (response.ok) {
        dataResponse = await response.json();
        responseObj.statusCode = response.status;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    } else {
        responseObj.success = false;
        responseObj.statusCode = response.status;
        responseObj.message = JSON.stringify(response);
        responseObj.errorSource = "Server problem";
    }

    return responseObj;
}

const googleUserDetails = async (url: string, data: object): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();

    return responseObj;
}

export default {
    get,
    getS3,
    post,
    put,
    del,
    googleUserDetails,
    upload
}