import {Mcq} from "classes/synapp/mcq/Mcq";
import {McqAnswer} from "classes/synapp/mcq/McqAnswer";

const parseAIResponse = (inputString: string) => {

    // Split the input string into lines
    let lines = inputString.trim().split('\n');
    
    //trim the lines
    lines.forEach((line, index) => {
        lines[index] = line.trim();
    });

    return [];
}

const returnPrompt = (numberOfQuestionsToGenerate: string, difficultySetting: string, mcqAIContentForPrompt: string) => {

    let diffFormatInstructionPrompt =
        "Generate a set of multiple choice questions. Each question should have 4 answers." +
        'Generate the questions in the following format: Q1:question text\n A1:answer1\n A2:answer2\n A3:answer3\n A4:answer4\n' +
        "The correct answer should always be the first answer in the list of answers.";


    let basicInstructionPrompt =
        "Generate a set of multiple choice questions. Each question should have 4 answers." +
        'Generate the questions in the following format: [{"q": "question text", "a": ["answer1", "answer2", "answer3", "answer4"]}]' +
        "The correct answer should always be the first answer in the list of answers. The response will be parsed to JSON, so make sure the response is in the correct format.";

    let contentPrompt = "The topic of the questions is javascript coding and all the questions should be based on the following content: ";

    let numberOfQuestionsPrompt = "Finally, make a set of " + numberOfQuestionsToGenerate + " questions.";

    let difficultyPrompt = "The questions should be of difficulty level " + difficultySetting + " on a scale of 1 to 3, with 1 being the easiest and 3 the most difficult.";

    let prompt = diffFormatInstructionPrompt + contentPrompt + mcqAIContentForPrompt + numberOfQuestionsPrompt + difficultyPrompt;
    return prompt;
}

export default { returnPrompt, parseAIResponse };