
enum COMPONENT_SUBTYPE {
    none = "none",
    plain = "plain",
    rich = "rich",
    mcqImage = "mcqImage",
    mcqCodeblock = "mcqCodeblock",
    codeblock = "tryit",
    type1 = "type1",
    type2 = "type2",
    type3 = "type3",
    type4 = "type4",
    type5 = "type5",
    ordered = "ordered",
    unordered = "unordered",
    // mcqQuestion = "mcqQuestion",
    // mcqAnswer = "mcqAnswer",
    incomplete = "incomplete",
    complete = "complete",  
}

export { COMPONENT_SUBTYPE};