class ComponentData {
    masterStyleSetting: string = "theme";
    style = {};
    specialisedData: any = {};
    width: number = 0;
  
    constructor() {
    }
}

export {ComponentData};