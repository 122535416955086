// public enum InternalErrorCodes {
//     None = 0,
//     IncorrectUsernameOrPassword = 1,
//     UserNotEnrolledInCourse = 2,
// }
  

enum InternalErrorCodes {
    ServerOffline = -1,
    None = 0,
    IncorrectUsernameOrPassword = 1,
    UserNotEnrolledInCourse = 2,
}

export {InternalErrorCodes};