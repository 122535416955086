//React
import { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";

//UI
import { Container } from 'components/ui_components/helper/HelperComponents';

//Services
import crudService from 'services/crudService';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
import apiService from 'services/apiService';
import config from 'config';

//Logics
import styleLogic from "logic/gridslate/style/styleLogic";
import parseLogic from "logic/parse/parseLogic";
import categoryStatusLogic from "logic/gridslate/categoryStatusLogic";

//Components
import ComponentRendererStatic from "views/render/ComponentRendererStatic";
import McqViewer from "components/synapp/mcq/McqViewer";
import PageChallengeTimeline from "components/synapp/PageChallengeTimeline";
import CodeExerciseViewer from "components/synapp/code_exercise/CodeExerciseViewer";

//Store
import { useAtom } from 'jotai';
import { mcqsInMemory } from 'atom';
import { userAtom } from 'atom';

//Classes
import { FilterModel } from 'classes/models/request/FilterModel';
import { CLASS } from 'classes/enums/classes';
import { Page } from "classes/gridslate/page/Page";
import { Mcq } from "classes/synapp/mcq/Mcq";
import { Component } from "classes/gridslate/components/Component";
import { PageChallenge } from "classes/synapp/page_challenge/PageChallenge";
import { Course } from "classes/synapp/course/Course";
import { StyleTheme } from 'classes/gridslate/style/StyleTheme';
import { StoppingPointStatus } from 'classes/synapp/page_challenge/StoppingPointStatus';
import { IsBusy } from 'classes/general/IsBusy';
//import { LoadPageChallengeResponseModel } from 'classes/models/ResponseModels';
import { PageChallengeInstance } from 'classes/synapp/page_challenge/PageChallengeInstance';
import { InternalErrorCodes } from 'classes/enums/internal-error-codes';

const PageChallengeInstanceViewer = () => {

    const [mcqs, setMcqs] = useAtom(mcqsInMemory);

    const [page, setPage] = useState<Page | null>(null); // The page that is the basis for the challenge 
    const [pageChallenge, setPageChallenge] = useState<PageChallenge>(new PageChallenge()); // The text challenge that is being viewed
    const [course, setCourse] = useState<Course | null>(null); // The course that the challenge is part of
    const [components, setComponents] = useState<Component[] | null>(null); // The components of the page

    const [pageChallengeInstance, setPageChallengeInstance] = useState<PageChallengeInstance | null>(null);

    const [stoppingPointStatuses, setStoppingPointStatuses] = useState<StoppingPointStatus[]>([]);

    //const [parseVisibility, setParseVisibility] = useState<boolean>(false);

    const [currentStoppingPointComponentRef, setCurrentStoppingPointComponentRef] = useState<string>("first");

    //const [isBusy, setIsBusy] = useState(new IsBusy().loading([CLASS.pageChallenge, CLASS.stoppingPointStatus]));
    const [isBusy, setIsBusy] = useState(new IsBusy().loading([CLASS.pageChallengeInstance]));

    const [defaultTheme, setDefaultTheme] = useState<StyleTheme>(styleLogic.returnDefaultStyleTheme());

    const { pageChallengeInstanceId } = useParams();

    const [isEnrolled, setIsEnrolled] = useState<boolean>(true);

    const [user] = useAtom(userAtom);

    //============================================================================= - Loading

    //Parsing components for stopping point visibility flags
    // useEffect(() => {
    //     if (parseVisibility) {
    //         let currentStoppingPointComponentRef = findNextStoppingPointFromStatuses(stoppingPointStatuses);
    //         //console.log("Current stopping point, from status load: ", currentStoppingPointComponentRef);
    //         let parsedComponents = parseComponentsForStoppingPointVisibilityFlags(currentStoppingPointComponentRef, pageChallenge, page.gridArray, components);
    //         setComponents(parsedComponents);
    //         setParseVisibility(false);
    //     }

    // }, [parseVisibility]);

    //Load the page challenge instance
    //TODO: Deal with users who are not enrolled
    useEffect(() => {

        const loadPageChallengeInstance = async () => {
            let response = await apiService.get(config.apiUrl + "/cms/LoadPageChallengeInstance/" + pageChallengeInstanceId);
            if (response.success) {

                let loadedPageChallengeInstance = response.payload.pageChallengeInstance as PageChallengeInstance;
                let loadedPageChallenge = response.payload.pageChallenge as PageChallenge;
                let loadedPage = response.payload.page as Page;
                let loadedComponents = response.payload.components as Component[];
                let loadedStoppingPointStatuses = response.payload.stoppingPointStatuses as StoppingPointStatus[];

                setPageChallengeInstance(loadedPageChallengeInstance);
                setPageChallenge(loadedPageChallenge);

                setPage(loadedPage);
                loadMcqs(loadedPageChallenge);
                let parsedComponents = parseLogic.parseComponentsFromLoad(loadedComponents);
                let currentStoppingPointComponentRef = findNextStoppingPointFromStatuses(loadedPageChallenge, loadedStoppingPointStatuses);
                parsedComponents = parseComponentsForStoppingPointVisibilityFlags(currentStoppingPointComponentRef, loadedPageChallenge, loadedPage.gridArray, parsedComponents);
                setComponents(parsedComponents);
                
                //console.log("Current stopping point, from status load: ", currentStoppingPointComponentRef);
                //let parsedComponents = parseComponentsForStoppingPointVisibilityFlags(currentStoppingPointComponentRef, pageChallenge, page.gridArray, components);

                setStoppingPointStatuses(loadedStoppingPointStatuses);
                setIsBusy(isBusy.loaded(CLASS.pageChallengeInstance));
            }
            else {
                errorService.handleError(response);
                console.log(response);
                //If the user is not enrolled, redirect to the course page
                if (response.internalErrorCode === InternalErrorCodes.UserNotEnrolledInCourse) {
                    toast.error("You are not enrolled in this course. Please enroll to view this page.");
                    setIsEnrolled(false);
                }

                setIsBusy(isBusy.loaded(CLASS.pageChallengeInstance));
            }
        }

        if (pageChallengeInstanceId) {
            loadPageChallengeInstance();
        }

    }, [pageChallengeInstanceId]);

    //Load all mcqs for the challenge
    //TODO: Consider retrieving mcqs as the individual challenge is started
    const loadMcqs = async (pageChallenge: PageChallenge) => {
        let highestLevel = categoryStatusLogic.findHighestCategoryId(pageChallenge);
        let filterModel = new FilterModel();
        if (highestLevel === 0) {
            filterModel.PropertyFilters.push(["Level0CategoryId", pageChallenge.level0CategoryId]);
        } else if (highestLevel === 1) {
            filterModel.PropertyFilters.push(["Level1CategoryId", pageChallenge.level1CategoryId]);
        } else if (highestLevel === 2) {
            filterModel.PropertyFilters.push(["Level2CategoryId", pageChallenge.level2CategoryId]);
        }

        let response = await crudService.get(CLASS.mcq, filterModel);
        if (response.success) {
            setMcqs(response.payload as Mcq[]);
        }
        else {
            errorService.handleError(response);
        }
    }

    //============================================================================= - Stopping point logic

    //Using statuses, determine the next stopping point to render
    // const findNextStoppingPointFromStatuses = (stoppingPointStatuses: StoppingPointStatus[]) => {

    //     for (let x = 0; x < pageChallenge.mcqStoppingPoints.length; x++) {
    //         let stoppingPoint = pageChallenge.mcqStoppingPoints[x];
    //         let stoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPoint.componentRefLocation);

    //         //Assuming that if stopping point status is undefined, then it has not been completed
    //         if (stoppingPointStatus === undefined) {
    //             return stoppingPoint.componentRefLocation;
    //         }
    //     }

    //     return "first";
    //     //If all stopping points have been completed, then return "complete"
    //     // if (stoppingPointStatuses.length === pageChallenge.mcqStoppingPoints.length) {
    //     //     return "complete";
    //     // } else {
    //     //     //If no stopping points have been completed, then return "first"
    //     //     return "first";
    //     // }
    // }

    const findNextStoppingPointFromStatuses = (pageChallenge: PageChallenge, stoppingPointStatuses: StoppingPointStatus[]) => {

        for (let x = 0; x < pageChallenge.stoppingPoints.length; x++) {
            let stoppingPoint = pageChallenge.stoppingPoints[x];
            let stoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPoint.componentRefLocation);

            //Assuming that if stopping point status is undefined, then it has not been completed
            if (stoppingPointStatus === undefined) {
                return stoppingPoint.componentRefLocation;
            }
        }

        return "first";
        //If all stopping points have been completed, then return "complete"
        // if (stoppingPointStatuses.length === pageChallenge.mcqStoppingPoints.length) {
        //     return "complete";
        // } else {
        //     //If no stopping points have been completed, then return "first"
        //     return "first";
        // }
    }

    //Sets render flags up until a given componentRef, and returns the components for setting
    const parseComponentsForStoppingPointVisibilityFlags = (stoppingPointComponentRef: string, pageChallenge: PageChallenge, gridArray: string[][] = [], components: Component[] = []) => {
        let stoppingPointReached = false;

        //If first then set to first stopping point
        // if (stoppingPointComponentRef === "first" && pageChallenge.mcqStoppingPoints.length > 0) {
        //     stoppingPointComponentRef = pageChallenge.mcqStoppingPoints[0].componentRefLocation;
        // }
        //TODO: first componentRef is not necessarily the first stopping point, if deleted and added etc
        // if (stoppingPointComponentRef === "first" && pageChallenge.stoppingPoints.length > 0) {
        //     stoppingPointComponentRef = pageChallenge.stoppingPoints[0].componentRefLocation;
        // }

        //Iterate through gridArray
        gridArray.forEach((row, rowIndex) => {
            row.forEach((componentRef, colIndex) => {
                let component = components.find((component) => component.componentRef === componentRef);
                if (component !== undefined) {
                    component.render = "yes";
                    if (!stoppingPointReached && component.componentRef === stoppingPointComponentRef) {
                        stoppingPointReached = true;
                        component.render = "stoppingpoint";
                    } else if (stoppingPointReached) {
                        component.render = "no";
                    }
                }
            });
        });
        setCurrentStoppingPointComponentRef(stoppingPointComponentRef);
        return components;
    }

    const checkIfStoppingPoint = (componentRef: string) => {
        for (let i = 0; i < pageChallenge.stoppingPoints.length; i++) {
            if (pageChallenge.stoppingPoints[i].componentRefLocation === componentRef) {
                //check if parent component is being rendered
                let parentComponent = components?.find((component) => component.componentRef === componentRef);
                if (parentComponent !== undefined) {
                    if (parentComponent.render === "no") {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    //============================================================================= - CRUD stopping point status

    const checkForChallengeCompletion = () => {
        let complete = true;
        for (let i = 0; i < pageChallenge.mcqStoppingPoints.length; i++) {
            let stoppingPoint = pageChallenge.mcqStoppingPoints[i];
            let stoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPoint.componentRefLocation);
            if (stoppingPointStatus === undefined || !stoppingPointStatus.isCompleted) {
                complete = false;
            }
        }
        if (complete) {
            submitPageChallengeStatus();
        }
    }

    const submitPageChallengeStatus = async () => {

        //TODO: update page challenge status

        // let pageChallengeStatus = new PageChallengeStatus();
        // pageChallengeStatus.pageChallengeId = pageChallenge.id;
        // pageChallengeStatus.dateCompleted = Date.now().toString();
        // pageChallengeStatus.isCompleted = true;

        // let response = await crudService.create(CLASS.pageChallengeStatus, pageChallengeStatus);
        // if (response.success) {
        //     toast.success("Challenge successfully completed!");
        //     setPageChallengeStatus(pageChallengeStatus);
        // }
        // else {
        //     errorService.handleError(response);
        // }
    }



    // const checkIfCodeExerciseStoppingPoint = (componentRef: string) => {
    //     for (let i = 0; i < pageChallenge.codeExerciseStoppingPoints.length; i++) {
    //         if (pageChallenge.codeExerciseStoppingPoints[i].componentRefLocation === componentRef) {
    //             //check if parent component is being rendered
    //             let parentComponent = components?.find((component) => component.componentRef === componentRef);
    //             if (parentComponent !== undefined) {
    //                 if (parentComponent.render === "no") {
    //                     return false;
    //                 }
    //             }
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    const submitStoppingPointStatus = async (type: string) => {
        let stoppingPointStatus = new StoppingPointStatus();

        //TODO: this needs to reflect actual stopping point guid, not componentRef
        stoppingPointStatus.stoppingPointGuidRef = currentStoppingPointComponentRef;

        stoppingPointStatus.pageChallengeId = pageChallenge.id;
        stoppingPointStatus.dateCompleted = Date.now().toString();
        //stoppingPointStatus.type = "Mcq";
        stoppingPointStatus.type = type
        stoppingPointStatus.isCompleted = true;

        let response = await crudService.create(CLASS.stoppingPointStatus, stoppingPointStatus);
        if (response.success) {
            //Not updating currently, only adding new ones that don't exist yet
            toast.success("Stopping point submitted successfully");
            let tempStoppingPointStatuses = [...stoppingPointStatuses];
            tempStoppingPointStatuses.push(response.payload as StoppingPointStatus);
            setStoppingPointStatuses(tempStoppingPointStatuses);

            //Reparse components for stopping point visibility flags
            let currentStoppingPointComponentRef = findNextStoppingPointFromStatuses(pageChallenge, tempStoppingPointStatuses);
            //TODO: find a better way to do this
            let gridArray = page? page.gridArray : [];
            let theseComponents = components ? components : [];
            let parsedComponents = parseComponentsForStoppingPointVisibilityFlags(currentStoppingPointComponentRef, pageChallenge, gridArray, theseComponents);
            setComponents(parsedComponents);

            checkForChallengeCompletion();
        }
        else {
            errorService.handleError(response);
        }
    }

    //============================================================================= - Render stopping points

    //TODO: change componentRefLocation to stoppingPointGuidRef
    //TODO: create guidRef on MCQStoppingPoint or remove if unnecessary

    //Redirects to the function that renders the appropriate stopping point
    const renderStoppingPoint = (componentRef: string) => {

        let stoppingPoint = pageChallenge.stoppingPoints.find(x => x.componentRefLocation === componentRef);

        if (stoppingPoint === undefined) {
            console.error("Expected stopping point not found for componentRef: " + componentRef);
            return <></>;
        }

        if (stoppingPoint.type === CLASS.mcq) {
            return renderMcqStoppingPoint(componentRef);
        }
        if (stoppingPoint.type === CLASS.codeExercise) {
            return renderCodeExerciseStoppingPoint(componentRef);
        }
    }

    const renderMcqStoppingPoint = (componentRef: string) => {
        let thisStoppingPoint = pageChallenge.mcqStoppingPoints.find(x => x.componentRefLocation === componentRef);
        if (thisStoppingPoint === undefined) {
            console.error("Expected stopping point not found for componentRef: " + componentRef);
            return <></>;
        }
        let thisStoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === thisStoppingPoint?.componentRefLocation);
        if (thisStoppingPointStatus !== undefined && thisStoppingPointStatus.isCompleted) {
            return (
                //TODO: add reopen button
                <div className="w-full h-16 bg-green-200 border-4 border-dashed m-4 text-lg text-center">
                    Stopping Point Complete!
                </div>
            )
        } else {
            return (
                <McqViewer
                    level0CategoryId={thisStoppingPoint.level0CategoryId}
                    level1CategoryId={thisStoppingPoint.level1CategoryId}
                    level2CategoryId={thisStoppingPoint.level2CategoryId}
                    questionsSubmittable={true}
                    stoppingPointStatus={thisStoppingPointStatus}
                    submitStoppingPointStatus={submitStoppingPointStatus}
                    mcqStoppingPoint={thisStoppingPoint}
                    parentId={pageChallenge.id}
                />
            )
        }
    }

    const renderCodeExerciseStoppingPoint = (componentRef: string) => {
        let thisStoppingPoint = pageChallenge.codeExerciseStoppingPoints.find(x => x.componentRefLocation === componentRef);
        if (thisStoppingPoint === undefined) {
            console.error("Expected stopping point not found for componentRef: " + componentRef);
            return <></>;
        }
        //let thisStoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === thisStoppingPoint?.componentRefLocation);
        //TODO: fix this on renderMcqStoppingPoint
        let thisStoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === thisStoppingPoint?.guidRef);
        if (thisStoppingPointStatus !== undefined && thisStoppingPointStatus.isCompleted) {
            return (
                //TODO: add reopen button
                <div className="w-full h-16 bg-green-200 border-4 border-dashed m-4 text-lg text-center">
                    Stopping Point Complete!
                </div>
            )
        } else {
            return (
                <CodeExerciseViewer
                    questionsSubmittable={true}
                    stoppingPointStatus={thisStoppingPointStatus}
                    codeExerciseStoppingPoint={thisStoppingPoint}
                    submitStoppingPointStatus={submitStoppingPointStatus}
                    challengeGuidRef={pageChallenge.guidRef}
                />
            )
        }

    }

    //============================================================================= - Render

    return (
        <Container>
            <div className="mt-6">
                {pageChallenge && <PageChallengeTimeline pageChallenge={pageChallenge} stoppingPointStatuses={stoppingPointStatuses} />}
            </div>
            {/* {JSON.stringify(mcqs)} */}
            {/* {<div>Stopping Point Ref: {currentStoppingPointComponentRef}</div>} */}
            {!isEnrolled && <div className="text-center">You are not enrolled in this course. Please enroll to view this page.</div>}
            {!isBusy.isLoading && components && page && page.gridArray.map((row, rowIndex) => {
                return (
                    <div key={"row" + rowIndex}>
                        {row.map((col, colIndex) => {
                            return (
                                <div className={'flex-1'} key={"columns-" + colIndex}>
                                    {/* {JSON.stringify(components.find((component) => component.componentRef === col) as Component)} */}
                                    {components.find((component) => component.componentRef === col) &&
                                        components.find((component) => component.componentRef === col)?.render === "yes" &&
                                        <ComponentRendererStatic
                                            component={components.find((component) => component.componentRef === col) as Component}
                                            rowIndex={rowIndex}
                                            colIndex={colIndex}
                                            setComponentRichText={() => { }}
                                            setComponentText={() => { }}
                                            handleDrop={() => { }}
                                            setImageAttribute={() => { }}
                                            styleTheme={defaultTheme}
                                            editingEnabled={false}
                                        />}
                                    {checkIfStoppingPoint(col) && renderStoppingPoint(col)}
                                    {/* {checkIfMcqStoppingPoint(col) && renderMcqStoppingPoint(col)} */}
                                    {/* {checkIfCodeExerciseStoppingPoint(col) && renderCodeExerciseStoppingPoint(col)} */}
                                </div>
                            )
                        })}
                    </div>
                )
            })}

            {isBusy.isLoading &&
                <div className="text-center">
                    Loading page...
                </div>}
            {/* {isError &&
                <div className="text-center">
                    An error has occured! Please refresh the page.
                    {JSON.stringify(isError)}
                </div>} */}
            {/* <CodeExercise /> */}
            <div className="mt-96"></div>

        </Container>
    )
}

export default PageChallengeInstanceViewer;