import {StyleThemeData} from './StyleThemeData';
class StyleTheme {

    id: string = "";
    type: string;
    stringifiedData: string = "";
    name: string = "default";
    data: StyleThemeData = new StyleThemeData();
    websiteId: string;
    ownerId: string = "";
    creationDate: string = "";
    lastUpdateDate: string = "";

    constructor(type: string, websiteId: string) {
        this.type = type;
        this.websiteId = websiteId;
    }
}

export { StyleTheme};