import Tiptap from "views/test/Tiptap";
import {Container} from "components/ui_components/helper/HelperComponents";

const TestTipTap = () => {
  return (
    <Container>
      <Tiptap />
    </Container>
  )
}

export default TestTipTap