import { STATUS } from "classes/enums/status";
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";

class CourseEnrollment {
    userId: string = "";
    studentNumber: string = "";
    group: number = 0;
}

//TODO: Change to CourseInstance
class Course {
    id: string = "";
    name: string = "";
    description: string = "";
    ownerId: string = "";
    courseTemplateId: string = "";
    dateStarted: string = "";
    dateCompleted: string = "";
    courseEnrollments: CourseEnrollment[] = [];

    status: STATUS = STATUS.new;
}

const getCourseProperties = (thisObject: Course) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'name', propertyType: 'string', displayName: "Course name", value: thisObject? thisObject.name : "new"});
    editableProperties.push({ propertyName: 'description', propertyType: 'string', displayName: "Course name", value: thisObject? thisObject.name : "new"});
    return editableProperties;
}

export {Course, CourseEnrollment, getCourseProperties};