//import { Slide, Fade } from "react-awesome-reveal";
import { useState } from "react";
import { Container } from 'components/ui_components/helper/HelperComponents';
//import "animate.css/animate.min.css";

const Test = () => {
    //const { pageRef, pageId } = props;
    const [image, setImage] = useState<any>(null);

    const handleImage = (e: any) => {
        setImage(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <Container>

            <div>
                <h1>Test Camera</h1>
                <input type="file" name="image" accept="image/*" capture="environment" onChange={(e: any) => handleImage(e)} />
            </div>

            <div>
                {image && <img src={image} alt="image" />}
            </div>

        </Container>

    );
}

export default Test;