//React
import { useEffect, useState, useRef } from 'react';
import React from 'react';

//UI
import { Button, Tabs, Modal, Textarea, RangeSlider, Spinner, TabsRef } from 'flowbite-react';
import { Row, CheckboxAndLabel } from 'components/ui_components/helper/HelperComponents';

//Services
import apiService from 'services/apiService';
import crudService from 'services/crudService';
import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';

//Logics
//import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';
import parseLogic from 'logic/parse/parseLogic';
import genericSaveLoadLogic from 'logic/saveload/genericSaveLoadLogic';

//Components
import GenericSaveLoadPanel from "components/gridslate/saveload/GenericSaveLoadPanel";

//Classes
import { FilterModel } from "classes/models/request/FilterModel";
import { CLASS } from 'classes/enums/classes';
import { STATUS } from 'classes/enums/status';
import { Course, CourseEnrollment, getCourseProperties } from 'classes/synapp/course/Course';
import { StoppingPointStatus } from 'classes/synapp/page_challenge/StoppingPointStatus';
import { PageChallenge } from 'classes/synapp/page_challenge/PageChallenge';
import { PageChallengeInstance } from 'classes/synapp/page_challenge/PageChallengeInstance';
import { CRUD_ACTION } from 'classes/enums/crud-action';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';

import { IsBusy } from 'classes/general/IsBusy';
import { StoppingPoint } from 'classes/synapp/page_challenge/StoppingPoint';

type Props = {
}

const CourseInstanceConstructor = (props: Props) => {

    const [courses, setCourses] = useState<Course[]>([]);
    const [pageChallenges, setPageChallenges] = useState<PageChallenge[]>([]);
    const [pageChallengeInstances, setPageChallengeInstances] = useState<PageChallengeInstance[]>([]);
    const [stoppingPointStatuses, setStoppingPointStatuses] = useState<StoppingPointStatus[]>([]);

    const [selectedPageChallengeId, setSelectedPageChallengeId] = useState<string>("");
    const [selectedPageChallengeInstanceId, setSelectedPageChallengeInstanceId] = useState<string>("None");
    const [loadedCourseIndex, setLoadedCourseIndex] = useState<number>(-1);

    const [isBusy, setIsBusy] = useState(new IsBusy());

    const [userIds, setUserIds] = useState<string>("");

    //const mainTabsRef = useRef<TabsRef>(null);

    useEffect(() => {

        const loadCourses = () => {
            setIsBusy(isBusy.loading(["courses"]));
            let filterModel = new FilterModel();
            filterModel.OnlyOwner = true;
            crudService.get(CLASS.course, filterModel).then(response => {
                if (response.success) {
                    //set status to unchanged
                    let loadedCourses = response.payload as Course[];
                    loadedCourses.forEach(course => {
                        course.status = STATUS.unchanged;
                    });
                    setCourses(loadedCourses);
                } else {
                    errorService.handleError(response);
                }
                setIsBusy(isBusy.loaded("courses"));
            });
        }

        if (courses.length === 0) {
            loadCourses();
        }

    }, []);

    useEffect(() => {
        const loadPageChallenges = () => {
            setIsBusy(isBusy.loading(["pageChallenges"]));
            let filterModel = new FilterModel();
            filterModel.OnlyOwner = true;
            crudService.get(CLASS.pageChallenge, filterModel).then(response => {
                if (response.success) {
                    let loadedPageChallenges = response.payload as PageChallenge[];
                    setPageChallenges(loadedPageChallenges);
                    if (loadedPageChallenges.length > 0) {
                        setSelectedPageChallengeId(loadedPageChallenges[0].id);
                    }
                } else {
                    errorService.handleError(response);
                }
                setIsBusy(isBusy.loaded("pageChallenges"));
            });
        }

        if (pageChallenges.length === 0) {
            loadPageChallenges();
        }

    }, []);

    useEffect(() => {
        const loadPageChallengeInstances = () => {
            setIsBusy(isBusy.loading(["pageChallengeInstances"]));
            let filterModel = new FilterModel();
            filterModel.OnlyOwner = true;
            filterModel.PropertyFilters = [["CourseId", courses[loadedCourseIndex].id]];
            crudService.get(CLASS.pageChallengeInstance, filterModel).then(response => {
                if (response.success) {
                    //let loadedPageChallengeInstances = response.payload as PageChallengeInstance[];
                    setPageChallengeInstances(response.payload);
                } else {
                    errorService.handleError(response);
                }
                setIsBusy(isBusy.loaded("pageChallengeInstances"));
            });
        }

        if (loadedCourseIndex !== -1) {
            loadPageChallengeInstances();
        }

    }, [loadedCourseIndex]);

    useEffect(() => {

        if (selectedPageChallengeInstanceId !== "None") {

            let pageChallengeId = pageChallengeInstances.find(x => x.id === selectedPageChallengeInstanceId)?.pageChallengeId;
            if (!pageChallengeId) {
                console.log("No page challenge id found");
                return;
            }

            let filterModel = new FilterModel();
            filterModel.PropertyFilters = [["PageChallengeId", pageChallengeId]];
            crudService.get(CLASS.stoppingPointStatus, filterModel).then(response => {
                if (response.success) {
                    setStoppingPointStatuses(response.payload);
                } else {
                    errorService.handleError(response);
                }
            });
        }

    }, [selectedPageChallengeInstanceId]);

    //================================================================================================= - Edit Course

    const crudActions = (action: CRUD_ACTION, value: any, target?: string) => {
        if (action === CRUD_ACTION.appendNewObject) {
            //expect value as new code challenge object
            var newCourses = [...courses, value];
            setLoadedCourseIndex(newCourses.length - 1);
            setCourses(newCourses);
        }
        if (action === CRUD_ACTION.getUpdatedObject) {
            return courses[loadedCourseIndex];
        }
        else if (action === CRUD_ACTION.returnNewObject) {
            let newCourse = new Course();
            return newCourse;
        }
        else if (action === CRUD_ACTION.loadObject) {
            //value is actually id of object here
            console.log(value);
            let index = courses.findIndex((course) => course.id === value);
            setLoadedCourseIndex(index);
        }
        else if (action === CRUD_ACTION.updateObject) {
            //value as Course object
            let newCourse = value as Course;
            var tempCourses = [...courses];
            tempCourses[loadedCourseIndex] = newCourse;
            setCourses(tempCourses);
            toast.success("Course Updated");
        }
        else if (action === CRUD_ACTION.updateObjectProperties) {
            let editableProperties = value as EditablePropertyMetadata[];
            let newCourses = [...courses];
            let newCourse = { ...newCourses[loadedCourseIndex] };
            newCourse = parseLogic.parseObjectUpdate(newCourse, editableProperties);
            newCourse.status = STATUS.updated;
            newCourses[loadedCourseIndex] = newCourse;
            setCourses(newCourses);
        }

        else if (action === CRUD_ACTION.deleteObject) {
            //value is actually the object here
            //But SHOULD always be currently selected object, especially if disabling selection while pending
            var tempCourses = [...courses];
            //find index of object
            let index = tempCourses.findIndex((course) => course.id === value.id);
            tempCourses.splice(index, 1);
            setCourses(tempCourses);
            setLoadedCourseIndex(-1);
            toast.success("Course Deleted");
        }

    }

    //================================================================================================= - Save/Load Course

    const handleDeleteEnrolledStudent = (index: number) => {
        let updatedUsers = [...courses[loadedCourseIndex].courseEnrollments];
        updatedUsers.splice(index, 1);
        crudActions(CRUD_ACTION.updateObjectProperties, [new EditablePropertyMetadata("courseEnrollments", "object", "Enrolled Users", updatedUsers)]);
    }

    const handleEnrollUsers = async () => {
        let userIdArray = userIds.split("\n");
        //remove blank lines and trim
        userIdArray = userIdArray.filter(userId => userId.trim() !== "");

        let response = await apiService.post(config.apiUrl + "/Accounts/GetAccountsByStudentNumbers", userIdArray);
        if (response.success) {
            let errorFound = false;
            //Check for not found users
            for (let i = 0; i < response.payload.length; i++) {
                if (response.payload[i] === "NOT FOUND") {
                    toast.warning("User with student number " + response.payload[i].studentNumber + " not found. ");
                    errorFound = true;
                }
            }
            if (errorFound) {
                toast.error("No students enrolled");
                return;
            }
        } else {
            errorService.handleError(response);
            return;
        }

        let enrolledUsers = [] as CourseEnrollment[];

        userIdArray.forEach(studentNumber => {
            let enrollment = new CourseEnrollment();
            //enrollment.userId = userIds.find(x => x.studentNumber === studentNumber).id;
            enrollment.studentNumber = studentNumber;
            enrollment.group = 1;
            enrolledUsers.push(enrollment);
        });

        //Add userIds
        let userIdsFromServer = response.payload as string[];
        for (let i = 0; i < enrolledUsers.length; i++) {
            enrolledUsers[i].userId = userIdsFromServer[i];
        }

        //let newCourse = { ...courses[loadedCourseIndex] };
        //newCourse.courseEnrollments = [...newCourse.courseEnrollments, ...enrolledUsers];

        //crudActions(CRUD_ACTION.updateObject, newCourse);
        let updatedUsers = [...courses[loadedCourseIndex].courseEnrollments, ...enrolledUsers];

        crudActions(CRUD_ACTION.updateObjectProperties, [new EditablePropertyMetadata("courseEnrollments", "object", "Enrolled Users", updatedUsers)]);
    }

    const handleCreatePageChallengeInstance = () => {
        if (selectedPageChallengeId === "") {
            console.log("No page challenge selected");
            return;
        }
        let newPageChallengeInstance = new PageChallengeInstance(selectedPageChallengeId, courses[loadedCourseIndex].id, "");
        crudService.create(CLASS.pageChallengeInstance, newPageChallengeInstance).then(response => {
            if (response.success) {
                let updatedPageChallengeInstances = [...pageChallengeInstances, response.payload as PageChallengeInstance];
                setPageChallengeInstances(pageChallengeInstances.concat(updatedPageChallengeInstances));
            } else {
                errorService.handleError(response);
            }
        });
    }

    //================================================================================== - Render Components

    const returnStoppingPointStatusText = (stoppingPoint: StoppingPoint, userId: string) => {
        //console.log(stoppingPoint);
        //console.log(stoppingPointStatuses);
        let thisStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPoint.componentRefLocation && x.ownerId === userId);

        if (thisStatus) {
            return thisStatus.isCompleted ? "Completed" : "Started";
        } else {
            return "Not started";
        }
    }

    const ViewPageInstance = () => {

        if (selectedPageChallengeInstanceId === "None") {
            return <div></div>;
        }
        let pageChallengeInstance = pageChallengeInstances.find(x => x.id === selectedPageChallengeInstanceId);
        let pageChallenge = pageChallenges.find(x => x.id === pageChallengeInstance?.pageChallengeId);
        if (!pageChallenge) {
            return <div></div>;
        }

        let course = courses[loadedCourseIndex];

        return (
            <div>
                <div className={"grid grid-cols-" + (pageChallenge.stoppingPoints.length + 1)}>
                    <div>Student</div>
                    {pageChallenge.stoppingPoints.map((stoppingPoint, index) => {
                        return <div key={"header-" + index}>{stoppingPoint.name + ":" + stoppingPoint.type}</div>
                    })}

                    {/* //Student */}
                    {courses[loadedCourseIndex].courseEnrollments.map((enrollment, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div key={"row-student-" + index}>{enrollment.studentNumber}</div>
                                {/* <div>ST1</div>
                            <div>ST2</div> */}
                                {pageChallenge && pageChallenge.stoppingPoints.map((stoppingPoint, index) => {
                                    return <div key={"stoppingpoint-" + index}>
                                        {returnStoppingPointStatusText(stoppingPoint, enrollment.userId)}
                                    </div>
                                })}
                            </React.Fragment>

                        )
                    })}


                </div>
            </div>
        )
    }

    //================================================================================== - Render Course

    return (
        <div className='container mx-auto'>
            <GenericSaveLoadPanel
                objectType='course'
                loadedObjectIndex={loadedCourseIndex}
                objects={courses}
                crudActions={crudActions}
                saveLoadData={courses[loadedCourseIndex]}
                getEditableProperties={() => { return getCourseProperties(courses[loadedCourseIndex]) }}
                saveLoadLogic={genericSaveLoadLogic}
            />
            {loadedCourseIndex !== -1 && <Tabs>
                <Tabs.Item title="Course Users" active>
                    <div className="text-2xl">Course Name: {courses[loadedCourseIndex].name}</div>
                    <div className="text-xl">Description: {courses[loadedCourseIndex].description}</div>
                    <div className="mt-6">
                        <div className="grid grid-cols-4">
                            <div>Student Number</div>
                            <div>UserId</div>
                            <div>Group</div>
                            <div>Action</div>
                        </div>
                        {courses[loadedCourseIndex].courseEnrollments.map((enrollment, index) => {
                            return <div key={"course-enrollments-" + index} className="grid grid-cols-4">
                                <input value={enrollment.studentNumber} onChange={(e) => {
                                    let updatedUsers = [...courses[loadedCourseIndex].courseEnrollments];
                                    updatedUsers[index].studentNumber = e.target.value;
                                    crudActions(CRUD_ACTION.updateObjectProperties, [new EditablePropertyMetadata("courseEnrollments", "object", "Enrolled Users", updatedUsers)]);
                                }} />
                                <div>{enrollment.userId}</div>
                                <div>
                                    <select value={enrollment.group} onChange={(e) => {
                                        let updatedUsers = [...courses[loadedCourseIndex].courseEnrollments];
                                        updatedUsers[index].group = parseInt(e.target.value);
                                        crudActions(CRUD_ACTION.updateObjectProperties, [new EditablePropertyMetadata("courseEnrollments", "object", "Enrolled Users", updatedUsers)]);
                                    }
                                    }>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                                <div>
                                    <Button onClick={() => handleDeleteEnrolledStudent(index)}>Del</Button>
                                </div>
                            </div>
                        })}
                    </div>
                    <textarea value={userIds} onChange={(e) => setUserIds(e.target.value)}></textarea>
                    <Button disabled={userIds === ''} onClick={() => handleEnrollUsers()}>Enroll Users</Button>
                </Tabs.Item>
                <Tabs.Item title="Create Page Instance">
                    <div className="text-2xl mb-6">Page Challenge Instances</div>
                    <div className="grid grid-cols-3">
                        <div>Id</div>
                        <div>PageChallengeId</div>
                        <div>DueDate</div>
                    </div>
                    {/* {JSON.stringify(pageChallengeInstances)} */}
                    {/* {<div>Page Challenge Id: {selectedPageChallengeId}</div>} */}
                    {pageChallengeInstances.map((pageChallengeInstance, index) => {
                        return <div key={index} className="grid grid-cols-3">
                            <div>{pageChallengeInstance.id}</div>
                            <div>{pageChallengeInstance.pageChallengeId}</div>
                            <div>{pageChallengeInstance.dueDate}</div>
                        </div>
                    })}

                    <div className="text-2xl my-6">Create Page Challenge Instance</div>
                    <div>Select Page Challenge</div>
                    <select value={selectedPageChallengeId} onChange={(e) => setSelectedPageChallengeId(e.target.value)}>
                        {pageChallenges.map((pageChallenge, index) => {
                            return <option key={index} value={pageChallenge.id}>{pageChallenge.name}</option>
                        })}
                        {/* <option value="awd">Select Page Challenge</option> */}
                    </select>
                    <Button onClick={() => handleCreatePageChallengeInstance()}>Create Page Challenge Instance</Button>

                </Tabs.Item>
                <Tabs.Item title="View Page Instances">
                    <div className="text-2xl mb-6">View Page Challenge Instances</div>
                    <select value={selectedPageChallengeInstanceId} onChange={(e) => setSelectedPageChallengeInstanceId(e.target.value)}>
                        <option value="None">Select Page Challenge Instance</option>
                        {pageChallengeInstances.map((pageChallengeInstance, index) => {
                            return <option key={index} value={pageChallengeInstance.id}>{pageChallengeInstance.name}</option>
                        })}
                    </select>
                    <ViewPageInstance />
                </Tabs.Item>
            </Tabs>}

            {loadedCourseIndex !== -1 && <div>

            </div>}
        </div>
    )

}

export default CourseInstanceConstructor;