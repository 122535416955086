class IsBusy {
    isBusy: boolean = false;
    isSaving: boolean = false;
    isDeleting: boolean = false;
    isFetching: boolean = false;
    isLoading: boolean = false;
    isGenerating: boolean = false;
    loadingQueue: string[] = [];

    reset() {
        this.isBusy = false;
        this.isDeleting = false;
        this.isFetching = false;
        this.isLoading = false;
        this.isSaving = false;
        this.isGenerating = false;
        this.loadingQueue = [];
        return this;
    }

    saving() {
        this.isBusy = true;
        this.isSaving = true;
        return this;
    }

    deleting() {
        this.isBusy = true;
        this.isDeleting = true;
        return this;
    }

    fetching() {
        this.isBusy = true;
        this.isFetching = true;
        return this;
    }

    loading(item:string[]) {
        this.isBusy = true;
        this.isLoading = true;
        this.loadingQueue = this.loadingQueue.concat(item);
        return this;
    }

    loaded(item:string) {
        this.loadingQueue = this.loadingQueue.filter(x => x !== item);
        if(this.loadingQueue.length === 0){
            this.isBusy = false;
            this.isLoading = false;
        }
        return this;
    }

    generating() {
        this.isBusy = true;
        this.isGenerating = true;
        return this;
    }
}

export { IsBusy};