//React
import { useEffect, useState } from "react";

//UI
import { Button } from "flowbite-react";

//Services

//Logics

//Components

//Classes
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";

type Props = {    
    persistObject: Function
    object: unknown,
    type: string
    editableProperties: EditablePropertyMetadata[]
    editModalType: string   
}

const GenericObjectEditor = (props: Props) => {

    const { persistObject, object, type, editableProperties, editModalType } = props;
    const [tempPropertyList, setTempPropertyList] = useState<EditablePropertyMetadata[]>([]);

    useEffect(() => {
        setTempPropertyList(editableProperties);
    }, [editableProperties]);

    const editTempPropertyList = (property : string, value : any) => {
        let tempPropertyListCopy = [...tempPropertyList];
        let propertyIndex = tempPropertyListCopy.findIndex((x) => x.propertyName === property);
        tempPropertyListCopy[propertyIndex].value = value;
        setTempPropertyList(tempPropertyListCopy);
    }

    return (
        
        <div>
            {/* <div>{JSON.stringify(tempPropertyList)}</div> */}
            {tempPropertyList && tempPropertyList.map((property : EditablePropertyMetadata, index : number) => {
                return (
                    <div key={property.propertyName}>
                        <label>{property.displayName}</label>
                        {property.propertyType === "string" && <input type="text" value={property.value} onChange={(e) => editTempPropertyList(property.propertyName, e.target.value)} />}
                        {property.propertyType === "boolean" && <input type="checkbox" checked={property.value} onChange={(e) => editTempPropertyList(property.propertyName, e.target.checked)} />}
                        {property.propertyType === "select" && <select value={property.value} onChange={(e) => editTempPropertyList(property.propertyName, e.target.value)}>
                            {property.dataSource && property.dataSource.map((item : any, index : number) => {
                                return (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                )
                            })}
                        </select>}
                    </div>
                )
            })}
      
        <Button onClick={() => persistObject(tempPropertyList, editModalType)}>Save</Button>
        
        </div>
    );
};

export default GenericObjectEditor;