//React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Store

//Components / helpers / services
import CardSkeleton from "components/ui_components/helper/CardSkeleton";
import { Button, Card, Blockquote } from "flowbite-react";
import courseIcons from "components/ui_components/icons/courseIcons";
import Container from "components/ui_components/helper/Container";

//Helpers/Services
//import config from 'config';
//import apiService from "services/apiService";
//import errorService from "services/errorService";
//import flashSetLogic from "utility/logics/flashSetLogic";

//Classes
import { FlashGameSession } from "classes/logging/FlashGameSession";
import { QAFlashSet } from "classes/synapp/wordflash/QAFlashSet";
import { WordFlashSet } from "classes/synapp/wordflash/WordFlashSet";

//import { Fade } from "react-awesome-reveal";

const Home = () => {


    class RecentGames {
        sessions: FlashGameSession[] = [];
        qaFlashSets: QAFlashSet[] = [];
        wordFlashSets: WordFlashSet[] = [];
    }

    const navigate = useNavigate();
    const [selectedToolDescription, setSelectedToolDescription] = useState(-1);
    const [recentGames, setRecentGames] = useState<RecentGames | null>(null);

    return (
        <Container>
            {/* <div>
              
               <a href='/pagechallenge/66a0f0241e0084b02f890ed1'>Test page challenge - OLD SYSTEM</a>
               <a href='/pagechallenge/66cb23544b27e0561b8fc2e3'>Test page challenge</a>

            
            </div>*/}

            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                    <h1 className="mb-10 text-col-p1 text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl dark:text-white">
                        Dynamic Teaching and Learning Tools</h1>
                    <p className="mb-8 text-col-p2 text-lg font-normal md:text-2xl sm:px-16 xl:px-48 dark:text-gray-400">
                        Connect students with content in a way that creates lifelong learning. Empower teachers with tools that energise learning.</p>
                    <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    </div>
                    <div className="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
                        <span className="w-32 mr-5 mb-5 lg:mb-0 text-gray-400 uppercase">TOOLS</span>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 my-8 text-gray-500">

                            <div onClick={() => setSelectedToolDescription(1)} className="cursor-pointer m-auto text-lg md:text-2xl w-32 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                                {courseIcons.renderCourseIcon()} Course Templates
                            </div>
                            <div onClick={() => setSelectedToolDescription(2)} className="cursor-pointer m-auto text-lg md:text-2xl w-32 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                                {courseIcons.renderBrainIcon()}Word Flashsets
                            </div>
                            <div onClick={() => setSelectedToolDescription(3)} className="cursor-pointer m-auto text-lg md:text-2xl w-32 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                                {courseIcons.renderSpeechIcon()}Q/A Flashsets
                            </div>
                        </div>

                        {selectedToolDescription === 1 &&
                            <Blockquote className="text-col-p2 my-4 border-l-4 border-col-p1 bg-col-t1 p-4">
                                "A course template is like a folder. It holds all the content for a specific course. Inside the course template, you can add word flashsets and question/answer flashsets. <a href="/course/browser" className='font-bold text-col-s1'>Click here to go to the course browser."</a>
                            </Blockquote>}
                        {selectedToolDescription === 2 &&
                            <Blockquote className="text-col-p2 my-4 border-l-4 border-col-p1 bg-col-t1 p-4">
                                "A word flashset is a collection of words related to a subject and topic. The word flashset consists of smaller word sets. You can use word flashsets to help students learn new vocabulary words."
                            </Blockquote>}
                        {selectedToolDescription === 3 &&
                            <Blockquote className="text-col-p2 my-4 border-l-4 border-col-p1 bg-col-t1 p-4">
                                "A question/answer flashset is a collection of questions and answers. Question/answer flashsets can be used to introduce speech patterns, drive conversations, or test knowledge."
                            </Blockquote>}


                        <span className="w-32 mr-5 mb-5 lg:mb-0 text-gray-400 uppercase">Recent Games</span>
                        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 my-8 text-gray-500">
                            {recentGames && recentGames.sessions.map((session: FlashGameSession, index: number) => {
                                return (
                                    <div key={'recent' + index}>{renderRecentGameCard(session)}</div>
                                );
                            })}
                            {!recentGames && [1, 2, 3].map((index: number) => {
                                return <CardSkeleton key={'recent' + index} />;
                            })}
                            {recentGames && recentGames.sessions.length === 0 &&
                                <div className="by-16 text-center">No recent games</div>
                            }
                        </div> */}

                    </div>
                </div>

            </section>


        </Container>
    );
}

export default Home;

