//React
import { useEffect, useState, useRef } from 'react';

//UI
import { Button, Modal, Textarea, RangeSlider, Spinner, TabsRef } from 'flowbite-react';
import { Row, CheckboxAndLabel } from 'components/ui_components/helper/HelperComponents';

//Services
import apiService from 'services/apiService';
import crudService from 'services/crudService';
import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';

//Logics
import parseMcqAILogic from "logic/ai/parseMcqAILogic";
import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';

//Components

//Classes
import {FilterModel} from "classes/models/request/FilterModel";
import {CLASS} from 'classes/enums/classes';
import {STATUS } from 'classes/enums/status';
import {Mcq} from "classes/synapp/mcq/Mcq";
import {McqAnswer} from "classes/synapp/mcq/McqAnswer";
import {CategoryStatus} from 'classes/synapp/CategoryStatus';
import {IsBusy} from 'classes/general/IsBusy';

type Props = {
    content: string;
    //categoryStatus: CategoryStatus;
    level0CategoryId: string;
    level1CategoryId: string;
    level2CategoryId: string;
    selectingContentPending: boolean;
    setSelectingContentPending: Function;
}

const MCQConstructor = (props: Props) => {

    const { content, level0CategoryId, level1CategoryId, level2CategoryId, selectingContentPending, setSelectingContentPending } = props;

    const [mcqs, setMcqs] = useState<Mcq[]>([]);

    const [selectedMcqIndex, setSelectedMcqIndex] = useState<number>(-1);

    const [difficultySetting, setDifficultySetting] = useState("1");
    const [numberOfQuestionsToGenerate, setNumberOfQuestionsToGenerate] = useState("3");

    const [mcqAIContentForPrompt, setMcqAIContentForPrompt] = useState(content);

    const [isBusy, setIsBusy] = useState(new IsBusy());

    const mainTabsRef = useRef<TabsRef>(null);

    const [showAIGenerationModal, setShowAIGenerationModal] = useState(false);

    useEffect(() => {
        if (content !== "") {
            setMcqAIContentForPrompt(content);
            // setShowAIGenerationModal(true);
            // if (selectingContentPending) {
            //     setSelectingContentPending(false);
            // }
        }
    }, [content]);

    // useEffect(() => {
    //     loadMcqs(categoryStatusLogic.findParentCategoryId(categoryStatus));
    //     setSelectedMcqIndex(-1);
    // }, [categoryStatus.level0CategoryId, categoryStatus.level1CategoryId, categoryStatus.level2CategoryId]);

    useEffect(() => {
       loadMcqs("");
        //loadMcqs(categoryStatusLogic.findParentCategoryId(categoryStatus));
        setSelectedMcqIndex(-1);
    }, [level0CategoryId, level1CategoryId, level2CategoryId]);

    useEffect(() => {
       setShowAIGenerationModal(isBusy.isGenerating);
    }, [isBusy.isGenerating]);

    const createMcq = () => {
        let newMcq = new Mcq();
        //newMcq.categoryId = categoryStatusLogic.findParentCategoryId(categoryStatus);
        newMcq.level0CategoryId = level0CategoryId;
        newMcq.level1CategoryId = level1CategoryId;
        newMcq.level2CategoryId = level2CategoryId;
        newMcq.difficulty = parseInt(difficultySetting);
        let tempMcqs = [...mcqs];
        tempMcqs.push(newMcq);
        setSelectedMcqIndex(tempMcqs.length - 1);
        setMcqs(tempMcqs);
    }

    //================================================================================================= - Edit Mcq

    const editMcq = (action: string, value: any, index?: number) => {
        if (selectedMcqIndex !== -1) {
            let tempMcqs = [...mcqs];
            if (action === "editQuestion") {
                tempMcqs[selectedMcqIndex].questionText = value;
            }
            if (action === "editCodeblock") {
                tempMcqs[selectedMcqIndex].codeblockText = value;
            }
            if (action === "editImage") {
                tempMcqs[selectedMcqIndex].imageSrc = value;
            }
            if (action === "editAnswerText") {
                if (index !== undefined) {
                    tempMcqs[selectedMcqIndex].answers[index].answerText = value;
                }
            }
            if (action === "editAnswerIsCorrect") {
                if (index !== undefined) {
                    tempMcqs[selectedMcqIndex].answers[index].isCorrect = !tempMcqs[selectedMcqIndex].answers[index].isCorrect;
                }
            }
            if (action === "editDifficulty") {
                tempMcqs[selectedMcqIndex].difficulty = parseInt(value);
            }
            // if (action === "editCategoryId") {
            //     //tempMcqs[selectedMcqIndex].categoryId = value;

            // }
            if (action === "addAnswer") {
                tempMcqs[selectedMcqIndex].answers.push(new McqAnswer());
            }
            if (action === "deleteAnswer") {
                if (index !== undefined) {
                    tempMcqs[selectedMcqIndex].answers.splice(index, 1);
                }
            }
            if (tempMcqs[selectedMcqIndex].status !== STATUS.new) {
                tempMcqs[selectedMcqIndex].status = STATUS.updated;
            }
            setMcqs(tempMcqs);
        }
    }

    const deleteMcq = () => {
        if (selectedMcqIndex === -1) {
            toast.error("No mcq selected to delete");
            return;
        }
        if (mcqs[selectedMcqIndex].status === STATUS.new) {
            let tempMcqs = [...mcqs];
            tempMcqs.splice(selectedMcqIndex, 1);
            setMcqs(tempMcqs);
            setSelectedMcqIndex(-1);
        } else {
            //delete from db
            let filterModel = new FilterModel([["Id", mcqs[selectedMcqIndex].id]]);
            crudService.del(CLASS.mcq, filterModel).then(response => {
                if (response.success) {
                    let tempMcqs = [...mcqs];
                    tempMcqs.splice(selectedMcqIndex, 1);
                    setMcqs(tempMcqs);
                    setSelectedMcqIndex(-1);
                } else {
                    errorService.handleError(response);
                }
            });
        }

    }

    //================================================================================================= - Save/Load Mcq

    const saveMcqs = async () => {

        //check validity of all mcqs
        for (let mcq of mcqs) {
            if (!checkForValidity(mcq)) {
                alert("Invalid mcq found. Please check all mcqs (in red) for validity before saving.");
                return;
            }
        }

        let requestModelList = mcqs.filter(x => x.status === STATUS.new || x.status === STATUS.updated);
        //TODO: parse data property when necessary
        //TODO: parse for problems like empty question or answers or no correct answer selected

        let response = await crudService.create(CLASS.mcq, requestModelList);
        if (response.success) {
            let responseModel = response.payload as Mcq[];
            let tempMcqs = [...mcqs];

            for (let i = 0; i < responseModel.length; i++) {
                let index = tempMcqs.findIndex(x => x.guidRef === responseModel[i].guidRef);
                tempMcqs[index] = responseModel[i];
            }
            toast.success("MCQs saved successfully");
            setMcqs(tempMcqs);
        } else {
            errorService.handleError(response);
        }
    }

    const loadMcqs = async (categoryId: string) => {
        let filterModel = new FilterModel();
        filterModel.OnlyOwner = true;
        if (level2CategoryId !== "None") {
            filterModel.PropertyFilters = [["Level2CategoryId", level2CategoryId]];
        } else if (level1CategoryId !== "None") {
            filterModel.PropertyFilters = [["Level1CategoryId", level1CategoryId]];
        } else if (level0CategoryId !== "None") {
            filterModel.PropertyFilters = [["Level0CategoryId", level0CategoryId]];
        }
        //filterModel.PropertyFilters = [["CategoryId", findParentCategoryId()]];
        //filterModel.PropertyFilters = [["CategoryId", categoryStatusLogic.findParentCategoryId(categoryStatus)]];
        // let level = categoryStatusLogic.findLevelFromCategoryStatus(categoryStatus);
        // if (level === 0) {
        //     filterModel.PropertyFilters = [["Level0CategoryId", categoryId]];
        // } else if (level === 1) {
        //     filterModel.PropertyFilters = [["Level1CategoryId", categoryId]];
        // } else if (level === 2) {
        //     filterModel.PropertyFilters = [["Level2CategoryId", categoryId]];
        // }
        //filterModel.PropertyFilters = [["CategoryId", categoryId]];

        let response = await crudService.get(CLASS.mcq, filterModel);
        if (response.success) {
            let responseModel = response.payload as Mcq[];
            for (let i = 0; i < responseModel.length; i++) {
                responseModel[i].status = STATUS.unchanged;
            }
            setMcqs(response.payload);
        } else {
            errorService.handleError(response);
        }

    }

    //============================================================ - Category Constructor, Selector and Helper Functions


    //============================================================= - AI Generator


    const parseAndCreateAIGeneratedMcqs = (response: string) => {
        let tempMcqs = parseMcqAILogic.parseAIResponseToMcqs(response);
        for (let mcq of tempMcqs) {
            //mcq.categoryId = categoryStatusLogic.findParentCategoryId(categoryStatus);
            mcq.level0CategoryId = level0CategoryId;
            mcq.level1CategoryId = level1CategoryId;
            mcq.level2CategoryId = level2CategoryId
            mcq.difficulty = parseInt(difficultySetting);
            mcq.status = STATUS.new;
        }
        //Add to existing mcqs
        let allTempMcqs = [...mcqs];
        allTempMcqs = allTempMcqs.concat(tempMcqs);
        console.log(tempMcqs);
        console.log(allTempMcqs);
        setMcqs(allTempMcqs);
    }

//     const testParsing = () => {
//         let testString = `Q1:What are variables used for in programming?
//  A1:Storing or remembering information
//  A2:Displaying information
//  A3:Creating loops
//  A4:Executing functions

// Q2:How many basic data types are there in JavaScript?
//  A1:Three
//  A2:Four
//  A3:Five
//  A4:Two

// Q3*:What is a variable that can only have two possible values, true or false, called?
//  A1:Boolean
//  A2:Integer
//  A3:String
//  A4:Float`;
//         parseAndCreateAIGeneratedMcqs(testString);

//     }

    const generateMcqsFromAI = async () => {
        setIsBusy(isBusy.generating());
        // let request = { "Prompt": parseMcqAILogic.returnPrompt(numberOfQuestionsToGenerate, difficultySetting, mcqAIContentForPrompt) };
        // let response = await apiService.post(config.apiUrl + '/cms/testGPT', request);
        let request = {"Content": mcqAIContentForPrompt, "NumberOfQuestions": numberOfQuestionsToGenerate, "Difficulty": difficultySetting};
        let response = await apiService.post(config.apiUrl + '/cms/testGPT', request);
        if (response.success) {
            console.log(response.payload);
            parseAndCreateAIGeneratedMcqs(response.payload);
            //set tab to create
            mainTabsRef.current?.setActiveTab(0);
            setIsBusy(isBusy.reset());
            setShowAIGenerationModal(false);
        } else {
            errorService.handleError(response);
            setIsBusy(isBusy.reset());
        }

    }

    const renderAIGenerationModal = () => {

        return (<Modal show={showAIGenerationModal} size="5xl" onClose={() => setShowAIGenerationModal(false)} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="my-10">
                    <div>{"Difficulty:" + difficultySetting}</div>
                    <select disabled={isBusy.isBusy} value={difficultySetting} onChange={(e) => { setDifficultySetting(e.target.value) }}>
                        <option value="different">Different difficulties</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    <div>Number of questions to generate: </div>
                    <select disabled={isBusy.isBusy} value={numberOfQuestionsToGenerate} onChange={(e) => { setNumberOfQuestionsToGenerate(e.target.value) }}>
                        <option value="1">1</option>
                        <option value="3">3</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                    </select>
                    <Button onClick={() => { setSelectingContentPending(true); setShowAIGenerationModal(false); }}>Select Content</Button>
                    <div>Content</div>
                    <div className="flex gap-2">
                    </div>
                    <div>
                        <Textarea placeholder="Content for generating mcqs..." required rows={8} value={mcqAIContentForPrompt} onChange={(e) => setMcqAIContentForPrompt(e.target.value)} />
                    </div>

                    <div className="flex gap-2 my-6">
                        <Button disabled={mcqAIContentForPrompt === "" || isBusy.isBusy} onClick={() => generateMcqsFromAI()}>Generate</Button>
                    </div>
                    {isBusy.isGenerating && <div className="flex justify-center gap-2">
                        <div className="w-32">
                            <Spinner size="large" />
                            Generating...
                        </div>
                    </div>}
                </div>
            </Modal.Body>
        </Modal>);
    }

    //================================================================================== - Render Mcq

    const renderMcq = (mcq: Mcq) => {

        // const {mcq} = props;

        return (
            <div>
                <Row>
                    Difficulty:
                    <select value={mcq.difficulty} onChange={(e) => { editMcq("editDifficulty", e.target.value) }}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </Row>
                <Row>
                    Question:
                    <div className="w-full">
                        <input className="w-full" type="text" placeholder='Answer text here...' value={mcq.questionText} onChange={(e) => { editMcq("editQuestion", e.target.value) }} />
                    </div>
                </Row>
                {/* <Row>
                    Codeblock:
                    <div>
                        <input type="text" value={mcq.CodeblockText} onChange={(e) => { editMcqProperty("CodeblockText", e.target.value) }} />
                    </div>
                </Row>
                <Row>
                    Image:
                </Row> */}
                <Row>
                    Answers:
                    <div className="">
                        {mcq.answers.map((answer, index) => {
                            return (
                                <Row key={"mcq-answers-" + index} className="flex gap-4 my-6">

                                    <CheckboxAndLabel checked={answer.isCorrect} label="Is Correct" onChange={(e: any) => { editMcq("editAnswerIsCorrect", null, index) }} />
                                    <input className="w-[800px]" type="text" placeholder='Answer text here...' value={answer.answerText} onChange={(e) => { editMcq("editAnswerText", e.target.value, index) }} />

                                    <Button onClick={() => editMcq("deleteAnswer", index)}>Delete</Button>

                                </Row>
                            )
                        })}
                        <Button onClick={() => editMcq("addAnswer", null)}>Add Answer</Button>
                    </div>

                </Row>
            </div>
        )
    }

    const checkForValidity = (mcq: Mcq) => {
        let valid = true;
        if (mcq.questionText === "") {
            valid = false;
        }
        if (mcq.answers.length === 0) {
            valid = false;
        }
        let correctAnswerCount = 0;
        let validAnswerTextCount = 0;
        for (let answer of mcq.answers) {
            if (answer.isCorrect) {
                correctAnswerCount++;
            }
            if (answer.answerText !== "") {
                validAnswerTextCount++;
            }
        }
        if (correctAnswerCount === 0) {
            valid = false;
        }
        if (validAnswerTextCount < 2) {
            valid = false;
        }
        return valid;
    }

    const getSelectionCardClassString = (index: number) => {
        let classString = "border-2 select-none text-xs p-3 m-3 w-20 h-16 ";
        classString += (selectedMcqIndex === index) ? " border-black" : " border-gray-300";
        let thisMcq = mcqs[index];
        //let thisAnswer = submittedAnswers.find(x => x.mcqId === filteredMcqs[index].id);
        if (thisMcq.status === STATUS.unchanged) {
            classString += " bg-gray-100";
        }
        else if (thisMcq.status === STATUS.new) {
            if (checkForValidity(thisMcq)) {
                classString += " bg-green-200";
            } else {
                classString += " bg-red-200";
            }
        } else if (thisMcq.status === STATUS.updated) {
            classString += " bg-yellow-200";
        }
        return classString;
    }

    return (
        <div className='container mx-auto'>
          {/* <Button onClick={() => testParsing()}>Test Parsing</Button> */}
            <div className="flex gap-2 my-6">
                <Button onClick={() => createMcq()}>Create Plain MCQ</Button>
                <Button onClick={() => saveMcqs()}>Save MCQs</Button>
                <Button onClick={() => setShowAIGenerationModal(true)}>Generate MCQs</Button>
                <Button disabled={selectedMcqIndex === -1} onClick={() => deleteMcq()}>Delete</Button>
            </div>
            <div className="flex gap-2 flex-wrap">
                {mcqs && mcqs.map((mcq, index) => {
                    return (
                        <div key={"mcq-" + index} className={getSelectionCardClassString(index)}
                            onClick={() => setSelectedMcqIndex(index)}
                        >
                            {"Dif: " + mcq.difficulty}
                        </div>
                    )
                }
                )}
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    {selectedMcqIndex !== -1 && mcqs[selectedMcqIndex] && renderMcq(mcqs[selectedMcqIndex])}
                </div>
            </div>

            {showAIGenerationModal && renderAIGenerationModal()}

        </div>
    )

}

export default MCQConstructor;