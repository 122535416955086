import createGUID from "logic/utility/helper-functions/createGUID";
import {McqAnswer} from "classes/synapp/mcq/McqAnswer";
import {STATUS } from "classes/enums/status";

class Mcq {
    id: string = "";
    guidRef: string = createGUID(8);
    ownerId: string = "";
    difficulty: number = 1;
    //categoryId: string = "";
    level0CategoryId: string = "None";
    level1CategoryId: string = "None";
    level2CategoryId: string = "None";
    questionText: string = "";
    codeblockText: string = "";
    isSelfContained: boolean = true;
    answers: McqAnswer[] = [new McqAnswer(), new McqAnswer(), new McqAnswer()];
    imageSrc: string = "";
    mediaLogId: string = "";
    status: STATUS = STATUS.new;
}

export { Mcq};

