//React
import React from 'react';

//UI

//Services

//Logics

//Components
import Dropzone from 'react-dropzone';

//Store

//Classes
import { Component } from 'classes/gridslate/components/Component';
import { ImageData } from 'classes/gridslate/components/ImageData';

type Props = {
  image: Component;
  style: {};
  setImageAttribute: Function;
}

const ImageComponent = (props: Props) => {

  const { image, setImageAttribute, style } = props;

  let imageData = image.data.specialisedData as ImageData;

  // const onDrop = (files: FileList) => {
  const onDrop = (files: any) => {
    console.log("Image onDrop called");
    let localBlobRef = URL.createObjectURL(files[0]);
    //Get file extension
    let extension = files[0].name.split('.').pop();
    setImageAttribute('localBlobRef', localBlobRef, extension);
  }

  // const CustomDropZone = () => {
  //   return (<div className="flex items-center justify-center w-full">
  //     <label className='w-84'>
  //       <div className="flex flex-col items-center justify-center pt-5 pb-6 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
  //         <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
  //           <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
  //         </svg>
  //         <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click here to upload</span> OR drag and drop OR paste an image using Ctrl + V</p>
  //         <p className="text-xs text-gray-500 dark:text-gray-400">PNG or JPG</p>
  //       </div>
  //       <input type="file" className="hidden" onDrop={(e) => onDrop(e.dataTransfer.files)} />
  //     </label>
  //   </div>)
  // }

  return (
    <React.Fragment>
      {!imageData.src && !imageData.localBlobRef &&
        // <CustomDropZone />
        <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section className="flex w-full items-center justify-center h-64 cursor-pointer">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone>
      }
      {imageData.localBlobRef && (!imageData.src || imageData.src === 'fetching') &&
        //<img src={imageData.localBlobRef} className={classString} alt={image.text} ></img>
        <img src={imageData.localBlobRef} style={style} alt={image.text} ></img>
      }
      {imageData.localBlobRef === '' && imageData.src &&
        //<img crossOrigin="anonymous" src={imageData.src} alt={image.text} className={'m-auto ' + classString}></img>
        <img crossOrigin="anonymous" src={imageData.src} alt={image.text} style={style}></img>
      }
    </React.Fragment>

  );

}

export default ImageComponent;
