
class ImageData {
    src: string = '';
    localBlobRef: string = '';
    extension: string = '';
    width: number = 0;
    height: number = 0;
    constructor() {
    }
}


export { ImageData};