//React

//Components / helpers / services

//Store

//Views/Components

//Classes
import { WordFlashSet, WordSet, SingleWord } from "classes/synapp/wordflash/WordFlashSet";
import { QAFlashSet } from "classes/synapp/wordflash/QAFlashSet";

const joinWordFlashSetWords = (wordFlashSet: WordFlashSet) => {
    let words = '';
    wordFlashSet.wordSets.forEach((wordSet: WordSet) => {
      wordSet.words.forEach((word: SingleWord) => {
        words += word.word + ', ';
      });

    });
    return words;
  }

  const joinQASets = (qaSet: QAFlashSet) => {
    let words = '';
    qaSet.qaSets.forEach((qa: any) => {
      words += qa.question;
      for (let i = 0; i < qa.answers.length; i++) {
        words += ', ' + qa.answers[i] + '  ';
      }
    });
    return words;
  }

  export default { joinWordFlashSetWords, joinQASets };