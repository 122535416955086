import { STATUS } from "../../enums/status";
import createGUID from "../../../logic/utility/helper-functions/createGUID";
import { CodeExerciseSubmissionGrade } from "./CodeExerciseSubmissionGrade";

class CodeExerciseSubmission {
    public id: string = "";
    public guidRef: string = createGUID(8);
    public ownerId: string = "";
    public codeExerciseId: string = "";
    public challengeGuidRef: string = ""; //
    public sourceCode: string = "";
    public pointsAwarded: number = 0;
    public isSubmitted: boolean = false;
    public dateSubmitted: string = "";
    public grades: CodeExerciseSubmissionGrade[] = [];

    public status: STATUS = STATUS.unchanged; //client side
}

export { CodeExerciseSubmission };