import {Checkbox, Label} from 'flowbite-react';

const CheckboxAndLabel = (props: any) => {
    //remove spaces from label to create id
    let id = props.label.replace(/\s/g, '');
    
    return (
      <div className="flex items-center gap-2">
        <Checkbox className='text-sm md:text-lg' id={id} checked={props.checked} onChange={(e) => props.onChange(e)}></Checkbox >
        <Label htmlFor={props.id}>{props.label}</Label>
      </div>
    )
  }

export default CheckboxAndLabel;