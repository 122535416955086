//TODO: Why are these lower case?

enum CLASS {
    page = "page",
    styleTheme = "styleTheme",
    component = "component",
    mcq = "mcq",
    category = "category",
    pageChallenge = "pageChallenge",
    stoppingPointStatus = "stoppingPointStatus",
    pageChallengeStatus = "pageChallengeStatus",
    codeChallenge = "codeChallenge",
    codeChallengeStatus = "codeChallengeStatus",
    codeExercise = "codeExercise",
    codeExerciseSubmission = "codeExerciseSubmission",
    course = "course",
    pageChallengeInstance = "pageChallengeInstance",
    foo = "foo",
    account = "account",
    mcqSubmittedAnswer = "mcqSubmittedAnswer",
}

export {CLASS};