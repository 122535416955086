//React

//UI
import {Container} from 'components/ui_components/helper/HelperComponents';
import { TextInput, Button, Label } from 'flowbite-react';

//Services
import errorService from 'services/errorService';
import apiService from 'services/apiService';

//Logics

//Components

//Store
import { userAtom } from 'atom';
import { useAtom } from 'jotai';

//Classes
import { useState, useEffect } from "react";
//import { Row, Col } from 'ui_components/helper/HelperComponents';
//import { useSelector } from "react-redux";

//Helpers/Services
import config from 'config';
import { toast } from 'react-toastify';
//import http from 'services/httpService';

//Views/Components

//CSS
// import { faEye } from "@fortawesome/free-regular-svg-icons";
// import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MyAccount = () => {

  //const user = useSelector((state) => state.user);
  const [user, setUser] = useAtom(userAtom);
  const [changePassword, setChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [submittingNewPassword, setSubmittingNewPassword] = useState(false);

  useEffect(() => {
    //Get all pages

  }, [])


  const submitNewPassword = async (e: any) => {
    e.preventDefault();

    if (newPassword1 === '') {
      toast.error("New password field is blank!");
      return;
    }

    if (newPassword1 !== newPassword2) {
      toast.error("Your passwords do not match!");
      return;
    }

    setSubmittingNewPassword(true);

    let newPasswordModel = {
      "Password": currentPassword,
      "NewPassword": newPassword1,
      "ConfirmedNewPassword": newPassword2
    }

    let response = await apiService.post(config.apiUrl + '/accounts/ChangePassword', newPasswordModel);
    if (response.success){
      toast.success("Password changed successfully!");
      setCurrentPassword("");
      setNewPassword1("");
      setNewPassword2("");
      setSubmittingNewPassword(false);
      setChangePassword(false);
    } else {
      errorService.handleError(response);
      setCurrentPassword("");
      setNewPassword1("");
      setNewPassword2("");
      setSubmittingNewPassword(false);
    }

    // http.post(config.apiUrl + '/accounts/ChangePassword', newPasswordModel)
    //   .then((data) => {
    //     if (!data.errorCaught) {
    //       toast.success("Password changed successfully!");
    //       setCurrentPassword("");
    //       setNewPassword1("");
    //       setNewPassword2("");
    //       setSubmittingNewPassword(false);
    //       setChangePassword(false);
    //     } else {
    //       toast.error("An error occured changing your password!");
    //       setCurrentPassword("");
    //       setNewPassword1("");
    //       setNewPassword2("");
    //       setSubmittingNewPassword(false);
    //       console.log(data);
    //     }
    //   })

  }

  // const hideUnhidePassword = () => {

  // }

  return (
    <Container className='standard'>
      {/* {JSON.stringify(user)} */}
      <form className="flex max-w-md flex-col gap-4">
        <Label>Username</Label>
        <TextInput type="text" disabled placeholder={user.username} />
        <div className="text-muted">
          Your username can't currently be changed
        </div>
      
      
        <Label>Email</Label>
        <TextInput type="text" disabled placeholder={user.email} />
        <div className="text-muted">
          Your email address can't currently be changed
        </div>
      </form>
      <p></p>
      {changePassword &&
       <form className="flex max-w-md flex-col gap-4">
       
          <Label>Current Password</Label>
          <TextInput type="password" placeholder="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.currentTarget.value)} />
          <p></p>
          <Label>New Password</Label>
          <TextInput type="password" placeholder="new password" value={newPassword1} onChange={(e) => setNewPassword1(e.currentTarget.value)} />

          <Label>Confirm New Password</Label>
          <TextInput type="password" placeholder="new password" value={newPassword2} onChange={(e) => setNewPassword2(e.currentTarget.value)} />
          <p></p>
          
          <Button
              color="blue"
              type="submit"
              disabled={submittingNewPassword}
              onClick={!submittingNewPassword ? (e: any) => submitNewPassword(e) : () => {}}
            >
               {!submittingNewPassword ? "Submit new password" : "Submitting..."}
            </Button>
           
          
        
        </form>
      }

      {!changePassword &&
        <Button color="blue" onClick={() => setChangePassword(true)}>
          Change password
        </Button>}
    </Container>
  );
}

export default MyAccount;