class StyleItemOption {
    name: string;
    values: string[];
    defaultValue: string;
    type: string;

    constructor(name: string, values: string[], defaultValue: string, type: string) {
        this.name = name;
        this.values = values;
        this.defaultValue = defaultValue;
        this.type = type;
    }

    setDefault(value: string) {
        this.defaultValue = value;
        return this;
    }
}

export { StyleItemOption};