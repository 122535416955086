
class QAFlashSet {
    id: string = "";
    courseId: string;
    title: string;
    description: string = "";
    imageSrc: string = "";
    qaSets: QASet[] = [];
    creatorId: string = "";
    isPublic: boolean = false;

    constructor(courseId: string, title: string) {
        this.courseId = courseId;
        this.title = title;
    }
}

class QASet {
    question: string;
    answers: string[];

    constructor(question: string, answers: string[]) {
        this.question = question;
        this.answers = answers;
    }
}

export { QAFlashSet, QASet};